import React from "react";
import { IconsType } from "../../libs/Utils/types";
function Facebook() {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2 10C2.00014 8.46045 2.44451 6.95364 3.27979 5.66038C4.11506 4.36712 5.30576 3.34234 6.70901 2.70901C8.11225 2.07568 9.66844 1.8607 11.1908 2.08987C12.7132 2.31904 14.1372 2.98262 15.2918 4.00099C16.4464 5.01936 17.2826 6.34926 17.7002 7.83111C18.1177 9.31296 18.0988 10.8838 17.6457 12.3552C17.1926 13.8265 16.3246 15.1359 15.1458 16.1262C13.967 17.1165 12.5275 17.7456 11 17.938V12H13C13.2652 12 13.5196 11.8946 13.7071 11.7071C13.8946 11.5196 14 11.2652 14 11C14 10.7348 13.8946 10.4804 13.7071 10.2929C13.5196 10.1054 13.2652 10 13 10H11V8C11 7.73478 11.1054 7.48043 11.2929 7.29289C11.4804 7.10536 11.7348 7 12 7H12.5C12.7652 7 13.0196 6.89464 13.2071 6.70711C13.3946 6.51957 13.5 6.26522 13.5 6C13.5 5.73478 13.3946 5.48043 13.2071 5.29289C13.0196 5.10536 12.7652 5 12.5 5H12C11.2044 5 10.4413 5.31607 9.87868 5.87868C9.31607 6.44129 9 7.20435 9 8V10H7C6.73478 10 6.48043 10.1054 6.29289 10.2929C6.10536 10.4804 6 10.7348 6 11C6 11.2652 6.10536 11.5196 6.29289 11.7071C6.48043 11.8946 6.73478 12 7 12H9V17.938C7.0667 17.6942 5.28882 16.7533 4 15.2917C2.71119 13.8302 2.00003 11.9486 2 10ZM10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20Z"
          fill="#F9F9F9"
        />
      </svg>
    </div>
  );
}

export default Facebook;
