import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Separator from "../../../../global components/Icons/Separator";
import BreadCrumb from "../../../../global components/BreadCrumbs/BreadCrumbs";
import s from "./InnerPage.module.scss";
import clsx from "clsx";
function EmailEditor() {
  const location = useLocation();
  const pathName = location.pathname;
  const items = [
    {
      title: <Link to="/dashboard/Message">Message</Link>,
    },

    {
      title: (
        <Link
          className={clsx(pathName.includes("email") && s.active)}
          to="/dashboard/Message/email"
          onClick={(e) => {
            if (pathName.includes("email")) e.preventDefault();
          }}
        >
          Email
        </Link>
      ), // Added title text
    },
    ...(location.pathname.includes("review")
      ? [
          {
            title: (
              <Link
                className={clsx(pathName.includes("review") && s.active)}
                to="/dashboard/Message/review"
                onClick={(e) => {
                  if (pathName.includes("review")) e.preventDefault();
                }}
              >
                Review
              </Link>
            ),
          },
        ]
      : []),
  ];

  return (
    <div className={s.wrapper}>
      <div className={s.breadCrumb}>
        <BreadCrumb
          items={items}
          separator={<Separator height={11} width={6.67} fill="#9CA3AF" />}
        />
      </div>

      <Outlet />
    </div>
  );
}

export default EmailEditor;
