import { Button } from "antd";
import React, { useState } from "react";
import s from "./ConfirmationModal.module.scss";
import ReusableModal from "../Modal/Modal";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
type ConfirmationModalProp = {
  handleCancel: () => void;
  handleConfirmationModal: () => void;
  open: boolean;
  reset?: () => void;
  msg?: string;
};
function ConfirmationModal({
  msg,
  handleCancel,
  handleConfirmationModal,
  open,
  reset,
}: ConfirmationModalProp) {
  const location = useLocation();
  const pathName = location.pathname;
  const navigate = useNavigate();
  const handleModalCancel = () => {
    handleCancel();
    handleConfirmationModal();
    reset && reset();
    if (
      msg &&
      (pathName === "/dashboard/Message/email" ||
        pathName === "/dashboard/Message/sms" ||
        pathName === "/dashboard/Message/voice-message")
    ) {
      navigate(-1);
    }
    //if (isTrue) toggle();
  };
  return (
    <div>
      <ReusableModal
        handleModal={handleConfirmationModal}
        isModalOpen={open}
        title={""}
        subtitle=""
        centered
        footer={null}
      >
        <div className={s.modalBody}>
          <div className={s.texts}>
            <h3 className={s.message}>
              {msg || "Are you sure you want to cancel?"}
            </h3>
            {/* <h5 className={s.subMessage}>
              The selected contacts will be removed from the group
            </h5> */}
          </div>

          <div className={s.modalContactBtns}>
            <Button
              className={clsx(s.button, s.cancelBtn)}
              onClick={handleConfirmationModal}
            >
              No
            </Button>
            <Button
              onClick={handleModalCancel}
              //loading={isMutating}
              className={clsx(s.button, s.modalContactBtn)}
            >
              Yes, {msg ? "leave page" : "cancel"}
            </Button>
          </div>
        </div>
      </ReusableModal>
    </div>
  );
}

export default ConfirmationModal;
