import React, { useEffect, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ToastProvider from "./global components/Toast";
import { ToastContainer } from "react-toastify";

import { logout, setUserFromToken, User } from "./libs/redux/auth/signinSlice";
import { isTokenExpired, useAppDispatch } from "./libs/Utils/customHooks";
import RequireAuth from "./requireAuth";
import Require from "./required";
import Loading from "./global components/Loading";
import useSWR from "swr";
import { swrFetcher } from "./libs/api/Endpoints";
import { UseAuthToken } from "./libs/api/userSession";
import NotFound from "./global components/NotFound/NotFound";
import { DraftProvider } from "./global components/Context/DraftContext";
// Lazy load your components
import Auth from "./pages/Auth";
import Login from "./pages/Auth/Login";
import SignIn from "./pages/Auth/Sign up";
import ForgotPassword from "./pages/Auth/Forgot Password";
import ResetCode from "./pages/Auth/ResetCode";
import ResetPassword from "./pages/Auth/Reset Password";
import EmailVerification from "./pages/Auth/Verify Email";
import PhoneNumberVerification from "./pages/Auth/Verify Phonenumber";
import Dashboard from "./pages/Dashboard";
import VerifyPhoneNumber from "./pages/Auth/Phone number";
import BuyCredit from "./pages/Home/local components/BuyCredit";
import HomeInnerPage from "./pages/Home/local components/InnerPage";
import Checkout from "./pages/Home/local components/Checkout";
import Contact from "./pages/Message/local components/ViewDetails/Message Details/Contacts";
import { ToggleProvider } from "./global components/Context/NavbarContext";
import EmailEditor from "./pages/Message/local components/EmailEditor";
import Review from "./pages/Message/local components/Review";
import ContactsInnerPage from "./pages/Contacts/local components/ContactPage";
import ContactDetails from "./pages/Contacts/local components/ContactPage/ContactDetails";
import Email from "./pages/Message/local components/Email";
import ViewDetails from "./pages/Message/local components/ViewDetails";
import MessageDetails from "./pages/Message/local components/ViewDetails/Message Details";
import SmsEditor from "./pages/Message/local components/SmsEditor";
import SMS from "./pages/Message/local components/SMS";
import VoiceMessage from "./pages/Message/local components/Voice message";
import VoiceMessageEditor from "./pages/Message/local components/Voice Message Editor";
import ContactGroupPage from "./pages/Contacts/local components/ContactGroupPage";
import GroupDetails from "./pages/Contacts/local components/ContactGroupPage/GroupDetails";
import { CountryCodeProvider } from "./global components/Context/CountryCode";
import { notification, type NotificationArgsProps } from "antd";
import { WelcomeProvider } from "./global components/Context/WelcomeContex";
import Unsubscribe from "./pages/Unsubscribe";
import { DraftProvider as DraftProvide } from "./global components/Context/DraftContent";
import HomePage from "./pages/Homepage";
import ScrollToTop from "./global components/ScrollToTop";
import FAQ from "./pages/Homepage/FAQ";
const LandingPage = lazy(() => import("./pages/LandingPage"));
const Home = lazy(() => import("./pages/Home"));
const Contacts = lazy(() => import("./pages/Contacts"));
const Message = lazy(() => import("./pages/Message"));
const Account = lazy(() => import("./pages/Account"));

function App() {
  const dispatch = useAppDispatch();
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      if (isTokenExpired(token)) {
        dispatch(logout()); // Update Redux state to logout
      } else {
        dispatch(setUserFromToken(token)); // Set valid token in Redux state
      }
    }
  }, [dispatch]);

  return (
    <>
      <ScrollToTop />

      <ToastContainer />

      <WelcomeProvider>
        <ToastProvider>
          <DraftProvide>
            <DraftProvider>
              <CountryCodeProvider>
                <ToggleProvider>
                  <Routes>
                    <Route path="*" element={<NotFound />} />
                    <Route element={<RequireAuth />}>
                      <Route element={<Require />}>
                        <Route path="/dashboard" element={<Dashboard />}>
                          <Route
                            index
                            element={
                              <Suspense fallback={<Loading />}>
                                <Home />
                              </Suspense>
                            }
                          />
                          <Route
                            path="Home"
                            element={
                              <Suspense fallback={<Loading />}>
                                <Home />
                              </Suspense>
                            }
                          />
                          <Route
                            path="Home/buy-credit"
                            element={<HomeInnerPage />}
                          >
                            <Route index element={<BuyCredit />} />
                            <Route path="checkout" element={<Checkout />} />
                          </Route>
                          <Route
                            path="Contacts"
                            element={
                              <Suspense fallback={<Loading />}>
                                <Contacts />
                              </Suspense>
                            }
                          />
                          <Route
                            path="Contacts/contact-details"
                            element={<ContactsInnerPage />}
                          >
                            <Route index element={<ContactDetails />} />
                          </Route>
                          <Route
                            path="Contacts/group-details"
                            element={<ContactGroupPage />}
                          >
                            <Route index element={<GroupDetails />} />
                          </Route>
                          <Route path="Message/email" element={<EmailEditor />}>
                            <Route index element={<Email />} />
                            <Route path="review" element={<Review />} />
                          </Route>
                          <Route path="Message/sms" element={<SmsEditor />}>
                            <Route index element={<SMS />} />
                            <Route path="review" element={<Review />} />
                          </Route>
                          <Route
                            path="Message/voice-message"
                            element={<VoiceMessageEditor />}
                          >
                            <Route index element={<VoiceMessage />} />
                            <Route path="review" element={<Review />} />
                          </Route>
                          <Route
                            path="Message/message-details"
                            element={<ViewDetails />}
                          >
                            <Route index element={<MessageDetails />} />
                            <Route path="contact" element={<Contact />} />
                          </Route>
                          <Route
                            path="Message"
                            element={
                              <Suspense fallback={<Loading />}>
                                <Message />
                              </Suspense>
                            }
                          />
                          <Route
                            path="Account"
                            element={
                              <Suspense fallback={<Loading />}>
                                <Account />
                              </Suspense>
                            }
                          />
                        </Route>
                      </Route>
                    </Route>
                    <Route path="/" element={<HomePage />}>
                      <Route
                        index
                        element={
                          <Suspense fallback={<Loading />}>
                            <LandingPage />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/"
                        element={
                          <Suspense fallback={<Loading />}>
                            <LandingPage />
                          </Suspense>
                        }
                      />
                      <Route path="/faq" element={<FAQ />} />
                    </Route>

                    <Route path="unsubscribe" element={<Unsubscribe />} />
                    <Route path="auth" element={<Auth />}>
                      <Route index element={<Login />} />

                      <Route path="signin" element={<Login />} />
                      <Route path="signup" element={<SignIn />} />
                      <Route
                        path="forgot-password"
                        element={<ForgotPassword />}
                      />

                      <Route path="resetcode" element={<ResetCode />} />
                      <Route
                        path="reset-password"
                        element={<ResetPassword />}
                      />
                      <Route
                        path="verify-email"
                        element={<EmailVerification />}
                      />
                      <Route
                        path="verify-phone-number"
                        element={<VerifyPhoneNumber />}
                      />
                      <Route
                        path="verify-phone-number/:phonenumber"
                        element={<PhoneNumberVerification />}
                      />
                    </Route>
                  </Routes>
                </ToggleProvider>
              </CountryCodeProvider>
            </DraftProvider>
          </DraftProvide>
        </ToastProvider>
      </WelcomeProvider>
    </>
  );
}

export default App;
