import React, { useCallback, useEffect, useRef, useState } from "react";
import s from "./SMS.module.scss";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  DatePicker,
  Input,
  Spin,
  TimePicker,
  TimePickerProps,
} from "antd";
import SelectField from "../../../../global components/Forms/SelectField/SelectField";
import {
  PaginatedContactGroups,
  singleContactGroup,
} from "../../../../libs/Utils/Types/ContactGroup";
import { UseAuthToken } from "../../../../libs/api/userSession";
import useSWR from "swr";
import {
  parseClientError,
  swrFetcher,
  swrMutationFetcher,
  SwrMutationFetcherArgType,
} from "../../../../libs/api/Endpoints";
import { useNavigate } from "react-router-dom";
import { generateResult } from "../../../../global components/ResultModal/ResultModal";
import ReusableDrawer from "../../../../global components/Drawer/ReuseableDrawer";
import ReusablePopover from "../../../../global components/Popover/ReuseablePopover";
import NewPopOverItems from "../TimePopOver/PopOverItems";
import ArrowDown from "../../../../global components/Icons/ArrowDown";
import dayjs, { Dayjs } from "dayjs";
import PhoneInput from "react-phone-input-2";
import AudioRecorder from "../../../../global components/AudioRecorder/AudioRecorder";
import useSWRMutation from "swr/mutation";
import "react-phone-input-2/lib/style.css";
import Microphone from "../../../../global components/Icons/Microphone/Microphone";
import { useDraft } from "../../../../global components/Context/DraftContext";
import ReusableModal from "../../../../global components/Modal/Modal";
import { capitalize, useQuery } from "../../../../libs/common";
import { MessageDetailsType } from "../../../../libs/Utils/Types/Message";
import { useToggle } from "../../../../global components/Context/NavbarContext";
import ConfirmationModal from "../../../../global components/CofirmationModal/ConfirmationModal";
import { Oval } from "react-loader-spinner";
import { SuccessDataType } from "../../../../libs/api/types";
type Inputs = {
  msgTitle: string;
  emailSubject: string;
  recipient: string;
  date: string;
  time: string;
  from: string;
};

function VoiceMessage() {
  const [openGroup, setOpenGroup] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<string | string[]>("");
  const token = UseAuthToken();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const query = useQuery();
  const [openMobile, setOpenMobile] = useState<boolean>(false);

  const groupId = query.get("groupId");

  const [audio, setAudio] = useState<Blob | null>(null); // Update type to accept Blob
  const [, /*audioURL*/ setAudioURL] = useState<string>("");
  const { trigger: uploadVoiceMessage, isMutating } = useSWRMutation(
    "api/media/upload",
    swrMutationFetcher
  );
  const msgId = query.get("msgId");
  const { data: group } = useSWR<singleContactGroup, any, any>(
    token && groupId ? [`api/contact-groups/${groupId}`, token] : null,
    swrFetcher,
    {
      onpause: () => !groupId,
    }
  );
  const {
    data: MessageDetails,
    isLoading,
    error,
  } = useSWR<MessageDetailsType, any, any>(
    token && msgId ? [`api/messages/${msgId}`, token] : null,
    swrFetcher,
    {
      revalidateOnFocus: true,

      shouldRetryOnError: false,
    }
  );
  const [open, setOpen] = useState<boolean>(false);
  const [recipient, setRecipients] = useState<string>("");

  // Fetch contact groups
  const { data: groups } = useSWR<PaginatedContactGroups, any, any>(
    token ? [`api/contact-groups`, token] : null,
    swrFetcher,
    {
      shouldRetryOnError: false,
    }
  );
  const { data: Recipient, isLoading: loading } = useSWR<
    singleContactGroup,
    any,
    any
  >(
    token && recipient ? [`api/contact-groups/${recipient}`, token] : null,
    swrFetcher,
    {
      onpause: () => !recipient,
    }
  );
  const {
    watch,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
    setValue,
  } = useForm<Inputs>({ shouldFocusError: false });
  const hasUpdated = useRef(false); // Tracks if state has been set
  const combineDateTime = (
    date: string | Dayjs | null,
    time: string | Dayjs | null
  ) => {
    if (date && time) {
      const parsedDate = dayjs(date); // Convert to Dayjs if it's a string
      const parsedTime = dayjs(time); // Convert to Dayjs if it's a string

      if (parsedDate.isValid() && parsedTime.isValid()) {
        const combined = parsedDate
          .hour(parsedTime.hour())
          .minute(parsedTime.minute())
          .second(0)
          .millisecond(0);

        return combined.toISOString();
      }
    }
    return null; // Handle invalid or null inputs
  };
  const navigate = useNavigate();
  const handleAudioSave = (blob: Blob) => {
    setAudio(blob);
  };
  const { isTrue, toggle } = useToggle();

  const onSubmit = async (data: Inputs) => {
    if (!audio) {
      generateResult("error", "You must record a message");
      return;
    }
    if (Recipient?.contacts.docs.length === 0) {
      generateResult(
        "error",
        "0 contacts found!",
        "Select a group with at least one contact or add contacts to the group you want to sent message to"
      );
      return;
    }
    try {
      if (audio) {
        const formData = new FormData();
        formData.append("media", audio);
        formData.append("type", "Message");
        type Response = {
          secure_url: string;
        };
        const response: SuccessDataType<Response> = await uploadVoiceMessage({
          token,
          requestOption: {
            method: "POST",
            body: formData,
          },
        });
        if (response && response.data.secure_url) {
          setAudioURL(response.data.secure_url);
          if (watch("date") && watch("time")) {
            const combinedDateTime = combineDateTime(
              watch("date"),
              watch("time")
            );

            generateResult(
              "success",
              "Successful",
              "Review your message before sending or check your drafts to edit the message"
            );
            navigate("review", {
              state: {
                ...data,
                type: "voice",
                audioURL: response.data.secure_url,
                schedule: combinedDateTime,
                msgId: msgID || msgId,
              },
            });

            reset();
          } else {
            generateResult(
              "success",
              "Successful",
              "Review your message before sending or check your drafts to edit the message"
            );
            navigate("review", {
              state: {
                ...data,
                type: "voice",
                audioURL: response.data.secure_url,
                msgId: msgID || msgId,
              },
            });
          }
        } else {
          generateResult("error", "Audio URL not returned from upload");
        }
      }
    } catch (error) {
      const e = parseClientError(error);
      generateResult(
        "error",
        capitalize(e.message) || "An unexpected error occurred."
      );
    }
  };
  function handleNewGroup() {
    setOpenGroup((prev) => !prev);
  }
  function handleModal() {
    if (!isTrue) {
      toggle();
    }
    setOpenModal((prev) => !prev);
  }
  const onChange: TimePickerProps["onChange"] = (time, timeString) => {
    setSchedule(timeString);
  };
  const handleTime = (time: string) => {
    setSchedule((prev) => prev + time);
  };
  const [msgID, setmsgId] = useState<string>("");

  const { trigger: submitMsg } = useSWRMutation(
    `api/messages/${msgID ? msgID : ""}?`,
    swrMutationFetcher
  );
  const { cancelDraft, debounceTimeout } = useDraft();
  const handleDraft = useCallback(() => {
    // Clear the previous timeout if it exists
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout
    debounceTimeout.current = setTimeout(async () => {
      try {
        if (audio) {
          type Response = {
            secure_url: string;
          };

          const formData = new FormData();
          formData.append("media", audio);
          formData.append("type", "Message");
          const response: SuccessDataType<Response> = await uploadVoiceMessage({
            token,
            requestOption: {
              method: "POST",
              body: formData,
            },
          });
          if (response && response.data.secure_url) {
            setAudioURL(response.data.secure_url);
            formData.append("body", response.data.secure_url);
          }
          formData.delete("type");
          if (watch("date") && watch("time")) {
            const combinedDateTime = combineDateTime(
              watch("date"),
              watch("time")
            );
            if (combinedDateTime)
              formData.append("scheduledFor", combinedDateTime);
          }

          if (watch("msgTitle")) formData.append("title", watch("msgTitle"));
          formData.append("type", "voice");

          formData.append("isDraft", "true");

          // if (groupIds) {
          //   groupIds.forEach((id) => formData.append("contactGroupIds[]", id));
          // }
          formData.append("body", audio);

          if (watch("recipient")) {
            const contactGroupIds = [];
            contactGroupIds.push(watch("recipient"));
            contactGroupIds.forEach((id) => {
              formData.append("contactGroupIds[]", id); // Append each ID as part of an array
            });
          }
          const fetcherArg: SwrMutationFetcherArgType = {
            token,
            requestOption: {
              method: msgID ? "PATCH" : "POST",
              body: formData,
            },
          };

          if (watch("msgTitle") && watch("recipient")) {
            type response = {
              id: string;
            };
            const result: SuccessDataType<response> = await submitMsg(
              fetcherArg
            );
            hasUpdated.current = true; // Set the flag to prevent further updates

            if (!msgID || !hasUpdated.current) setmsgId(result.data.id);
          }
        }
      } catch (error) {
        console.error("Failed to submit draft:", error);
      }
    }, 1500); // 45 seconds delay

    // Clean up the timeout on unmount or when the effect is re-run
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [
    debounceTimeout,
    watch,
    schedule,
    audio,
    uploadVoiceMessage,
    token,
    msgID,
    submitMsg,
  ]);
  const handleConfirmationModal = () => {
    setOpen((prev) => !prev);
  };
  // Effect to call handleDraft whenever content changes
  useEffect(() => {
    handleDraft();
  }, [audio, handleDraft]);
  const watchedRecipient = watch(["recipient"]);
  useEffect(() => {
    if (watch("recipient")) {
      setRecipients(watch("recipient"));
    }
  }, [watch, watchedRecipient]);
  const handleMobileConfirmationModal = () => {
    setOpenMobile((prev) => !prev);
  };
  useEffect(() => {
    if (MessageDetails) {
      setValue("msgTitle", MessageDetails.title);
      // setValue("date", dayjs(MessageDetails.scheduledFor).toISOString());

      // setValue("time", dayjs(MessageDetails.scheduledFor).toISOString());
      setValue("from", MessageDetails.senderName);
    }
  }, [MessageDetails, setValue]);
  function handleReset() {
    setValue("date", "");
    setValue("time", "");
  }
  useEffect(() => {
    if (msgId) {
      setmsgId(msgId);
    }
  }, [msgId]);
  return (
    <>
      <ConfirmationModal
        handleCancel={handleModal}
        open={openMobile}
        reset={handleReset}
        handleConfirmationModal={handleMobileConfirmationModal}
      />
      <ConfirmationModal
        handleCancel={handleNewGroup}
        open={open}
        reset={handleReset}
        handleConfirmationModal={handleConfirmationModal}
      />
      <ReusableModal
        title="Schedule message"
        handleModal={handleModal}
        isModalOpen={openModal}
        centered
        footer={false}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={s.form}>
            <div className={s.scheduleWrapper}>
              <div className={clsx(s.inputWrapper)}>
                <h5 className={clsx(s.inputLabel)}>Select date</h5>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      onChange={(date, dateString) => {
                        if (date) {
                          const isValidDate = dayjs(date.toString()).isValid();
                          if (isValidDate) {
                            field.onChange(date); // Set the value if valid
                          } else {
                            field.onChange(null); // Clear the field if invalid

                          }
                        }
                      }}
                      value={watch("date") ? dayjs(watch("date")) : null} // Ensure value is a Day.js object
                      className={clsx(s.input, {
                        [s.inputError]: errors.date,
                      })}
                      placeholder="MM/YYYY"
                    />
                  )}
                />
              </div>
              <div className={clsx(s.inputWrapper)}>
                <h5 className={clsx(s.inputLabel)}>Time</h5>
                <Controller
                  name="time"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      className={clsx(s.input)}
                      defaultValue={
                        msgId ? dayjs(MessageDetails?.scheduledFor) : null
                      } // Use dayjs to convert the string to a Dayjs object
                      onChange={(time, timeString) => {
                        if (time) {
                          field.onChange(time); // Set the value
                        }
                      }}
                      changeOnScroll
                      format="HH:mm"
                      needConfirm={false}
                      suffixIcon={
                        <ReusablePopover
                          placement="left"
                          trigger="click"
                          content={<NewPopOverItems handleTime={handleTime} />}
                        >
                          <ArrowDown height={16} width={16} fill="#6A6767" />
                        </ReusablePopover>
                      }
                    />
                  )}
                />
              </div>
            </div>
            <div className={s.addContactBtns}>
              <Button
                type="default"
                className={clsx(s.button, s.cancelBtn)}
                onClick={handleMobileConfirmationModal}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className={clsx(s.button, s.addContactBtn)}
                onClick={() => {
                  generateResult("success", "Schedule set successfully");
                  handleModal();
                }}
              >
                Schedule
              </Button>
            </div>
          </div>
        </form>
      </ReusableModal>
      <ReusableDrawer
        title="Schedule message"
        Open={openGroup}
        handleOpen={handleNewGroup}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={s.form}>
            <div className={s.scheduleWrapper}>
              <div className={clsx(s.inputWrapper)}>
                <h5 className={clsx(s.inputLabel)}>Select date</h5>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      onChange={(date, dateString) => {
                        if (date) {
                          const isValidDate = dayjs(date.toString()).isValid();
                          if (isValidDate) {
                            field.onChange(date); // Set the value if valid
                          } else {
                            field.onChange(null); // Clear the field if invalid

                          }
                        }
                      }}
                      value={watch("date") ? dayjs(watch("date")) : null} // Ensure value is a Day.js object
                      className={clsx(s.input, {
                        [s.inputError]: errors.date,
                      })}
                      placeholder="MM/YYYY"
                    />
                  )}
                />
              </div>
              <div className={clsx(s.inputWrapper)}>
                <h5 className={clsx(s.inputLabel)}>Time</h5>
                <Controller
                  name="time"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      defaultValue={
                        msgId ? dayjs(MessageDetails?.scheduledFor) : null
                      } // Use dayjs to convert the string to a Dayjs object
                      onChange={(time, timeString) => {
                        if (time) {
                          field.onChange(time); // Set the value
                        }
                      }}
                      className={clsx(s.input)}
                      changeOnScroll
                      format="HH:mm"
                      needConfirm={false}
                      suffixIcon={
                        <ReusablePopover
                          placement="left"
                          trigger="click"
                          content={<NewPopOverItems handleTime={handleTime} />}
                        >
                          <ArrowDown height={16} width={16} fill="#6A6767" />
                        </ReusablePopover>
                      }
                    />
                  )}
                />
              </div>
            </div>
            <div className={s.addContactBtns}>
              <Button
                type="default"
                className={clsx(s.button, s.cancelBtn)}
                onClick={handleConfirmationModal}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className={clsx(s.button, s.addContactBtn)}
                onClick={() => {
                  generateResult("success", "Schedule set successfully");
                  handleNewGroup();
                }}
              >
                Schedule
              </Button>
            </div>
          </div>
        </form>
      </ReusableDrawer>
      {isLoading || error ? (
        <div className={s.spin}>
          <Spin />
        </div>
      ) : (
        <div className={s.wrapper}>
          <div className={s.emailTitles}>
            <div className={s.msgTitleWrapper}>
              <h1>
                {msgId && MessageDetails
                  ? MessageDetails.title
                  : watch("msgTitle") || "Untitled"}
              </h1>
            </div>
            <div className={s.inputWrapper}>
              <h5 className={s.label}>Message title</h5>
              <Controller
                name="msgTitle"
                control={control}
                rules={{ required: "Message title is required" }}
                render={({ field }) => (
                  <Input
                    {...field}
                    defaultValue={MessageDetails ? MessageDetails.title : ""}
                    count={{
                      show: true,
                      max: 80,
                    }}
                    className={clsx(s.input, {
                      [s.inputError]: errors.msgTitle,
                    })}
                    maxLength={80}
                  />
                )}
              />
              {!errors.msgTitle && (
                <span className={s.helperText}>
                  <p className={s.titleMsg}>
                    This is just an internal title to help organize and locate
                    your message easily. This does not appear to your audience.
                  </p>
                </span>
              )}
              {errors.msgTitle && (
                <span className={clsx(s.errorMessage)}>
                  {errors.msgTitle.message}
                </span>
              )}
            </div>
            <div className={s.inputWrapper}>
              <h5 className={s.label}>Recipient</h5>
              <Controller
                name="recipient"
                control={control}
                rules={{ required: "Recipient is required" }}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    bordered
                    placeholder="Select contact group that will get your message"
                    wrapperClassname={s.SelectWrapper}
                    options={
                      groupId && group
                        ? [
                            {
                              label: group.name,
                              value: group._id,
                            },
                          ]
                        : groups?.docs.map((group) => ({
                            label: group.name,
                            value: group._id,
                          }))
                    }
                    style={{ width: "100%", height: "100%" }}
                    onChange={field.onChange}
                  />
                )}
              />
              {loading ? (
                <Oval
                  visible={loading}
                  height="16"
                  width="16"
                  color="#d52b1e"
                  ariaLabel="oval-loading"
                />
              ) : (
                !errors.recipient &&
                Recipient && (
                  <p className={s.helperText}>
                    {Recipient?.contacts.docs.length} Contacts
                  </p>
                )
              )}
              {errors.recipient && !Recipient?.contacts && (
                <span className={clsx(s.errorMessage)}>
                  {errors.recipient.message}
                </span>
              )}
            </div>
            {/* <div className={clsx(s.inputWrapper)}>
            <h5 className={s.label}>From</h5>
            <Controller
              name="from"
              control={control}
              rules={{ required: "Phone number is required" }}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  country={"ng"} // default country
                  enableSearch={true} // Enable searching countries
                  placeholder="Enter phone number"
                  onChange={field.onChange} // directly update field value
                  containerClass={s.phoneInputContainer} // Added for custom CSS
                  dropdownClass={s.phoneInputDropdown}
                  inputClass={clsx(
                    s.input,
                    { [s.inputError]: errors.from },
                    s.PhoneInput
                  )} // Phone number input field class
                  buttonClass={s.flagDropdown} // Dropdown for flag and country code
                />
              )}
            />
            {errors.from && (
              <span className={clsx(s.errorMessage)}>
                {errors.from.message}
              </span>
            )}
          </div> */}
          </div>

          <div className={s.textEditor}>
            <div className={s.editorBtnGroup}>
              <button
                type="button"
                className={clsx(s.scheduleBtn)}
                onClick={handleNewGroup}
                disabled={isSubmitting}
              >
                Schedule
              </button>
              {/* Button to show scheduling mobile modal */}
              <button
                type="button"
                className={clsx(s.mobileScheduleBtn)}
                onClick={handleModal}
                disabled={isSubmitting}
              >
                Schedule
              </button>
              <button
                type="button"
                className={clsx(s.submitBtn)}
                onClick={handleSubmit(onSubmit)}
                disabled={isSubmitting}
              >
                <Oval
                  visible={isSubmitting}
                  height="16"
                  width="16"
                  color="white"
                  ariaLabel="oval-loading"
                />
                Send now
              </button>
            </div>
            <div className={s.audio}>
              <AudioRecorder
                body={MessageDetails?.body}
                onAudioSave={handleAudioSave}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default VoiceMessage;
