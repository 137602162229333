import clsx from "clsx";
import React from "react";
import { IconsType } from "../../../libs/Utils/types";
import s from "./ArrowRemove.module.scss";
type prop = IconsType & {
  onClick: () => void;
};
function AvatarRemove({ onClick, fill, height, width }: prop) {
  return (
    <div onClick={onClick} className={clsx(!width && s.wrapper)}>
      <svg
        viewBox="0 0 44 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: width || "100%",
          height: height || "100%",
        }}
        preserveAspectRatio="xMidYMid meet"
      >
        <rect
          x="1.0625"
          y="1.5625"
          width="41.875"
          height="41.875"
          rx="20.9375"
          fill="#F0EFEE"
          stroke="white"
          stroke-width="1.875"
        />
        <path
          d="M20.7487 22.0615C22.8198 22.0615 24.4987 20.2942 24.4987 18.1141C24.4987 15.934 22.8198 14.1667 20.7487 14.1667C18.6776 14.1667 16.9987 15.934 16.9987 18.1141C16.9987 20.2942 18.6776 22.0615 20.7487 22.0615Z"
          fill="#6A6767"
        />
        <path
          d="M21.9987 22.9386H19.4987C18.394 22.94 17.335 23.4026 16.5539 24.2248C15.7728 25.047 15.3334 26.1618 15.332 27.3246V29.9562C15.332 30.1888 15.4198 30.412 15.5761 30.5765C15.7324 30.741 15.9444 30.8334 16.1654 30.8334H25.332C25.553 30.8334 25.765 30.741 25.9213 30.5765C26.0776 30.412 26.1654 30.1888 26.1654 29.9562V27.3246C26.164 26.1618 25.7246 25.047 24.9435 24.2248C24.1624 23.4026 23.1034 22.94 21.9987 22.9386Z"
          fill="#6A6767"
        />
        <path
          d="M31.1654 22.0615H26.1654C25.9444 22.0615 25.7324 21.969 25.5761 21.8045C25.4198 21.64 25.332 21.4169 25.332 21.1843C25.332 20.9516 25.4198 20.7285 25.5761 20.564C25.7324 20.3995 25.9444 20.3071 26.1654 20.3071H31.1654C31.3864 20.3071 31.5983 20.3995 31.7546 20.564C31.9109 20.7285 31.9987 20.9516 31.9987 21.1843C31.9987 21.4169 31.9109 21.64 31.7546 21.8045C31.5983 21.969 31.3864 22.0615 31.1654 22.0615Z"
          fill="#6A6767"
        />
      </svg>
    </div>
  );
}

export default AvatarRemove;
