import clsx from "clsx";
import s from "./ResetCode.module.scss";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OtpInputs from "../../../global components/OtpInputs";
import useSWRMutation from "swr/mutation";
import {
  parseClientError,
  swrMutationFetcher,
  SwrMutationFetcherArgType,
} from "../../../libs/api/Endpoints";
import { capitalize, useQuery } from "../../../libs/common";
import Navigation from "../../../global components/Navigation/Navigation";
import { generateResult } from "../../../global components/ResultModal/ResultModal";
import { SuccessDataType } from "../../../libs/api/types";
interface resendOtp {
  email: string;
  password: string;
}
function EmailVerification() {
  const query = useQuery();
  const userId = query.get("userId");
  const email = query.get("email");
  const navigate = useNavigate();
  const location = useLocation();

  // Destructure the state with default values to avoid issues if the state is undefined
  const { password = "" } = (location.state as resendOtp) || {};
  const { trigger: enterOTP, isMutating } = useSWRMutation(
    "api/auth/verify-otp",
    swrMutationFetcher
  );
  const { trigger: reSendOTP } = useSWRMutation(
    "api/auth/sign-in",
    swrMutationFetcher
  );

  const handleResendOTP = async () => {
    const urlEncodedData = new URLSearchParams();
    if (email) urlEncodedData.append("email", email);
    urlEncodedData.append("password", password);
    try {
      const fetcherArg: SwrMutationFetcherArgType = {
        requestOption: {
          body: urlEncodedData,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      };
      await reSendOTP(fetcherArg);

      generateResult("success", "Your One-Time Passcode (OTP) has been Sent");
    } catch (error) {
      const e = parseClientError(error);
      generateResult("error", capitalize(e.message));
    }
  };
  const handleOtpSubmit = async (otp: string) => {
    const urlEncodedData = new URLSearchParams();
    if (userId && email) {
      urlEncodedData.append("userId", userId);
      urlEncodedData.append("otp", otp);
      urlEncodedData.append("type", "email");
    }

    try {
      const fetcherArg: SwrMutationFetcherArgType = {
        requestOption: {
          method: "post",
          body: urlEncodedData,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      };
      type response = {
        token: string;
      };
      const result: SuccessDataType<response> = await enterOTP(fetcherArg);
      console.log(result.data.token)
      localStorage.setItem("token", result.data.token);
      generateResult(
        "success",
        "Account verified!",
        "Complete your journey by verifying your phone number"
      );
      navigate("/auth/verify-phone-number");
    } catch (error: any) {
      const e = parseClientError(error);
      generateResult("error", capitalize(e.message));
    }
  };
  return (
    <div className={s.wrapper}>
      <div className={s.navigate}>
        <Navigation />
      </div>
      <div className={s.mainContent}>
        <span className={s.titles}>
          <h3 className={s.title}>Verify email address</h3>
          <p className={s.subtitle}>
            Provide the code that was sent to {email}
          </p>
        </span>
        <div className={clsx(s.form)}>
          <OtpInputs
            numInputs={6} // Number of OTP inputs
            handleSubmit={handleOtpSubmit} // Your submit function
            otpLengthCheck={4} // Optional check for OTP length
            errorMessage="Please enter the complete OTP"
            buttonLabel="Continue"
            loading={isMutating}
            buttonClassName={s.button} // Pass custom button class if needed
          />
        </div>

        <div className={s.createAccount}>
          <p>
            Did not get the code? &nbsp;
            <span onClick={handleResendOTP} className={s.links}>
              Resend code
            </span>
            &nbsp; or &nbsp;
            <Link to="/auth/forgot-password" className={s.links}>
              Change email
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default EmailVerification;
