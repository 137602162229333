import React, { useCallback, useMemo, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./QuilEditor.scss";

// Register the bullet list format

type RichTextEditorProps = {
  value: string | undefined;
  handleEdit: (value: string) => void;
  modules?: any;
  handleImageUpload?: (blob: Blob) => void;
  sms?:boolean
};

const RichTextEditor = ({
  value,
  handleEdit,
  modules,
  handleImageUpload,
  sms
}: RichTextEditorProps) => {
  const quillRef = useRef<ReactQuill | null>(null);

  // Function to convert file to base64 for display in the editor
  const readAsDataURL = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  // Custom image handler, called only when the image button is clicked
  const selectLocalImage = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");

    input.onchange = async () => {
      if (input.files && input.files.length > 0) {
        const file = input.files[0];
        const blob = new Blob([file], { type: file.type });

        try {
          // Convert the image to base64 URL
          const base64URL = await readAsDataURL(file);

          // Insert the image into the editor content
          const quill = quillRef.current?.getEditor();
          const range = quill?.getSelection(); // Get the current cursor position
          if (range && quill) {
            quill.insertEmbed(range.index, "image", base64URL); // Insert image at cursor position
          }

          // Emit the Blob to the parent
          if (handleImageUpload) {
            handleImageUpload(blob);
          }
        } catch (error) {
          console.error("Error uploading image: ", error);
          // Optionally, you can alert the user or handle the error in a user-friendly way
          alert("Failed to upload the image. Please try again.");
        }
      }
    };

    input.click(); // Trigger file input click here
  }

  // Memoize the toolbar modules with the custom image handler
  const modulesWithImageHandler = useMemo(() => {
    return {
      toolbar: {
        container: sms?["image"]:[
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ align: [] }, { color: [] }, { background: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "image"],
        ],
        handlers: {
          image: selectLocalImage, // Custom image handler for image upload
        },
      },
    };
  }, []);

  return (
    <div>
      <ReactQuill
        ref={quillRef} // Attach the ref to access Quill instance
        value={value} // Controlled by parent
        onChange={handleEdit} // Passes updated content to parent
        placeholder="Type your message here"
        modules={modules || modulesWithImageHandler} // Use modules with image handler
        formats={[
          "header",
          "font",
          "size",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list", // Use 'list' instead of 'bullet'
          "align",
          "color",
          "background",
          "link",
          "image", // Image is part of the formats
        ]}
      />
    </div>
  );
};

export default RichTextEditor;
