import React, { createContext, useContext, useState, ReactNode } from "react";

interface DraftContextType {
  content: string;
  title: string;
  recipient: string;
  emailSubject: string;
  ID:string
  setContent: (value: string) => void;
  setTitle: (value: string) => void;
  setRecipient: (value: string) => void;
  setEmailSubject: (value: string) => void;
  setID: (value: string) => void;
}

const DraftContext = createContext<DraftContextType | undefined>(undefined);

export const DraftProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [content, setContent] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [recipient, setRecipient] = useState<string>("");
  const [emailSubject, setEmailSubject] = useState<string>("");
    const[ID,setID]=useState<string>("")
  return (
    <DraftContext.Provider
      value={{
        content,
        title,
        recipient,
        emailSubject,
        ID,
        setContent,
        setTitle,
        setRecipient,
        setEmailSubject,
        setID
      }}
    >
      {children}
    </DraftContext.Provider>
  );
};
export const useDraft = () => {
    const context = useContext(DraftContext);
    if (!context) {
      throw new Error("useDraft must be used within a DraftProvider");
    }
    return context;
  };
  