import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import s from "./NotFound.module.scss"
function NotFound() {
  const navigate = useNavigate();
  return (
    <div
    className={s.container}
     
    >
      <h3>Page not found</h3>
      <Button
        style={{ backgroundColor: "#d52b1e", color: "white" }}
        onClick={() => navigate("/dashboard")}
      >
        Go back to Dashboard
      </Button>
    </div>
  );
}

export default NotFound;
