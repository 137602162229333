import React from "react";
import { IconsType } from "../../../libs/Utils/types";
import s from "./Envelope.module.scss"
import clsx from "clsx";
function Envelope({ width, height, fill }: IconsType) {
  return (
    <div className={clsx(!width && s.wrapper)}>
      <svg
       
        viewBox="0 0 20 15"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: width || "100%",
          height: height || "100%",
        }}
        preserveAspectRatio="xMidYMid meet"
      >
        <path
          d="M10 8.68754L19.275 1.27501C18.9189 0.992592 18.4673 0.836582 18 0.834473H2C1.53267 0.836582 1.08115 0.992592 0.725 1.27501L10 8.68754Z"
          fill={fill}
        />
        <path
          d="M11.25 10.1463C10.8861 10.4062 10.4412 10.5481 9.983 10.5505C9.556 10.5513 9.14034 10.4222 8.799 10.1827L0 3.15287V12.9678C0 13.4629 0.210714 13.9377 0.585786 14.2877C0.960859 14.6378 1.46957 14.8345 2 14.8345H18C18.5304 14.8345 19.0391 14.6378 19.4142 14.2877C19.7893 13.9377 20 13.4629 20 12.9678V3.15287L11.25 10.1463Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}

export default Envelope;
