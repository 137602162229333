import React from "react";
import { IconsType } from "../../../libs/Utils/types";
import clsx from "clsx";
import s from "./UploadContacts.module.scss"
function UploadContacts({ fill, height, width }: IconsType) {
  return (
    <div className={clsx(!width && s.wrapper)}>
      <svg
       
        viewBox="0 0 27 22"
        fill={fill || "none"}
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: width || "100%",
          height: height || "100%",
        }}
        preserveAspectRatio="xMidYMid meet"

      >
        <path
          d="M13.3333 22.0001C12.5973 22.0001 12 21.4041 12 20.6667V19.3334H14.6667V20.6667C14.6667 21.4041 14.0693 22.0001 13.3333 22.0001Z"
          fill={fill}
        />
        <path
          d="M22.652 8.83208C22.392 4.28541 18.6107 0.666748 14 0.666748C10.4733 0.666748 7.31333 2.82941 5.992 6.03341C2.632 6.37341 0 9.21875 0 12.6667C0 16.3427 2.99067 19.3334 6.66667 19.3334H12V11.8854L11.6093 12.2761C11.088 12.7974 10.2453 12.7974 9.724 12.2761C9.20267 11.7547 9.20267 10.9121 9.724 10.3907L12.3907 7.72408C12.912 7.20275 13.7547 7.20275 14.276 7.72408L16.9427 10.3907C17.464 10.9121 17.464 11.7547 16.9427 12.2761C16.6827 12.5361 16.3413 12.6667 16 12.6667C15.6587 12.6667 15.3173 12.5361 15.0573 12.2761L14.6667 11.8854V19.3334H21.3333C24.2747 19.3334 26.6667 16.9414 26.6667 14.0001C26.6667 11.5147 24.9573 9.42008 22.652 8.83208Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}

export default UploadContacts;
