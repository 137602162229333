import clsx from "clsx";
import s from "./ResetCode.module.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import OtpInputs from "../../../global components/OtpInputs";
import { generateResult } from "../../../global components/ResultModal/ResultModal";
import {
  parseClientError,
  swrFetcher,
  swrMutationFetcher,
  SwrMutationFetcherArgType,
} from "../../../libs/api/Endpoints";
import { UseAuthToken } from "../../../libs/api/userSession";
import useSWRMutation from "swr/mutation";
import useSWR from "swr";
import { User } from "../../../libs/redux/auth/signinSlice";
import Navigation from "../../../global components/Navigation/Navigation";
import { capitalize } from "../../../libs/common";
import { SuccessDataType } from "../../../libs/api/types";
import { useToggle } from "../../../global components/Context/WelcomeContex";

function PhoneNumberVerification() {
  const { toggle,isFromSignUp } = useToggle();

  const { phonenumber } = useParams(); // Get email from URL params
  // Handle form submission
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const { data } = useSWR<User, any, any>(
    token ? [`api/users/me`, token] : null,
    swrFetcher,
    {
      revalidateOnFocus: true,

      shouldRetryOnError: false,
    }
  );
  const { trigger: resendOtp, isMutating: loading } = useSWRMutation(
    "api/users/me/verify-phone",
    swrMutationFetcher
  );
  const { trigger: verifyPhone, isMutating } = useSWRMutation(
    "api/users/me/check-otp",
    swrMutationFetcher
  );
  const handleResendOTP = async () => {
    const urlEncodedData = new URLSearchParams();
    if (phonenumber) urlEncodedData.append("phoneNo", phonenumber);
    try {
      const fetchArg: SwrMutationFetcherArgType = {
        token,
        requestOption: {
          body: urlEncodedData,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      };
      const response = await resendOtp(fetchArg);

      generateResult("success", "Your One-Time Passcode (OTP) has been Sent");

      localStorage.setItem("phoneNo", JSON.stringify(phonenumber));
    } catch (error: any) {
      const e = parseClientError(error);
      generateResult("error", capitalize(e.message) || "");
    }
  };
  const handleOtpSubmit = async (otp: string) => {
    const urlEncodedData = new URLSearchParams();
    urlEncodedData.append("isFromSignup", "true");

    if (otp) urlEncodedData.append("otp", otp);

    try {
      const fetchArg: SwrMutationFetcherArgType = {
        token,
        requestOption: {
          body: urlEncodedData,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      };
     
      let result:SuccessDataType<response>;
      type response = {
        token: string;
      };
      if (data?._id) {
        result = await verifyPhone(fetchArg);
        localStorage.setItem("token", result.data.token);
      }
      if (phonenumber) localStorage.setItem("phoneNo", phonenumber);
      //generateResult("success", "Phone number verified");
      toggle(true);
      isFromSignUp(true)
      navigate(`/dashboard`);
    } catch (error) {
      const e = parseClientError(error);
      generateResult("error", capitalize(e.message) || "");
    }
  };
  return (
    <div className={s.wrapper}>
      <div className={s.navigate}>
        <Navigation />
      </div>
      <div className={s.mainContent}>
        <span className={s.titles}>
          <h3 className={s.title}>Verify phone number</h3>
          <p className={s.subtitle}>
            Provide the code that was sent to {phonenumber}
          </p>
        </span>
        <div className={clsx(s.form)}>
          <OtpInputs
            numInputs={6} // Number of OTP inputs
            handleSubmit={handleOtpSubmit} // Your submit function
            otpLengthCheck={4} // Optional check for OTP length
            errorMessage="Please enter the complete OTP"
            buttonLabel="Continue"
            loading={isMutating}
            buttonClassName={s.button} // Pass custom button class if needed
          />
        </div>

        <div className={s.createAccount}>
          <p>
            Did not get the code? &nbsp;
            <span onClick={handleResendOTP} className={s.links}>
              Resend code
            </span>
            &nbsp; or &nbsp;
            <Link to="/auth/verify-phone-number" className={s.links}>
              Change phone number
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default PhoneNumberVerification;
