import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the shape of our context
interface ToggleContextType {
  isTrue: boolean;
  toggle: () => void;
}

// Create the context with a default value
const ToggleContext = createContext<ToggleContextType | undefined>(undefined);

// Create a provider component
export const ToggleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isTrue, setIsTrue] = useState(false);

  // Define a toggle function to switch between true and false
  const toggle = () => setIsTrue((prev) => !prev);

  return (
    <ToggleContext.Provider value={{ isTrue, toggle }}>
      {children}
    </ToggleContext.Provider>
  );
};

// Custom hook for consuming the context
export const useToggle = (): ToggleContextType => {
  const context = useContext(ToggleContext);
  if (context === undefined) {
    throw new Error('useToggle must be used within a ToggleProvider');
  }
  return context;
};
