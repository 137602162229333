import React from "react";
import { IconsType } from "../../../libs/Utils/types";
import clsx from "clsx";
import s from "./BackArrow.module.scss";
type BackArrowprops = IconsType & {
  onClick: () => void;
};
function BackArrow({ onClick, fill, height, width }: BackArrowprops) {
  return (
    <div onClick={onClick} className={clsx(!width && s.wrapper)}>
      <svg
        viewBox="0 0 24 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: width || "100%",
          height: height || "100%",
        }}
        preserveAspectRatio="xMidYMid meet"
      >
        <path
          d="M22.2858 8.15461H5.85359L9.78423 3.8752C9.94795 3.70304 10.0785 3.4971 10.1684 3.26941C10.2582 3.04171 10.3055 2.79682 10.3075 2.54901C10.3095 2.30121 10.2661 2.05546 10.1799 1.8261C10.0937 1.59674 9.96642 1.38836 9.80548 1.21313C9.64453 1.0379 9.45313 0.899319 9.24247 0.80548C9.0318 0.711641 8.80608 0.664421 8.57847 0.666574C8.35086 0.668728 8.12592 0.720212 7.91678 0.818022C7.70765 0.915833 7.51849 1.05801 7.36037 1.23626L0.503608 8.70144C0.343972 8.8748 0.217317 9.08075 0.1309 9.30748C0.0444827 9.53422 0 9.77729 0 10.0228C0 10.2683 0.0444827 10.5113 0.1309 10.7381C0.217317 10.9648 0.343972 11.1707 0.503608 11.3441L7.36037 18.8093C7.68367 19.1492 8.11667 19.3374 8.56613 19.3331C9.01558 19.3288 9.44552 19.1326 9.76335 18.7865C10.0812 18.4405 10.2615 17.9724 10.2654 17.4831C10.2693 16.9938 10.0965 16.5223 9.78423 16.1703L5.85359 11.8872H22.2858C22.7404 11.8872 23.1765 11.6906 23.4979 11.3406C23.8194 10.9906 24 10.5159 24 10.0209C24 9.52593 23.8194 9.05123 23.4979 8.70124C23.1765 8.35124 22.7404 8.15461 22.2858 8.15461Z"
          fill="#1F2A37"
        />
      </svg>
    </div>
  );
}

export default BackArrow;
