import React, { useEffect, useState } from "react";
import { Avatar, Checkbox } from "antd";
import "./ContactCheckBox.scss";
import ContactAvatar from "../Icons/ContactAvatar/ContactAvatar";

interface Contact {
  _id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
}

interface CheckboxListProps {
  contacts: Contact[];
  defaultChecked?: string[];
  onSelectionChange: (selectedIds: string[]) => void;
}

function ContactCheckboxList({
  contacts,
  onSelectionChange,
  defaultChecked = [],
}: CheckboxListProps) {
  const [selectedIds, setSelectedIds] = useState<string[]>(defaultChecked);

  // Handle checkbox changes
  const handleCheckboxChange = (contactId: string, isChecked: boolean) => {
    if (isChecked) {
      setSelectedIds((prev) => [...prev, contactId]);
    } else {
      setSelectedIds((prev) => prev.filter((id) => id !== contactId));
    }
  };

  // Handle the "Select All" checkbox change
  const handleSelectAllChange = (isChecked: boolean) => {
    if (isChecked) {
      // If checked, select all contact IDs
      setSelectedIds(contacts.map((contact) => contact._id));
    } else {
      // If unchecked, clear the selection
      setSelectedIds([]);
    }
  };

  // Notify parent component whenever selection changes
  useEffect(() => {
    onSelectionChange(selectedIds);
  }, [selectedIds, onSelectionChange]);

  // Determine if all checkboxes are selected
  const allSelected = selectedIds.length === contacts.length;

  // Determine if the checkbox should be in the indeterminate state
  const indeterminate = selectedIds.length > 0 && !allSelected;

  return (
    <div className="checkbox-wrapper">
      {/* "Select All" Checkbox */}
      <div className="select-all">
        <Checkbox
          checked={allSelected}
          indeterminate={indeterminate} // Set indeterminate state
          onChange={(e) => handleSelectAllChange(e.target.checked)}
        >
          Select All
        </Checkbox>
      </div>

      {contacts.map((contact) => (
        <div key={contact._id} className="contact-item">
          {contact.firstName && contact.lastName ? (
            <div className="contact-info">
              <div className="profile">
                {contact.firstName[0].toUpperCase() +
                  " " +
                  contact.lastName[0].toUpperCase()}
              </div>
              <span>
                <p className="profileInfo">{`${
                  contact.firstName
                    ? contact.firstName.charAt(0).toUpperCase() +
                      contact.firstName.slice(1)
                    : ""
                } ${
                  contact.lastName
                    ? contact.lastName.charAt(0).toUpperCase() +
                      contact.lastName.slice(1)
                    : ""
                }`}</p>
              </span>
            </div>
          ) : (
            <div className="contact-info">
              <ContactAvatar height={30} width={30} />
              <span>
                <h5 className="profileInfo">
                  {contact.email || contact.phone || "Unknown Contact"}
                </h5>
              </span>
            </div>
          )}
          <Checkbox
            checked={selectedIds.includes(contact._id)}
            onChange={(e) =>
              handleCheckboxChange(contact._id, e.target.checked)
            }
          />
        </div>
      ))}
    </div>
  );
}

export default ContactCheckboxList;
