import clsx from "clsx";
import React from "react";
import { IconsType } from "../../../libs/Utils/types";
import s from "./Cancel.module.scss"
type prop=IconsType &{
    onClick: ()=>void
}
function Cancel({onClick,fill,height,width}:prop) {
  return (
    <div onClick={onClick}  className={clsx(!width && s.wrapper)}>
      <svg
        
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: width || "100%",
          height: height || "100%",
        }}
        preserveAspectRatio="xMidYMid meet"
      >
        <circle cx="20.5" cy="20.5" r="20.5" fill="#F0EFEE" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.25 15.8075L24.1925 14.75L20 18.9425L15.8075 14.75L14.75 15.8075L18.9425 20L14.75 24.1925L15.8075 25.25L20 21.0575L24.1925 25.25L25.25 24.1925L21.0575 20L25.25 15.8075Z"
          fill="#6A6767"
        />
      </svg>
    </div>
  );
}

export default Cancel;
