import React, { createContext, useContext, useRef, ReactNode } from 'react';

// Define a type for the context value
interface DraftContextType {
  cancelDraft: () => void;
  debounceTimeout: React.MutableRefObject<NodeJS.Timeout | null>;
}

// Create a context with a default value of null
const DraftContext = createContext<DraftContextType | undefined>(undefined);

// Custom hook to use the DraftContext
export const useDraft = () => {
  const context = useContext(DraftContext);
  if (!context) {
    throw new Error('useDraft must be used within a DraftProvider');
  }
  return context;
};

// Define the props for the DraftProvider
interface DraftProviderProps {
  children: ReactNode;
}

export const DraftProvider: React.FC<DraftProviderProps> = ({ children }) => {
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const cancelDraft = () => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
      debounceTimeout.current = null; // Reset to null after clearing
    }
  };

  return (
    <DraftContext.Provider value={{ cancelDraft, debounceTimeout }}>
      {children}
    </DraftContext.Provider>
  );
};
