"use client";
import React, { ComponentPropsWithRef, forwardRef } from "react";
import s from "./selectField.module.scss";
import { Select as AntdSelect } from "antd";
import clsx from "clsx";
import ArrowDown from "../../Icons/ArrowDown";
interface PropsTypes extends ComponentPropsWithRef<typeof AntdSelect> {
  wrapperClassname?: string;
  label?: React.ReactNode;
}


const SelectField = forwardRef<any, PropsTypes>(function (
  { className, wrapperClassname = "", bordered, label = null, ...rest },
  ref
) {
  return (
    <div
      className={clsx(s.wrapper, wrapperClassname, bordered && s.wrapperBorder)}
    >
      {label && <p className={s.label}>{label}</p>}
      <AntdSelect
        ref={ref}
        bordered={false}
        className={clsx(s.selectContainer, className)}
        suffixIcon={<ArrowDown fill="#1F2A37" height={4.7} width={8.75}/>}
        {...rest}
      />
    </div>
  );
});

SelectField.displayName = "SelectField";

export default SelectField;
