import React, { useEffect, useRef, useState } from "react";
import s from "./Header.module.scss";
import logo from "../../../assets/images/Cloudivi red.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Hamburger from "../../../global components/Icons/Hamburger";
import gsap from "gsap";
import clsx from "clsx";
import Close from "../../../global components/Icons/Close";
import { motion } from "framer-motion";
function Header() {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState<boolean | null>(null);
  const mobileNavRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const currentPath = location.pathname;
  useEffect(() => {
    if (showMenu) {
      // Set display to block before opening animation
      gsap.set(mobileNavRef.current, { display: "flex" });
      gsap.fromTo(
        mobileNavRef.current,
        { width: "100%", height: "30%", top: "0", left: "0" }, // Start state
        {
          width: "100vw",
          height: "100vh",
          duration: 1, // Duration of animation
          ease: "power2.inOut", // Easing for smooth animation
        }
      );
    } else if (showMenu != null && !showMenu) {
      // Animate closing and set display to none after the animation
      gsap.to(mobileNavRef.current, {
        width: "10%",
        height: "10%",
        duration: 0.5, // Faster duration for closing
        ease: "power2.inOut",
        onComplete: () => {
          gsap.set(mobileNavRef.current, { display: "none" });
        },
      });
    }
  }, [showMenu]);
  const handleNavigation = () => {
    if (currentPath === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" }); // Smooth 
    } else {
      navigate("/");
    }
  };
  return (
    <div className={s.wrapper}>
      <div onClick={handleNavigation}>
        <img src={logo} alt="logo" className={s.logo} />
      </div>
      <div className={clsx(s.navigations)}>
        <div className={s.links}>
          <a href="#aboutSection"> Why Cloudivi?</a>
          <a href="#faqSection">FAQs</a>
        </div>

        <span className={s.btnGroups}>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate("/auth/signin")}
            className={s.signinBtn}
          >
            Sign in
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate("/auth/signup")}
            className={s.registerBtn}
          >
            Get started now
          </motion.button>
        </span>
      </div>
      <div ref={mobileNavRef} className={clsx(s.mobileNav)}>
        <div className={s.close} onClick={() => setShowMenu(false)}>
          <Close />
        </div>
        <div className={s.links}>
          <a href="#aboutSection" onClick={() => setShowMenu(false)}>
            Why Cloudivi?
          </a>
          <a href="#faqSection" onClick={() => setShowMenu(false)}>
            FAQs
          </a>
        </div>

        <span className={s.btnGroups}>
          <button
            onClick={() => navigate("/auth/signin")}
            className={s.signinBtn}
          >
            Sign in
          </button>
          <button
            onClick={() => navigate("/auth/signup")}
            className={s.registerBtn}
          >
            Get started now
          </button>
        </span>
      </div>
      <div className={s.hamburger} onClick={() => setShowMenu(true)}>
        <Hamburger />
      </div>
    </div>
  );
}

export default Header;
