import React, { useEffect, useRef, useState } from "react";
import s from "./BuyCredit.module.scss";
import clsx from "clsx";
import { SubmitHandler, useForm } from "react-hook-form";

import { Button, Divider } from "antd";

import { Alert } from "antd";
import Info from "./Info/Info";
import { UseAuthToken } from "../../../../libs/api/userSession";
import useSWRMutation from "swr/mutation";
import { Spin } from "antd";
import {
  parseClientError,
  swrFetcher,
  swrMutationFetcher,
  SwrMutationFetcherArgType,
} from "../../../../libs/api/Endpoints";
import { generateResult } from "../../../../global components/ResultModal/ResultModal";
import { Oval } from "react-loader-spinner";
import useSWR from "swr";
import { capitalize } from "../../../../libs/common";
import { SuccessDataType } from "../../../../libs/api/types";
type Inputs = {
  credits: string;
  promoCode: string;
};
type buycreditProp = {
  paymentUrl: string;
};
type PromoCodeType = {
  name: string; // The name of the promo code
  description: string; // A brief description of the promo code
  type: "amount" | "percentage"; // The type of discount (e.g., fixed amount or percentage)
  creditPercentage: number | null; // The percentage of credit (if applicable, otherwise null)
  maxCredit: number; // Maximum credit the promo code can apply
  totalUsage: number; // How many times the promo code has been used
  usageLimit: number; // The maximum number of uses allowed
};

function BuyCredit() {
  const [value, setValue] = useState<string>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,

    watch,
  } = useForm<Inputs>({ shouldFocusError: false });
  const [credit, setCredit] = useState<boolean | null>(null);
  const token = UseAuthToken();
  const { trigger: buyCredit, isMutating } = useSWRMutation(
    "api/users/me/buy-credits",
    swrMutationFetcher
  );
  const [promo, setPromo] = useState<string>();
  const {
    data: PromoCode,
    error,
    isLoading,
    mutate,
  } = useSWR<PromoCodeType, any, any>(
    token ? [`api/users/me/promo-code`, token, { code: promo || value }] : null,
    swrFetcher,
    {
      isPaused: () => !promo,
      revalidateOnfocus: true,
    }
  );
  const credits = watch("credits"); // Watch the 'credits' field
  const creditAmount = parseInt(credits || "0", 10);
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const urlEncodedData = new URLSearchParams();
    urlEncodedData.append("amount", data.credits);
    if (data.promoCode)
      urlEncodedData.append("promoCode", data.promoCode.trimEnd());
    urlEncodedData.append(
      "returnUrl",
      "https://staging.cloudivi.com/dashboard"
    );
    try {
      const fetcherArg: SwrMutationFetcherArgType = {
        token,
        requestOption: {
          method: "POST",
          body: urlEncodedData,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      };
      type Response={
        paymentUrl:string
      }
      const result:SuccessDataType<Response> = await buyCredit(fetcherArg);
      if (result.data.paymentUrl) {
        window.location.href = result.data.paymentUrl; // Redirect to payment URL
      } else {
        throw new Error("Payment URL not found.");
      }
      //generateResult("success", "success");
    } catch (error) {
      const e = parseClientError(error);
      generateResult(
        "error",
        capitalize(e.message) || "An unexpected error occurred."
      );
    }
  };

  useEffect(() => {
    if (value) {
      setTimeout(() => {
        setPromo(value);
        mutate();
      }, 300);
    }
  }, [mutate, value]);

  console.log(promo, PromoCode);
  let result;

  if (typeof PromoCode === "object" && PromoCode) {
    if (PromoCode.type === "percentage" && PromoCode.creditPercentage) {
      result = (PromoCode.creditPercentage / 100) * creditAmount;
      if (result > PromoCode.maxCredit) {
        result = "+" + PromoCode.maxCredit;
      } else {
        result = "+" + (PromoCode.creditPercentage / 100) * creditAmount;
      }
    } else {
      result = "+" + PromoCode.maxCredit;
    }
  }

  console.log(result);

  return (
    <div className={clsx(s.wrapper)}>
      <section className={s.titles}>
        <h2 className={s.title}>Buy Credits</h2>
        <h5 className={s.subtitle}>
          Top up your account to send messages to your contacts.
        </h5>
      </section>

      <section className={s.mainWrapper}>
        <section className={s.sectionOne}>
          <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={s.inputWrapper}>
              <h5 className={s.creditQuantity}>Credit Quantity</h5>

              <input
                type="number"
                min="0"
                className={clsx(s.inputCredit, {
                  [s.inputError]: errors.credits,
                })}
                {...register("credits", {
                  required: true,
                  min: { value: 0, message: "Credits cannot be less than 0" },
                })}
              />
              {!errors.credits && (
                <p className={s.helperText}>1 credit equivalent $0.15</p>
              )}
              {errors.credits && (
                <span className={clsx(s.errorMessage)}>
                  {errors.credits.message || "Credits is required"}
                </span>
              )}
            </div>
            <div className={s.inputWrapper}>
              <h5 className={s.creditQuantity}>Promo code</h5>

              <input
                type="text"
                className={clsx(s.inputCredit, {
                  [s.inputError]: errors.promoCode,
                })}
                {...register("promoCode", {
                  required: false,
                })}
                onChange={(e) => setValue(e.target.value)}
              />
              {errors.promoCode && (
                <span className={clsx(s.errorMessage)}>
                  {errors.promoCode.message || "Promo code is invalid"}
                </span>
              )}
              {isLoading ? (
                <Oval
                  visible={isLoading}
                  height="16"
                  width="16"
                  color="#d52b1e"
                  ariaLabel="oval-loading"
                />
              ) : (
                !errors.promoCode && (
                  <p className={s.helperText}>
                    {error
                      ? parseClientError(error).message ||
                        "promo code not found"
                      : PromoCode?.maxCredit &&
                        `credit of ${
                          result && result.replace("+", "")
                        } will be added`}
                  </p>
                )
              )}
            </div>
          </form>
          <Alert className={s.Alert} message={<Info />} type="info" />
        </section>
        <Divider type="vertical" className={s.divider} />
        <section className={s.sectionTwo}>
          <>
            <h2 className={s.tableTitle}>Purchase summary</h2>
            <table className={s.table}>
              <tbody>
                <tr>
                  <td className={s.credits}>
                    {credits || 0}
                    {result || ""} credits
                  </td>
                  <td className={s.leftAlign}>
                    <span className={s.priceDetails}>
                      <h5 className={s.price}>
                        $
                        {(creditAmount * 0.15).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </h5>
                      <p className={s.quantity}>
                        {`$0.15 X ${credits || 0} credits`}
                      </p>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Divider
                      variant="dotted"
                      style={{ borderColor: "#7cb305" }}
                    />
                  </td>
                  <td>
                    <Divider
                      variant="dotted"
                      style={{ borderColor: "#7cb305" }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={s.price}>Subtotal</td>
                  <td className={s.leftAlign}>
                    <span className={s.priceDetails}>
                      <h5 className={s.price}>
                        $
                        {(creditAmount * 0.15).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </h5>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <Button
              loading={isMutating}
              className={s.button}
              onClick={handleSubmit(onSubmit)}
              disabled={isMutating}
            >
              Proceed to checkout
            </Button>
          </>
        </section>
      </section>
    </div>
  );
}

export default BuyCredit;
