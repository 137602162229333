import React, { useEffect, useRef, useState } from "react";
import { Input } from "antd";
import type { GetProps } from "antd";
import Search from "../../Icons/Search";
import "./SearchInput.scss";

type SearchProps = GetProps<typeof Input> & {
  handleSearch: (value: string) => void; // Specify the type for the search value
  width?: number;
  height?: number;
  placeholder?: string
};

function SearchInput({ handleSearch,placeholder }: SearchProps) {
  const [, /*value*/ setValue] = useState("");
  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  const handleChange = (value: string) => {
    const newValue = value;
    setValue(newValue);
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      handleSearch(newValue);
    }, 300);
  };

  useEffect(() => {
    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
  }, []);
  return (
    <Input
    placeholder={placeholder}
      prefix={<Search height={15} width={15} fill="#80828A" />}
      className={"input"}
      style={{
        width: `100%`,
        height: `100px`,
        borderRadius: "8px",
        display: "flex",
        alignItems: "center", // Ensures text and icon are centered
      }}
      onChange={(e) => handleSearch(e.target.value)} // Call handleSearch on change
      onPressEnter={(e) => handleChange(e.currentTarget.value)} // Call handleSearch on Enter key press
    />
  );
}

export default SearchInput;
