import React from "react";
import { IconsType } from "../../libs/Utils/types";
function ArrowDown({ width, height, fill }: IconsType) {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 9 5"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.19927 0.112549H4.30548H0.799232C0.199232 0.112549 -0.100768 0.837548 0.324232 1.26255L3.56174 4.50005C4.08049 5.01876 4.92424 5.01876 5.44299 4.50005L6.67424 3.2688L8.68047 1.26255C9.09927 0.837548 8.79927 0.112549 8.19927 0.112549Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}

export default ArrowDown;
