import React from "react";
import { IconsType } from "../../../libs/Utils/types";
import clsx from "clsx";
import s from "./AddContacts.module.scss"
function AddContacts({ fill, height, width }: IconsType) {
  return (
    <div className={clsx(!width && s.wrapper)}>
      <svg
        viewBox="0 0 27 27"
        style={{
          width: width || "100%",
          height: height || "100%",
        }}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.66471 12.6321C11.9784 12.6321 14.6647 9.80439 14.6647 6.31628C14.6647 2.82816 11.9784 0.000488281 8.66471 0.000488281C5.35101 0.000488281 2.66471 2.82816 2.66471 6.31628C2.66471 9.80439 5.35101 12.6321 8.66471 12.6321Z"
          fill={fill}
        />
        <path
          d="M10.6647 14.0356H6.66471C4.89725 14.0378 3.20279 14.7779 1.95301 16.0934C0.703223 17.409 0.000164019 19.1926 -0.00195312 21.0531V25.2636C-0.00195312 25.6359 0.138523 25.9929 0.388571 26.2561C0.63862 26.5193 0.977758 26.6672 1.33138 26.6672H15.998C16.3517 26.6672 16.6908 26.5193 16.9409 26.2561C17.1909 25.9929 17.3314 25.6359 17.3314 25.2636V21.0531C17.3293 19.1926 16.6262 17.409 15.3764 16.0934C14.1266 14.7779 12.4322 14.0378 10.6647 14.0356Z"
          fill={fill}
        />
        <path
          d="M25.3314 9.82505H22.6647V7.01803C22.6647 6.6458 22.5242 6.28881 22.2742 6.0256C22.0241 5.76239 21.685 5.61452 21.3314 5.61452C20.9778 5.61452 20.6386 5.76239 20.3886 6.0256C20.1385 6.28881 19.998 6.6458 19.998 7.01803V9.82505H17.3314C16.9778 9.82505 16.6386 9.97292 16.3886 10.2361C16.1385 10.4993 15.998 10.8563 15.998 11.2286C15.998 11.6008 16.1385 11.9578 16.3886 12.221C16.6386 12.4842 16.9778 12.6321 17.3314 12.6321H19.998V15.4391C19.998 15.8113 20.1385 16.1683 20.3886 16.4315C20.6386 16.6947 20.9778 16.8426 21.3314 16.8426C21.685 16.8426 22.0241 16.6947 22.2742 16.4315C22.5242 16.1683 22.6647 15.8113 22.6647 15.4391V12.6321H25.3314C25.685 12.6321 26.0241 12.4842 26.2742 12.221C26.5242 11.9578 26.6647 11.6008 26.6647 11.2286C26.6647 10.8563 26.5242 10.4993 26.2742 10.2361C26.0241 9.97292 25.685 9.82505 25.3314 9.82505Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}

export default AddContacts;
