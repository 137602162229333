import React, { useCallback, useEffect, useRef, useState } from "react";
import RichTextEditor from "../../../../global components/React Quill Text Editor/QuilEditor";
import s from "./SMS.module.scss";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  DatePicker,
  Input,
  Spin,
  TimePicker,
  TimePickerProps,
} from "antd";
import SelectField from "../../../../global components/Forms/SelectField/SelectField";
import {
  PaginatedContactGroups,
  singleContactGroup,
} from "../../../../libs/Utils/Types/ContactGroup";
import { UseAuthToken } from "../../../../libs/api/userSession";
import useSWR from "swr";
import {
  parseClientError,
  swrFetcher,
  swrMutationFetcher,
  SwrMutationFetcherArgType,
} from "../../../../libs/api/Endpoints";
import { useNavigate } from "react-router-dom";
import { generateResult } from "../../../../global components/ResultModal/ResultModal";
import ReusableDrawer from "../../../../global components/Drawer/ReuseableDrawer";
import ReusablePopover from "../../../../global components/Popover/ReuseablePopover";
import NewPopOverItems from "../TimePopOver/PopOverItems";
import ArrowDown from "../../../../global components/Icons/ArrowDown";
import dayjs, { Dayjs } from "dayjs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useQuery } from "../../../../libs/common";
import { MessageDetailsType } from "../../../../libs/Utils/Types/Message";
import useSWRMutation from "swr/mutation";
import { useDraft } from "../../../../global components/Context/DraftContext";
import ReusableModal from "../../../../global components/Modal/Modal";
import ConfirmationModal from "../../../../global components/CofirmationModal/ConfirmationModal";
import { Oval } from "react-loader-spinner";
import { SuccessDataType } from "../../../../libs/api/types";

type Inputs = {
  msgTitle: string;
  emailSubject: string;
  recipient: string;
  date: string;
  time: string;
  from: string;
};

function SMS() {
  const [content, setContent] = useState<string>("");
  const [openGroup, setOpenGroup] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<string | string[]>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openMobile, setOpenMobile] = useState<boolean>(false);

  const [msgID, setmsgId] = useState<string>("");
  const [recipient, setRecipients] = useState<string>("");

  const token = UseAuthToken();
  const query = useQuery();
  const msgId = query.get("msgId");
  // Fetch contact groups
  const { data: groups } = useSWR<PaginatedContactGroups, any, any>(
    token ? [`api/contact-groups`, token] : null,
    swrFetcher,
    {
      shouldRetryOnError: false,
    }
  );
  const { data: Recipient, isLoading: loading } = useSWR<
    singleContactGroup,
    any,
    any
  >(
    token && recipient ? [`api/contact-groups/${recipient}`, token] : null,
    swrFetcher,
    {
      onpause: () => !recipient,
    }
  );
  const {
    data: MessageDetails,
    isLoading,
    error,
  } = useSWR<MessageDetailsType, any, any>(
    token && msgId ? [`api/messages/${msgId}`, token] : null,
    swrFetcher,
    {
      revalidateOnFocus: true,

      shouldRetryOnError: false,
    }
  );
  const {
    watch,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
    setValue,
  } = useForm<Inputs>({ shouldFocusError: false });

  const navigate = useNavigate();
  const combineDateTime = (
    date: string | Dayjs | null,
    time: string | Dayjs | null
  ) => {
    if (date && time) {
      const parsedDate = dayjs(date); // Convert to Dayjs if it's a string
      const parsedTime = dayjs(time); // Convert to Dayjs if it's a string

      if (parsedDate.isValid() && parsedTime.isValid()) {
        const combined = parsedDate
          .hour(parsedTime.hour())
          .minute(parsedTime.minute())
          .second(0)
          .millisecond(0);

        return combined.toISOString();
      }
    }
    return null; // Handle invalid or null inputs
  };
  // Handler to receive edited content
  const handleEdit = (newContent: string) => {
    setContent(newContent);
  };
  const { cancelDraft, debounceTimeout } = useDraft();
  const hasUpdated = useRef(false); // Tracks if state has been set
  const [images, setImages] = useState<Blob[]>([]);

  function htmlToPlainText(htmlString: string): string {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;

    // Extract image tags
    const images = Array.from(tempElement.querySelectorAll("img")).map(
      (img) => {
        return `<img src="${img.src}" alt="${img.alt || "image"}" title="${
          img.title || "image"
        }" style="max-width: 100%; max-height: 257px; width: 400px; height: auto;" />`;
      }
    );

    // Remove all tags but preserve their text content
    const plainText = tempElement.textContent || tempElement.innerText || "";

    // Append image elements as HTML if any
    if (images.length > 0) {
      return `${plainText}\n${images.join("\n")}`;
    }

    return plainText;
  }

  const { trigger: uploadImage, isMutating: Loading } = useSWRMutation(
    "api/media/upload",
    swrMutationFetcher
  );
  const { trigger: submitMsg, isMutating } = useSWRMutation(
    `api/messages/${msgID ? msgID : ""}?`,

    swrMutationFetcher
  );
  const watchedRecipient = watch(["recipient"]);

  const handleImageUpload = async (blob: Blob) => {
    // You can handle the blob here, for example, upload it to a server

    // Optionally, convert the blob to an object URL if you want to display it

    setImages((prevImages) => [...prevImages, blob]);
  };
  useEffect(() => {
    if (watch("recipient")) {
      setRecipients(watch("recipient"));
    }
  }, [watch, watchedRecipient]);

  const onSubmit = async (data: Inputs) => {
    if (!content) {
      generateResult("error", "You must have a body!");
      return;
    }
    if (Recipient?.contacts.docs.length === 0) {
      generateResult(
        "error",
        "0 contacts found!",
        "Select a group with at least one contact or add contacts to the group you want to sent message to"
      );
      return;
    }
    if (images.length > 5) {
      generateResult("error", "You must have at most one image!");
      let newString = content;
      newString = newString.replace(
        /<img[^>]*src=["']data:image\/[a-zA-Z]+;base64,[^"']+["'][^>]*>/,
        ""
      );
      setContent(newString);
      setImages((prev) => {
        const newArray = [...prev];
        newArray.pop();
        return newArray;
      });
      return;
    }

    try {
      if (watch("date") && watch("time")) {
        const combinedDateTime = combineDateTime(watch("date"), watch("time"));

        // Navigate to the review page with the form data and formatted date/time
        if (images.length > 0) {
          let newString = content;

          // Process each image in the images array
          const imageArray: { publicId: any; secureUrl: any }[] | undefined =
            [];
          for (const image of images) {
            const img = await handleEmail(image);
            imageArray.push(img!);
            // Replace each <img> tag with the processed image URL
            newString = newString.replace(
              /<img[^>]*src=["']data:image\/[a-zA-Z]+;base64,[^"']+["'][^>]*>/,
              `<img src="${img?.secureUrl}" alt="image" title="img" style="max-width: 100%; max-height: 257px; width: 400px; height: auto;" />`
            );
          }

          let tempDiv = document.createElement("div");
          tempDiv.innerHTML = content; // Parse the HTML string into elements

          // Update any remaining content as necessary
          newString = newString.replace(/https:\/\//g, "http://");
          navigate("review", {
            state: {
              ...data,
              type: tempDiv.querySelector("img") ? "mms" : "sms",
              content: newString,
              schedule: combinedDateTime,
              msgId: msgID || msgId,
              medias: imageArray,
              reset: reset(),
            },
          });
          //reset();
          generateResult(
            "success",
            "Review your sms",
            "Review your message before sending or check your drafts to edit the message"
          );
        } else {
          let newString = htmlToPlainText(content);
          let tempDiv = document.createElement("div");
          tempDiv.innerHTML = content; // Parse the HTML string into elements

          navigate("review", {
            state: {
              ...data,
              type: tempDiv.querySelector("img") ? "mms" : "sms",
              content: newString,
              schedule: combinedDateTime,
              msgId: msgID || msgId,
              reset: reset(),
            },
          });
          //reset();
          generateResult(
            "success",
            "Review your sms",
            "Review your message before sending or check your drafts to edit the message"
          );
        }
      } else {
        if (images.length > 0) {
          let newString = content;
          const imageArray: { publicId: any; secureUrl: any }[] | undefined =
            [];
          // Process each image in the images array
          for (const image of images) {
            const img = await handleEmail(image);
            imageArray.push(img!);

            // Replace each <img> tag with the processed image URL
            newString = newString.replace(
              /<img[^>]*src=["']data:image\/[a-zA-Z]+;base64,[^"']+["'][^>]*>/,
              `<img src="${img?.secureUrl}" alt="image" title="img" style="max-width: 100%; max-height: 257px; width: 400px; height: auto;" />`
            );
          }

          // Update any remaining content as necessary

          // Update any remaining content as necessary
          newString = newString.replace(/https:\/\//g, "http://");
          navigate("review", {
            state: {
              ...data,
              type: "mms",
              content: newString,
              msgId: msgID || msgId,
              medias: imageArray,
              reset: reset(),
            },
          });
          generateResult(
            "success",
            "Review your sms",
            "Review your message before sending or check your drafts to edit the message"
          );
        } else {
          let newString = htmlToPlainText(content);
          console.log(newString);
          navigate("review", {
            state: {
              ...data,
              type: "sms",
              content: newString,
              msgId: msgID || msgId,
              reset: reset(),
            },
          });

          generateResult(
            "success",
            "Review your sms",
            "Review your message before sending or check your drafts to edit the message"
          );
        }
      }
    } catch (error) {
      const e = parseClientError(error);
      generateResult("error", e.message || "An unexpected error occurred.");
    }
  };

  function handleNewGroup() {
    setOpenGroup((prev) => !prev);
  }

  const onChange: TimePickerProps["onChange"] = (time, timeString) => {
    setSchedule(timeString);
  };
  const handleTime = (time: string) => {
    setSchedule((prev) => prev + time);
  };
  function handleModal() {
    setOpenModal((prev) => !prev);
  }
  useEffect(() => {
    if (msgId) {
      setContent(MessageDetails?.body || ""); // Initialize with message body
    }
  }, [msgId, MessageDetails]);

  const handleDraft = useCallback(async () => {
    // Clear the previous timeout if it exists
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout
    debounceTimeout.current = setTimeout(async () => {
      try {
        const formData = new FormData();
        if (images.length > 5) {
          generateResult("error", "You must have at most five image!");
          let newString = content;
          newString = newString.replace(
            /<img[^>]*src=["']data:image\/[a-zA-Z]+;base64,[^"']+["'][^>]*>/,
            ""
          );
          setContent(newString);
          setImages((prev) => {
            const newArray = [...prev];
            newArray.pop();
            return newArray;
          });
          return;
        }
        if (watch("date") && watch("time")) {
          const combinedDateTime = combineDateTime(
            watch("date"),
            watch("time")
          );
          if (combinedDateTime)
            formData.append("scheduledFor", combinedDateTime);
        }
        formData.append("title", watch("msgTitle"));
        formData.append("recipient", watch("recipient"));
        //formData.append("body", htmlToPlainText(content)); // Convert HTML to plain text
        formData.append("isDraft", "true");

        const contactGroupIds = [];
        contactGroupIds.push(watch("recipient"));
        contactGroupIds.forEach((id) => {
          formData.append("contactGroupIds[]", id);
        });

        const fetcherArg: SwrMutationFetcherArgType = {
          token,
          requestOption: {
            method: msgID ? "PATCH" : "POST",

            body: formData,
          },
        };
        let newString: string = "";

        if (images.length > 0) {
          let newString = content;
          const imageArray: { publicId: any; secureUrl: any }[] = [];
          // Process each image in the images array
          for (const image of images) {
            const img = await handleEmail(image);
            imageArray.push(img!);

            // Replace each <img> tag with the processed image URL
            newString = newString.replace(
              /<img[^>]*src=["']data:image\/[a-zA-Z]+;base64,[^"']+["'][^>]*>/,
              `<img src="${img?.secureUrl}" alt="image" title="img" style="max-width: 100%; max-height: 257px; width: 400px; height: auto;" />`
            );
          }

          // Update any remaining content as necessary
          newString = newString.replace(/https:\/\//g, "http://");
          formData.append("body", htmlToPlainText(newString));
          let tempDiv = document.createElement("div");
          tempDiv.innerHTML = content; // Parse the HTML string into elements

          if (tempDiv.querySelector("img")) {
            formData.append("type", "mms");
          } else {
            formData.append("type", "sms");
          }
          if (imageArray && imageArray.length > 0) {
            formData.append("medias", JSON.stringify(imageArray));
          }
          //formData.append("isDraft", "true");
        } else {
          newString = content;
          let tempDiv = document.createElement("div");
          tempDiv.innerHTML = content; // Parse the HTML string into elements

          if (tempDiv.querySelector("img")) {
            formData.append("type", "mms");
          } else {
            formData.append("type", "sms");
          }
          formData.append("body", htmlToPlainText(newString));

          //formData.append("isDraft", "true");
        }
        if (watch("msgTitle") && watch("recipient")) {
          type response = {
            id: string;
          };
          const result: SuccessDataType<response> = await submitMsg(fetcherArg);
          hasUpdated.current = true; // Set the flag to prevent further updates
          if (!msgId) {
            if (!msgID || !hasUpdated.current) setmsgId(result.data.id);
          }
        }
      } catch (error) {
        console.error("Failed to submit draft:", error);
      }
    }, 5000); // 30 seconds delay

    // Cleanup function to clear timeout
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [
    debounceTimeout,
    watch,
    schedule,
    token,
    msgID,
    images,
    content,
    handleEmail,
    submitMsg,
  ]);
  const handleConfirmationModal = () => {
    setOpen((prev) => !prev);
  };
  const handleMobileConfirmationModal = () => {
    setOpenMobile((prev) => !prev);
  };
  const watchedFields = watch(["msgTitle", "recipient"]);
  async function handleEmail(blob: Blob) {
    type Response = {
      secure_url: string;
      public_id: string;
    };
    if (blob) {
      const formData = new FormData();
      formData.append("media", blob);
      formData.append("type", "Message");
      const response: SuccessDataType<Response> = await uploadImage({
        token,
        requestOption: {
          method: "POST",
          body: formData,
        },
      });
      return {
        secureUrl: response.data.secure_url,
        publicId: response.data.public_id,
      };
    }
  }
  // Effect to call handleDraft whenever content changes
  useEffect(() => {
    handleDraft();
  }, [handleDraft, watchedFields, content]);
  useEffect(() => {
    if (MessageDetails) {
      setValue("msgTitle", MessageDetails.title);
      // setValue("date", dayjs(MessageDetails.scheduledFor).toISOString());

      // setValue("time", dayjs(MessageDetails.scheduledFor).toISOString());
      setValue("from", MessageDetails.senderName);
      setContent(MessageDetails.body || "");
    }
  }, [MessageDetails, setValue]);
  function handleReset() {
    setValue("date", "");
    setValue("time", "");
  }
  useEffect(() => {
    if (msgId) {
      setmsgId(msgId);
    }
  }, [msgId]);
  return (
    <>
      <ConfirmationModal
        handleCancel={handleModal}
        open={openMobile}
        reset={handleReset}
        handleConfirmationModal={handleMobileConfirmationModal}
      />
      <ConfirmationModal
        handleCancel={handleNewGroup}
        open={open}
        reset={handleReset}
        handleConfirmationModal={handleConfirmationModal}
      />
      <ReusableModal
        title="Schedule message"
        handleModal={handleModal}
        isModalOpen={openModal}
        centered
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={s.form}>
            <div className={s.scheduleWrapper}>
              <div className={clsx(s.inputWrapper)}>
                <h5 className={clsx(s.inputLabel)}>Select date</h5>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      onChange={(date, dateString) => {
                        if (date) {
                          const isValidDate = dayjs(date.toString()).isValid();
                          if (isValidDate) {
                            field.onChange(date); // Set the value if valid
                          } else {
                            field.onChange(null); // Clear the field if invalid

                          }
                        }
                      }}
                      value={watch("date") ? dayjs(watch("date")) : null} // Ensure value is a Day.js object

                      className={clsx(s.input, {
                        [s.inputError]: errors.date,
                      })}
                      placeholder="MM/YYYY"
                    />
                  )}
                />
              </div>
              <div className={clsx(s.inputWrapper)}>
                <h5 className={clsx(s.inputLabel)}>Time</h5>
                <Controller
                  name="time"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      className={clsx(s.input)}
                      defaultValue={
                        msgId ? dayjs(MessageDetails?.scheduledFor) : null
                      } // Use dayjs to convert the string to a Dayjs object
                      onChange={(time, timeString) => {
                        if (time) {
                          field.onChange(time); // Set the value
                        }
                      }}
                      changeOnScroll
                      format="HH:mm"
                      needConfirm={true}
                      suffixIcon={
                        <ReusablePopover
                          placement="left"
                          trigger="click"
                          content={<NewPopOverItems handleTime={handleTime} />}
                        >
                          <ArrowDown height={16} width={16} fill="#6A6767" />
                        </ReusablePopover>
                      }
                    />
                  )}
                />
              </div>
            </div>
            <div className={s.addContactBtns}>
              <Button
                type="default"
                className={clsx(s.button, s.cancelBtn)}
                onClick={handleMobileConfirmationModal}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className={clsx(s.button, s.addContactBtn)}
                onClick={() => {
                  generateResult("success", "Schedule set successfully");
                  handleModal();
                }}
              >
                Schedule
              </Button>
            </div>
          </div>
        </form>
      </ReusableModal>
      <ReusableDrawer
        title="Schedule message"
        Open={openGroup}
        handleOpen={handleNewGroup}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={s.form}>
            <div className={s.scheduleWrapper}>
              <div className={clsx(s.inputWrapper)}>
                <h5 className={clsx(s.inputLabel)}>Select date</h5>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      onChange={(date, dateString) => {
                        if (date) {
                          const isValidDate = dayjs(date.toString()).isValid();
                          if (isValidDate) {
                            field.onChange(date); // Set the value if valid
                          } else {
                            field.onChange(null); // Clear the field if invalid

                          }
                        }
                      }}
                      value={watch("date") ? dayjs(watch("date")) : null} // Ensure value is a Day.js object

                      className={clsx(s.input, {
                        [s.inputError]: errors.date,
                      })}
                      placeholder="MM/YYYY"
                    />
                  )}
                />
              </div>
              <div className={clsx(s.inputWrapper)}>
                <h5 className={clsx(s.inputLabel)}>Time</h5>
                <Controller
                  name="time"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      className={clsx(s.input)}
                      defaultValue={
                        msgId ? dayjs(MessageDetails?.scheduledFor) : null
                      } // Use dayjs to convert the string to a Dayjs object
                      onChange={(time, timeString) => {
                        if (time) {
                          field.onChange(time); // Set the value
                        }
                      }}
                      changeOnScroll
                      format="HH:mm"
                      needConfirm={true}
                      suffixIcon={
                        <ReusablePopover
                          placement="left"
                          trigger="click"
                          content={<NewPopOverItems handleTime={handleTime} />}
                        >
                          <ArrowDown height={16} width={16} fill="#6A6767" />
                        </ReusablePopover>
                      }
                    />
                  )}
                />
              </div>
            </div>
            <div className={s.addContactBtns}>
              <Button
                type="default"
                className={clsx(s.button, s.cancelBtn)}
                onClick={handleConfirmationModal}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className={clsx(s.button, s.addContactBtn)}
                onClick={() => {
                  generateResult("success", "Schedule set successfully");
                  handleNewGroup();
                }}
              >
                Schedule
              </Button>
            </div>
          </div>
        </form>
      </ReusableDrawer>
      {isLoading || error ? (
        <div className={s.spin}>
          <Spin />
        </div>
      ) : (
        <div className={s.wrapper}>
          <div className={s.emailTitles}>
            <div className={s.msgTitleWrapper}>
              <h1>{watch("msgTitle") || "Untitled"}</h1>
            </div>
            <div className={s.inputWrapper}>
              <h5 className={s.label}>Message title</h5>
              <Controller
                name="msgTitle"
                control={control}
                rules={{ required: "Message title is required" }}
                defaultValue={
                  msgId && MessageDetails ? MessageDetails.title : ""
                }
                render={({ field }) => (
                  <Input
                    {...field}
                    count={{
                      show: true,
                      max: 80,
                    }}
                    className={clsx(s.input, {
                      [s.inputError]: errors.msgTitle,
                    })}
                    maxLength={80}
                  />
                )}
              />
              {!errors.msgTitle && (
                <span className={s.helperText}>
                  <p className={s.titleMsg}>
                    This is just an internal title to help organize and locate
                    your message easily. This does not appear to your audience.
                  </p>
                </span>
              )}
              {errors.msgTitle && (
                <span className={clsx(s.errorMessage)}>
                  {errors.msgTitle.message}
                </span>
              )}
            </div>
            <div className={s.inputWrapper}>
              <h5 className={s.label}>Recipient</h5>
              <Controller
                name="recipient"
                control={control}
                rules={{ required: "Recipient is required" }}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    bordered
                    placeholder="Select contact group that will get your message"
                    wrapperClassname={s.SelectWrapper}
                    className={clsx(s.selectInput, {
                      [s.inputError]: errors.recipient,
                    })}
                    options={groups?.docs.map((group) => ({
                      label: group.name,
                      value: group._id,
                    }))}
                    style={{ width: "100%", height: "100%" }}
                    onChange={field.onChange}
                  />
                )}
              />

              {loading ? (
                <Oval
                  visible={loading}
                  height="16"
                  width="16"
                  color="#d52b1e"
                  ariaLabel="oval-loading"
                />
              ) : (
                !errors.recipient &&
                Recipient && (
                  <p className={s.helperText}>
                    {Recipient?.contacts.docs.length} Contacts
                  </p>
                )
              )}
              {errors.recipient && !Recipient?.contacts && (
                <span className={clsx(s.errorMessage)}>
                  {errors.recipient.message}
                </span>
              )}
            </div>
          </div>

          <div className={s.textEditor}>
            <div className={s.editorBtnGroup}>
              <button
                type="button"
                className={clsx(s.scheduleBtn)}
                onClick={handleNewGroup}
                disabled={isSubmitting}
              >
                Schedule
              </button>
              {/* Button to show scheduling mobile modal */}
              <button
                type="button"
                className={clsx(s.mobileScheduleBtn)}
                onClick={handleModal}
                disabled={isSubmitting}
              >
                Schedule
              </button>
              <button
                type="button"
                className={clsx(s.submitBtn)}
                onClick={handleSubmit(onSubmit)}
                disabled={isSubmitting}
              >
                <Oval
                  visible={isSubmitting}
                  height="16"
                  width="16"
                  color="white"
                  ariaLabel="oval-loading"
                />
                Send now
              </button>
            </div>
            <RichTextEditor
              handleImageUpload={handleImageUpload}
              sms={true}
              value={content}
              handleEdit={handleEdit}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default SMS;
