import { useLocation, Navigate, Outlet } from "react-router-dom";
import { UseAuthToken } from "./libs/api/userSession";
import { User } from "./libs/redux/auth/signinSlice";
import useSWR from "swr";
import { swrFetcher } from "./libs/api/Endpoints";
import { useEffect, useState } from "react";
import Loading from "./global components/Loading";
import NetworkErr from "./global components/NetworkErr/NetworkErr";

function Require() {
  const location = useLocation();
  const token = UseAuthToken();
  const [isLoading, setIsLoading] = useState(true); // Start with loading as true to avoid flash
  
  // Fetch user data with SWR
  const { data, error, isValidating } = useSWR<User, any,any>(
    token ? [`api/users/me`, token] : null,
    swrFetcher,
    {
      revalidateOnFocus:false,
      shouldRetryOnError: false,
      onSuccess: (userData) => {
        // Cache the phone number if it exists
        if (userData.phoneNo) {
          localStorage.setItem("phoneNo", userData.phoneNo);
        } else {
          localStorage.removeItem("phoneNo");
        }
        setIsLoading(false); // Stop loading when data is successfully fetched
      },
    }
  );

  // Retrieve cached phone number if available
  const phoneNo = localStorage.getItem("phoneNo");

  // Control `isLoading` based on `isValidating` and `data`
  useEffect(() => {
    if (!isValidating && data) {
      setIsLoading(false);
    }
  }, [isValidating, data]);

  // Render network error component if there's an error
  if (error) {
    return <NetworkErr />;
  }

  // Display loading spinner if loading
  if (isLoading) {
    return <Loading />;
  }

  // Redirect to phone verification if no phone number is found
  return !phoneNo ? (
    <Navigate to="/auth/verify-phone-number" state={{ from: location }} replace />
  ) : (
    <Outlet />
  );
}

export default Require;
