import React from "react";
import { IconsType } from "../../libs/Utils/types";
type pauseProp= IconsType & {
    onClick:()=>void
}
function Pause({ fill, height, width,onClick }: pauseProp) {
  return (
    <div onClick={onClick}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 32 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.20508 3.2646C0.20508 1.86935 1.21496 0.738281 2.46072 0.738281H6.97199C8.21775 0.738281 9.22763 1.86935 9.22763 3.2646V48.7383C9.22763 50.1335 8.21775 51.2646 6.97199 51.2646H2.46072C1.86248 51.2646 1.28875 50.9984 0.865739 50.5247C0.442725 50.0509 0.205078 49.4083 0.205078 48.7383L0.20508 3.2646ZM22.7615 3.2646C22.7615 1.86935 23.7713 0.738281 25.0171 0.738281H29.5284C30.1266 0.738281 30.7003 1.00445 31.1234 1.47822C31.5464 1.952 31.784 2.59458 31.784 3.2646L31.784 48.7383C31.784 50.1335 30.7741 51.2646 29.5284 51.2646H25.0171C23.7713 51.2646 22.7615 50.1335 22.7615 48.7383V3.2646Z"
          fill={fill || "#D52B1E"}
        />
      </svg>
    </div>
  );
}

export default Pause;
