// AudioRecorder.tsx
import React, { useCallback, useEffect, useRef, useState } from "react";
import Microphone from "../Icons/Microphone/Microphone";
import Pause from "../Icons/Pause";
import s from "./AudioRecorder.module.scss";
import ReactAudioPlayer from "react-audio-player";

interface AudioRecorderProps {
  onAudioSave: (blob: Blob) => void;
  body?: string;
}

const AudioRecorder: React.FC<AudioRecorderProps> = ({ onAudioSave, body }) => {
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [audioURL, setAudioURL] = useState<string | null>(null); // For audio player source
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);

  // Start recording audio
  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);
    mediaRecorderRef.current.start();

    // Collect audio chunks as recording goes
    mediaRecorderRef.current.ondataavailable = (event: BlobEvent) => {
      audioChunksRef.current.push(event.data);
    };

    // When recording stops, create a Blob and pass it to the audio player
    mediaRecorderRef.current.onstop = () => {
      const blob = new Blob(audioChunksRef.current, { type: "audio/wav" });
      setAudioBlob(blob);
      setAudioURL(URL.createObjectURL(blob)); // Set URL for audio player
      audioChunksRef.current = []; // Reset for next recording
      onAudioSave(blob);
    };

    setIsRecording(true); // Set recording state to true
  };

  // Stop recording
  const stopRecording = async () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop(); // This stops the recording

      setIsRecording(false); // Reset recording state
    }
  };

  // Discard the current recording
  const discardRecording = useCallback(() => {
    setAudioBlob(null);
    setAudioURL(null); // Clear the audio URL so the player is reset
  }, []);

  // Effect cleanup to stop any active recording when unmounting
  useEffect(() => {
    return () => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stream
          .getTracks()
          .forEach((track) => track.stop());
      }
    };
  }, []);
  useEffect(() => {
    setAudioBlob(null);
    setAudioURL(null);
  }, [discardRecording]);
  useEffect(() => {
    if (body) setAudioURL(body);
  }, [body]);
  return (
    <div className={s.wrapper}>
      <div className={s.iconWrapper}>
        {!isRecording ? (
          <Microphone
            width={50}
            height={63}
            fill="#D52B1E"
            onClick={startRecording}
          />
        ) : (
          <Pause width={32} height={50} onClick={stopRecording} />
        )}
      </div>
      <div className={s.audioPlayer}>
        {/* Audio player to reflect recorded audio */}
        {body ? (
          <ReactAudioPlayer
            src={body}
            controls
            autoPlay={false} // No need to autoplay since user interaction is needed
          />
        ) : (
          audioURL && (
            <ReactAudioPlayer
              src={audioURL}
              controls
              autoPlay={false} // No need to autoplay since user interaction is needed
            />
          )
        )}

        {/* Discard button shown only if recording has stopped and audioBlob exists */}
        {(audioBlob || body) && (
          <div>
            <button className={s.discardBtn} onClick={discardRecording}>
              Discard
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AudioRecorder;
