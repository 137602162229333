import React from "react";
import { IconsType } from "../../libs/Utils/types";
function InfoIcon({ height, width, fill }: IconsType) {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 0.5C6.51664 0.5 5.06659 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46317C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4967 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.4978 6.01154 14.7069 4.10516 13.3009 2.69911C11.8948 1.29306 9.98846 0.502184 8 0.5ZM7.625 3.5C7.84751 3.5 8.06501 3.56598 8.25002 3.6896C8.43502 3.81321 8.57922 3.98891 8.66437 4.19448C8.74952 4.40005 8.77179 4.62625 8.72839 4.84448C8.68498 5.0627 8.57783 5.26316 8.4205 5.42049C8.26316 5.57783 8.06271 5.68497 7.84448 5.72838C7.62625 5.77179 7.40005 5.74951 7.19448 5.66436C6.98892 5.57921 6.81322 5.43502 6.6896 5.25001C6.56598 5.06501 6.5 4.8475 6.5 4.625C6.5 4.32663 6.61853 4.04048 6.82951 3.8295C7.04049 3.61853 7.32663 3.5 7.625 3.5ZM9.5 11.75H6.5C6.30109 11.75 6.11032 11.671 5.96967 11.5303C5.82902 11.3897 5.75 11.1989 5.75 11C5.75 10.8011 5.82902 10.6103 5.96967 10.4697C6.11032 10.329 6.30109 10.25 6.5 10.25H7.25V8H6.5C6.30109 8 6.11032 7.92098 5.96967 7.78033C5.82902 7.63968 5.75 7.44891 5.75 7.25C5.75 7.05109 5.82902 6.86032 5.96967 6.71967C6.11032 6.57902 6.30109 6.5 6.5 6.5H8C8.19891 6.5 8.38968 6.57902 8.53033 6.71967C8.67098 6.86032 8.75 7.05109 8.75 7.25V10.25H9.5C9.69891 10.25 9.88968 10.329 10.0303 10.4697C10.171 10.6103 10.25 10.8011 10.25 11C10.25 11.1989 10.171 11.3897 10.0303 11.5303C9.88968 11.671 9.69891 11.75 9.5 11.75Z"
          fill="#1A56DB"
        />
      </svg>
    </div>
  );
}

export default InfoIcon;
