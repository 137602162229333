import React from "react";
import s from "./Unauthorised.module.scss";
type prop = {
  error?: { title: string; message: string };
};
function Unauthorised({ error }: prop) {
  return (
    <div className={s.wrapper}>
      <h3 className={s.title}>
        {error?.title ? error.title : "Unauthorized!"}
      </h3>
      <p className={s.subtitle}>
        {error?.message
          ? error.message
          : "You do not have access to this module"}
      </p>
    </div>
  );
}

export default Unauthorised;
