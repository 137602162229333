import React, { useEffect, useRef } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import logo from "../../../../assets/images/Cloudivi red.png";
import clsx from "clsx";
import Home from "../../../../global components/Icons/Home/Home";
import Account from "../../../../global components/Icons/Account/Account";
import Envelope from "../../../../global components/Icons/Envelope/Envelope";
import ContactsGroup from "../../../../global components/Icons/ContactGroup/ContactsGroup";
import s from "./Sidebar.module.scss";
import { logout, User } from "../../../../libs/redux/auth/signinSlice";
import { useAppDispatch } from "../../../../libs/Utils/customHooks";
import Exit from "../../../../global components/Icons/Exit";
import useSWR from "swr";
import { swrFetcher } from "../../../../libs/api/Endpoints";
import { UseAuthToken } from "../../../../libs/api/userSession";
import profile from "../../../../assets/images/defualtPFP.webp";
import ContactAvatar from "../../../../global components/Icons/ContactAvatar/ContactAvatar";
import { useToggle } from "../../../../global components/Context/NavbarContext";
import gsap from "gsap";
import { generateConfirmation } from "../../../../global components/DestructiveModal/DestruvtiveModal";
import { generateResult } from "../../../../global components/ResultModal/ResultModal";
function Sidebar() {
  const dispatch = useAppDispatch();
  const token = UseAuthToken();
  const location = useLocation(); // Get current location
  const currentPath = location.pathname; // Current path for comparison
  const navigate = useNavigate();
  const sidebarRef = useRef<HTMLDivElement>(null);

  const { isTrue } = useToggle();

  // Retrieve cached user data from localStorage
  const cachedUser = localStorage.getItem("user");

  // Use SWR to fetch the user data if not in cache
  const { data, error } = useSWR<User, any>(
    token ? [`api/users/me`, token] : null, // Key, only fetch if token exists
    swrFetcher,
    {
      revalidateOnFocus: true, // Disable refetching when the window regains focus
      shouldRetryOnError: false, // Disable retrying on errors
      onSuccess: (userData) => {
        // Cache the user data on successful fetch
        localStorage.setItem("user", JSON.stringify(userData));
      },
    }
  );

  // Use cached data if available
  const user = data || (cachedUser && JSON.parse(cachedUser));
  async function handleLogout() {
    dispatch(logout());
    generateResult("success", "Logout sucessful");
    navigate("/auth");
  }
  useEffect(() => {
    const sidebar = sidebarRef.current;

    if (sidebar) {
      const screenWidth = window.innerWidth; // Get the current screen width

      if (screenWidth <= 800) {
        // Check if the screen width is 800px or less
        if (!isTrue) {
          gsap.set(sidebar, { y: 100 }); // Hide the sidebar above the view
          gsap.to(sidebar, {
            duration: 0.5,
            y: 0, // Bring it back into view
            ease: "back.in",
            delay: 0.5,
          });
        } else {
          gsap.to(sidebar, {
            duration: 1,
            y: 100, // Move it above the view
            ease: "back.out",
            delay: 0.5,
          });
        }
      }
    }
  }, [isTrue]);

  return (
    <>
      <div className={clsx(s.wrapper)}>
        <img
          onClick={() => navigate("/")}
          src={logo}
          className={s.logo}
          alt="cloudivo logo"
        />
        <div className={clsx(s.mainWrapper)}>
          <div className={s.linkWrapper}>
            <span>
              <NavLink
                to="/dashboard/Home"
                className={clsx(
                  (currentPath.includes("/dashboard/Home") ||
                    currentPath === "/dashboard") &&
                    s.active,
                  s.link
                )}
              >
                <Home
                  fill={
                    currentPath.includes("/dashboard/Home") ||
                    currentPath === "/dashboard"
                      ? "#240806"
                      : "#6A6767"
                  }
                />
                Home
              </NavLink>
            </span>
            <span>
              <NavLink
                to="/dashboard/Contacts"
                className={clsx(
                  currentPath.includes("/dashboard/Contacts") && s.active,
                  s.link
                )}
              >
                <ContactsGroup
                  fill={
                    currentPath.includes("/dashboard/Contacts")
                      ? "#240806"
                      : "#6A6767"
                  }
                />
                Contacts
              </NavLink>
            </span>
            <span>
              <NavLink
                to="/dashboard/Message"
                className={clsx(
                  currentPath.includes("/dashboard/Message") && s.active,
                  s.link
                )}
              >
                <Envelope
                  fill={
                    currentPath.includes("/dashboard/Message")
                      ? "#240806"
                      : "#6A6767"
                  }
                />
                Messages
              </NavLink>
            </span>
            <span>
              <NavLink
                to="/dashboard/Account"
                className={clsx(
                  currentPath.includes("/dashboard/Account") && s.active,
                  s.link
                )}
              >
                <Account
                  fill={
                    currentPath.includes("/dashboard/Account")
                      ? "#240806"
                      : "#6A6767"
                  }
                />
                Account
              </NavLink>
            </span>
          </div>

          <span
            className={clsx(s.authentication)}
            onClick={() =>
              generateConfirmation(
                "Log out?",
                "Are you sure you want to log out?",
                handleLogout,
                true,
                "log out"
              )
            }
          >
            <span className={s.profile}>
              {user?.avatar?.publicId ? (
                <img
                  src={user?.avatar?.publicId}
                  className={s.profilePics}
                  alt="Profile"
                />
              ) : (
                <ContactAvatar width={24} height={24} /> // Fallback to default profile image if no avatar
              )}
              <h5 className={s.name}>
                {user?.firstName + " " + user?.lastName}
              </h5>
            </span>
            <Exit height={16} width={16} fill="#BEC2CA" />
          </span>
        </div>
      </div>
      <div ref={sidebarRef} className={clsx(s.wrapperMobile)}>
        <img
          onClick={() => navigate("/")}
          src={logo}
          className={s.logo}
          width={"108px"}
          height={"33px"}
          alt="cloudivo logo"
        />
        <div className={clsx(s.mainWrapper)}>
          <div className={s.linkWrapper}>
            <span>
              <NavLink
                to="/dashboard/Home"
                className={clsx(
                  (currentPath.includes("/dashboard/Home") ||
                    currentPath === "/dashboard") &&
                    s.active,
                  s.link
                )}
              >
                <Home
                  width={13.33}
                  height={13.33}
                  fill={
                    currentPath.includes("/dashboard/Home") ||
                    currentPath === "/dashboard"
                      ? "#240806"
                      : "#6A6767"
                  }
                />
                Home
              </NavLink>
            </span>
            <span>
              <NavLink
                to="/dashboard/Contacts"
                className={clsx(
                  currentPath.includes("/dashboard/Contacts") && s.active,
                  s.link
                )}
              >
                <ContactsGroup
                  height={13.33}
                  width={13.33}
                  fill={
                    currentPath.includes("/dashboard/Contacts")
                      ? "#240806"
                      : "#6A6767"
                  }
                />
                Contacts
              </NavLink>
            </span>
            <span>
              <NavLink
                to="/dashboard/Message"
                className={clsx(
                  currentPath.includes("/dashboard/Message") && s.active,
                  s.link
                )}
              >
                <Envelope
                  width={13.33}
                  height={11.33}
                  fill={
                    currentPath.includes("/dashboard/Message")
                      ? "#240806"
                      : "#6A6767"
                  }
                />
                Messages
              </NavLink>
            </span>
            <span>
              <NavLink
                to="/dashboard/Account"
                className={clsx(
                  currentPath.includes("/dashboard/Account") && s.active,
                  s.link
                )}
              >
                <Account
                  height={13.33}
                  width={13.33}
                  fill={
                    currentPath.includes("/dashboard/Account")
                      ? "#240806"
                      : "#6A6767"
                  }
                />
                Account
              </NavLink>
            </span>
            <div
              className={clsx(s.link)}
              onClick={() =>
                generateConfirmation(
                  "Log out?",
                  "Are you sure you want to log out",
                  handleLogout,
                  true,
                  "log out?"
                )
              }
            >
              <Exit height={13} width={13} />
              Logout
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
