import React, { useCallback, useEffect, useState } from "react";
import s from "./FAQ.module.scss";
import SearchInput from "../../../global components/Forms/SearchInput/SearchInput";
import clsx from "clsx";
import send from "../../../assets/images/send.webp";
import { Collapse, CollapseProps } from "antd";
import { PlusOutlined } from "@ant-design/icons";

function FAQ() {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [items, setItems] = useState<CollapseProps["items"]>();

  const handleNavigationClick = (index: number) => {
    setActiveIndex(index);
    switch (index) {
      case 0:
        setItems(itemsAll);
        break;
      case 1:
        setItems(itemsOne);
        break;
      case 2:
        setItems(itemsTwo);
        break;
      case 3:
        setItems(itemsThree);
        break;
      default:
        setItems(itemsAll);
    }
  };

  const itemsAll: CollapseProps["items"] = [
    {
      key: "1",
      label: "Can I send both SMS and email messages from one account?",
      children: (
        <p className={s.answer}>
          Yes, Cloudivi allows you to send both SMS and email campaigns from the
          same account using your available credits.
        </p>
      ),
    },
    {
      key: "2",
      label: "What if I run out credits?",
      children: (
        <p className={s.answer}>
          No problem! You can easily purchase additional credits as needed. Our
          flexible system allows you to top up anytime, ensuring your
          communication stays uninterrupted.
        </p>
      ),
    },
    {
      key: "3",
      label: " What are credit-based payments and how do they work?",
      children: (
        <p className={s.answer}>
          Cloudivi operates on a credit system, allowing you to buy credits in
          advance. You only use credits for the messages you send, ensuring you
          never pay for more than you need.
        </p>
      ),
    },
    {
      key: "4",
      label: "Can I schedule messages to be sent at a later time?",
      children: (
        <p className={s.answer}>
          Absolutely! You can schedule your SMS, MMS, email, and voice messages
          in advance. Simply select your desired date and time, and Cloudivi
          will handle the rest.
        </p>
      ),
    },
    {
      key: "5",
      label: " Is there a limit to how many messages I can send at once?",
      children: (
        <p className={s.answer}>
          No, there’s no limit! Whether you’re sending messages to 10 contacts
          or 10,000, Cloudivi can handle it all seamlessly
        </p>
      ),
    },
    {
      key: "6",
      label: " What file types can I send with MMS?",
      children: (
        <p className={s.answer}>
          You can send images and videos files via MMS to enhance your messaging
          campaigns. Just upload the media file, and it’s ready to go!
        </p>
      ),
    },
    {
      key: "7",
      label: " Can I import my contact list?",
      children: (
        <p className={s.answer}>
          Absolutely! You can easily upload your contacts via a CSV file to
          start messaging right away.
        </p>
      ),
    },
    {
      key: "8",
      label: "What happens if my message fails to deliver?",
      children: (
        <p className={s.answer}>
          If a message fails to deliver, you will receive an error report with
          the reason for the failure. Your credits will not be deducted unless
          the delivery is successful.
        </p>
      ),
    },
    {
      key: "9",
      label: " Can I personalize my messages with customer details?",
      children: (
        <p className={s.answer}>
          Definitely! You can create templates to include customer names, order
          numbers, or other personalized details in your SMS, email, and voice
          messages.
        </p>
      ),
    },
    {
      key: "10",
      label: "How do I add a contact to a group?",
      children: (
        <p className={s.answer}>
          To add a contact to a group, navigate to the "Contacts" section, and
          select the group you want to update. From there, you can manually add
          individual contacts by selecting them. Once added, the contact will be
          part of that specific group.
        </p>
      ),
    },
    {
      key: "11",
      label: "Can I add a contact to multiple groups? ",
      children: (
        <p className={s.answer}>
          Yes, you can! A single contact can belong to multiple groups, allowing
          you to organize your audience more efficiently. Simply navigate to
          each group and add the same contact to all relevant groups. This
          ensures they receive messages tailored to the specific groups they are
          part of.
        </p>
      ),
    },
  ];
  const itemsOne: CollapseProps["items"] = [
    {
      key: "1",
      label: " Can I import my contact list?",
      children: (
        <p className={s.answer}>
          Absolutely! You can easily upload your contacts via a CSV file to
          start messaging right away.
        </p>
      ),
    },

    {
      key: "2",
      label: "How do I add a contact to a group?",
      children: (
        <p className={s.answer}>
          To add a contact to a group, navigate to the "Contacts" section, and
          select the group you want to update. From there, you can manually add
          individual contacts by selecting them. Once added, the contact will be
          part of that specific group.
        </p>
      ),
    },
    {
      key: "3",
      label: "Can I add a contact to multiple groups? ",
      children: (
        <p className={s.answer}>
          Yes, you can! A single contact can belong to multiple groups, allowing
          you to organize your audience more efficiently. Simply navigate to
          each group and add the same contact to all relevant groups. This
          ensures they receive messages tailored to the specific groups they are
          part of.
        </p>
      ),
    },
    {
      key: "4",
      label: " Can I import my contact list?",
      children: (
        <p className={s.answer}>
          Absolutely! You can easily upload your contacts via a CSV file to
          start messaging right away.
        </p>
      ),
    },
    {
      key: "5",
      label: "How do I add a contact to a group?",
      children: (
        <p className={s.answer}>
          To add a contact to a group, navigate to the "Contacts" section, and
          select the group you want to update. From there, you can manually add
          individual contacts by selecting them. Once added, the contact will be
          part of that specific group.
        </p>
      ),
    },
    {
      key: "6",
      label: "Can I add a contact to multiple groups? ",
      children: (
        <p className={s.answer}>
          Yes, you can! A single contact can belong to multiple groups, allowing
          you to organize your audience more efficiently. Simply navigate to
          each group and add the same contact to all relevant groups. This
          ensures they receive messages tailored to the specific groups they are
          part of.
        </p>
      ),
    },
    {
      key: "7",
      label: "How do I add a contact to a group?",
      children: (
        <p className={s.answer}>
          To add a contact to a group, navigate to the "Contacts" section, and
          select the group you want to update. From there, you can manually add
          individual contacts by selecting them. Once added, the contact will be
          part of that specific group.
        </p>
      ),
    },
    {
      key: "8",
      label: "Can I add a contact to multiple groups? ",
      children: (
        <p className={s.answer}>
          Yes, you can! A single contact can belong to multiple groups, allowing
          you to organize your audience more efficiently. Simply navigate to
          each group and add the same contact to all relevant groups. This
          ensures they receive messages tailored to the specific groups they are
          part of.
        </p>
      ),
    },
  ];
  const itemsTwo: CollapseProps["items"] = [
    {
      key: "1",
      label: " Can I personalize my messages with customer details?",
      children: (
        <p className={s.answer}>
          Definitely! You can create templates to include customer names, order
          numbers, or other personalized details in your SMS, email, and voice
          messages.
        </p>
      ),
    },
    {
      key: "2",
      label: "What happens if my message fails to deliver?",
      children: (
        <p className={s.answer}>
          If a message fails to deliver, you will receive an error report with
          the reason for the failure. Your credits will not be deducted unless
          the delivery is successful.
        </p>
      ),
    },
    {
      key: "3",
      label: "What happens if my message fails to deliver?",
      children: (
        <p className={s.answer}>
          If a message fails to deliver, you will receive an error report with
          the reason for the failure. Your credits will not be deducted unless
          the delivery is successful.
        </p>
      ),
    },
    {
      key: "4",
      label: "Can I schedule messages to be sent at a later time?",
      children: (
        <p className={s.answer}>
          Absolutely! You can schedule your SMS, MMS, email, and voice messages
          in advance. Simply select your desired date and time, and Cloudivi
          will handle the rest.
        </p>
      ),
    },
    {
      key: "5",
      label: " Is there a limit to how many messages I can send at once?",
      children: (
        <p className={s.answer}>
          No, there’s no limit! Whether you’re sending messages to 10 contacts
          or 10,000, Cloudivi can handle it all seamlessly
        </p>
      ),
    },
    {
      key: "6",
      label: " What file types can I send with MMS?",
      children: (
        <p className={s.answer}>
          You can send images and videos files via MMS to enhance your messaging
          campaigns. Just upload the media file, and it’s ready to go!
        </p>
      ),
    },
  ];
  const itemsThree: CollapseProps["items"] = [
    {
      key: "1",
      label: "What if I run out credits?",
      children: (
        <p className={s.answer}>
          No problem! You can easily purchase additional credits as needed. Our
          flexible system allows you to top up anytime, ensuring your
          communication stays uninterrupted.
        </p>
      ),
    },
    {
      key: "2",
      label: " What are credit-based payments and how do they work?",
      children: (
        <p className={s.answer}>
          Cloudivi operates on a credit system, allowing you to buy credits in
          advance. You only use credits for the messages you send, ensuring you
          never pay for more than you need.
        </p>
      ),
    },
  ];

  const handleSearch = useCallback(
    (value: string) => {
      const filteredItems = itemsAll?.filter((item) => {
        if (typeof item.label === "string") {
          return item.label.toLowerCase().includes(value.toLowerCase());
        }
        return false; // Skip non-string labels
      });

      setItems(filteredItems);
      setActiveIndex(0)
    },
    [itemsAll]
  );
  useEffect(() => {
    setActiveIndex(0)
    setItems(itemsAll);
  }, []);

  return (
    <div className={s.wrapper}>
      <div className={s.sectionOne}>
        <h2 className={s.title}>Frequently asked questions</h2>

        <div className={s.search}>
          <SearchInput handleSearch={handleSearch} />
        </div>

        <div className={s.navigations}>
          <p>SECTIONS</p>
          <ul>
            <li
              onClick={() => handleNavigationClick(0)}
              className={clsx({ [s.active]: activeIndex === 0 })}
            >
              <img src={send} alt="" />
              All
            </li>
            <li
              onClick={() => handleNavigationClick(1)}
              className={clsx({ [s.active]: activeIndex === 1 })}
            >
              <img src={send} alt="" />
              Contacts
            </li>
            <li
              onClick={() => handleNavigationClick(2)}
              className={clsx({ [s.active]: activeIndex === 2 })}
            >
              <img src={send} alt="" />
              Messages
            </li>
            <li
              onClick={() => handleNavigationClick(3)}
              className={clsx({ [s.active]: activeIndex === 3 })}
            >
              <img src={send} alt="" />
              Credits
            </li>
          </ul>
        </div>
      </div>
      <div className={s.sectionTwo}>
        <div className={s.faq}>
          {items?.length ? (
            <Collapse
              bordered={false}
              accordion
              className={s.frequentlyAskedQuestions}
              expandIcon={({ isActive }) => (
                <PlusOutlined rotate={isActive ? 135 : 0} />
              )}
              expandIconPosition={"end"}
              style={{ background: "transparent" }}
              items={items}
            />
          ) : (
            <p className={s.noResults}>No results found.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default FAQ;
