import React from "react";
import { IconsType } from "../../../libs/Utils/types";
import clsx from "clsx";
import s from "./EyeClose.module.scss"
type EyeOpenProp = IconsType & {
  onClick?: () => void;
};
function EyeClose({ height, width, fill, onClick }: EyeOpenProp) {
  return (
    <div   onClick={onClick} className={clsx(!width && s.wrapper)}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: width || "100%",
          height: height || "100%",
        }}
        preserveAspectRatio="xMidYMid meet"

      >
        <path
          d="M2.33268 11.4892L4.87852 8.94332C4.8552 8.79648 4.8399 8.64849 4.83268 8.49999C4.83401 7.39532 5.27342 6.33629 6.05453 5.55517C6.83565 4.77406 7.89469 4.33464 8.99935 4.33332C9.14783 4.34026 9.29583 4.35529 9.44268 4.37832L10.896 2.92499C10.2775 2.75797 9.64003 2.67114 8.99935 2.66665C4.50935 2.66665 0.666016 7.11332 0.666016 8.49999C0.883353 9.65052 1.4682 10.6994 2.33268 11.4892Z"
          fill={fill}
        />
        <path
          d="M11.2493 7.42832L16.2552 2.42249C16.3348 2.34561 16.3983 2.25366 16.4419 2.15199C16.4856 2.05032 16.5086 1.94097 16.5096 1.83032C16.5105 1.71967 16.4894 1.60994 16.4475 1.50752C16.4056 1.40511 16.3438 1.31207 16.2655 1.23382C16.1873 1.15558 16.0942 1.0937 15.9918 1.0518C15.8894 1.0099 15.7797 0.988816 15.669 0.989778C15.5584 0.990739 15.449 1.01373 15.3473 1.0574C15.2457 1.10108 15.1537 1.16456 15.0768 1.24415L10.071 6.24999C9.91426 6.17466 9.75003 6.116 9.58102 6.07499L9.55185 6.06582C9.14004 5.96903 8.71033 5.979 8.30345 6.0948C7.89657 6.2106 7.52601 6.42838 7.22688 6.72751C6.92774 7.02664 6.70996 7.39721 6.59416 7.80409C6.47836 8.21097 6.46839 8.64067 6.56518 9.05249C6.56518 9.06249 6.57185 9.07082 6.57352 9.07999C6.61454 9.24964 6.67348 9.41446 6.74935 9.57165L1.74352 14.5775C1.66392 14.6544 1.60044 14.7463 1.55676 14.848C1.51309 14.9497 1.4901 15.059 1.48914 15.1697C1.48818 15.2803 1.50926 15.39 1.55116 15.4924C1.59307 15.5949 1.65494 15.6879 1.73319 15.7662C1.81143 15.8444 1.90447 15.9063 2.00689 15.9482C2.1093 15.9901 2.21903 16.0112 2.32968 16.0102C2.44033 16.0092 2.54968 15.9862 2.65135 15.9426C2.75302 15.8989 2.84498 15.8354 2.92185 15.7558L7.92768 10.75C8.08488 10.8259 8.24969 10.8848 8.41935 10.9258C8.42852 10.9258 8.43685 10.9317 8.44685 10.9342C8.85866 11.0309 9.28837 11.021 9.69525 10.9052C10.1021 10.7894 10.4727 10.5716 10.7718 10.2725C11.071 9.97333 11.2887 9.60276 11.4045 9.19589C11.5203 8.78901 11.5303 8.3593 11.4335 7.94749C11.4335 7.93749 11.4268 7.92832 11.4243 7.91832C11.3833 7.74931 11.3247 7.58507 11.2493 7.42832Z"
          fill={fill}
        />
        <path
          d="M15.5168 5.66082L13.136 8.04165C13.2117 8.66323 13.1449 9.29386 12.9407 9.8858C12.7365 10.4777 12.4003 11.0154 11.9576 11.4582C11.5148 11.901 10.9771 12.2372 10.3852 12.4413C9.79323 12.6455 9.16259 12.7123 8.54102 12.6367L7.05352 14.1242C7.693 14.2619 8.3452 14.332 8.99935 14.3333C14.3769 14.3333 17.3327 9.95165 17.3327 8.49999C17.3327 7.52832 15.9685 6.10499 15.5168 5.66082Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}

export default EyeClose;
