import React, { useEffect, useState } from "react";
import s from "./ContactDetails.module.scss";
import clsx from "clsx";
import { capitalize, useQuery } from "../../../../../libs/common";
import useSWR from "swr";
import { UseAuthToken } from "../../../../../libs/api/userSession";
import {
  parseClientError,
  swrFetcher,
  swrMutationFetcher,
  SwrMutationFetcherArgType,
} from "../../../../../libs/api/Endpoints";

import { SingleContactResponse } from "../../../../../libs/Utils/Types/singContact";

import ContactAvatar from "../../../../../global components/Icons/ContactAvatar/ContactAvatar";
import { singleContactGroup } from "../../../../../libs/Utils/Types/ContactGroup";
import { Avatar, Button, Divider, message, Spin } from "antd";
import SearchInput from "../../../../../global components/Forms/SearchInput/SearchInput";
import NoData from "../../../../../global components/Table/NoData/NoData";
import User from "../../../../../global components/Icons/User";
import AddContact from "./AddContacts";
import ReusableModal from "../../../../../global components/Modal/Modal";
import ContactCheckboxList from "../../../../../global components/ContactCheckbox/ContactCheckbox";
import AvatarRemove from "../../../../../global components/Icons/ArrowRemove/AvatarRemove";
import Cancel from "../../../../../global components/Icons/Cancel/Cancel";
import useSWRMutation from "swr/mutation";
import { generateResult } from "../../../../../global components/ResultModal/ResultModal";
import { useToggle } from "../../../../../global components/Context/NavbarContext";
function GroupDetails() {
  const query = useQuery();
  const [open, setOpen] = useState<boolean>(false);
  const groupID = query.get("groupID");
  const token = UseAuthToken();
  const [search, setSearch] = useState<string>("");
  const [contactID, setContactID] = useState<string>();
  const [active, setActive] = useState<string>();
  const [mobileActive, setMobileActive] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [checkedContactsId, setContactsId] = useState<string[]>([]);
  const [, /*allContactsID*/ setAllContactsID] = useState<string[]>();
  const [removeContact, setRemoveContact] = useState<boolean>(false);
  const { isTrue, toggle } = useToggle();

  const { data: selectedContact, isLoading: loadingContact } = useSWR<
    SingleContactResponse,
    any,
    any
  >(
    token ? [`api/contacts/${contactID}`, token] : null,
    swrFetcher,

    {
      revalidateOnFocus: true, // Refetch when the window regains focus
      // Refetch every 10 seconds (10000 ms)
      shouldRetryOnError: false, // Disable retrying on errors
      onpause: () => !contactID,
    }
  );

  const { trigger: removeFromGroup, isMutating } = useSWRMutation(
    `api/contact-groups/${groupID}/remove-contacts/`,
    swrMutationFetcher
  );

  const { data, isLoading, mutate, error } = useSWR<
    singleContactGroup,
    any,
    any
  >(
    token ? [`api/contact-groups/${groupID}`, token, { search: search }] : null,
    swrFetcher,

    {
      revalidateOnFocus: true, // Refetch when the window regains focus
      // Refetch every 10 seconds (10000 ms)
      shouldRetryOnError: false, // Disable retrying on errors
    }
  );

  function handleConfirmationModal() {
    if (!isTrue) {
      toggle();
    }
    setOpen((prev) => !prev);
  }
  function handleSearch(searchValue: string) {
    setSearch(searchValue);
  }
  function handleContacts(id: string) {
    setActive(id);
    setContactID(id);
  }
  function handleMobileContacts(id: string) {
    handleMobileModal();
    if (!isTrue) {
      toggle();
    }
    setContactID(id);
  }
  function handleModal() {
    if (!isTrue) {
      toggle();
    }
    setIsModalOpen((prev) => !prev);
  }
  const handleSelectionChange = (selectedItems: string[]) => {
    setContactsId(selectedItems);
  };
  const removeContacts = async () => {
    const jsonBody = { contactIds: checkedContactsId };
    try {
      const fetcherArg: SwrMutationFetcherArgType = {
        token,
        requestOption: {
          body: JSON.stringify(jsonBody),
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
        },
      };
      await removeFromGroup(fetcherArg);
      generateResult("success", "Contacts removed successfully");
      mutate();
      handleConfirmationModal();
    } catch (error) {
      const e = parseClientError(error);
      generateResult("error", capitalize(e.message) || "");
    }
  };
  function handleCancel() {
    setRemoveContact(false);
    setContactsId([]);
  }
  useEffect(() => {
    if (data?.contacts.docs.length) {
      const firstContactId = data.contacts.docs[0]._id;
      setContactID(firstContactId);
      setActive(firstContactId);
    }
  }, [data?.contacts.docs]);
  function handleMobileModal() {
    if (!isTrue) {
      toggle();
    }
    setMobileActive((prev) => !prev);
  }
  if (error) {
    const e = parseClientError(error);
    message.error(capitalize(e.message) || "Something went wrong");
  }
  return (
    <>
      <ReusableModal
        footer={null}
        subtitle={"Select how you want to add contact to your group"}
        title={"Add Contact"}
        centered
        width={931}
        height={356}
        isModalOpen={isModalOpen}
        handleModal={handleModal}
      >
        <AddContact
          mutate={mutate}
          groupID={groupID}
          handleModal={handleModal}
        />
      </ReusableModal>
      <ReusableModal
        handleModal={handleConfirmationModal}
        isModalOpen={open}
        title={""}
        subtitle=""
        centered
        footer={null}
      >
        <div className={s.modalBody}>
          <div className={s.texts}>
            <h3 className={s.message}>Are you sure you want to remove?</h3>
            <h5 className={s.subMessage}>
              The selected contacts will be removed from the group
            </h5>
          </div>

          <div className={s.modalContactBtns}>
            <Button
              className={clsx(s.button, s.cancelBtn)}
              onClick={handleConfirmationModal}
            >
              No, Cancel
            </Button>
            <Button
              onClick={removeContacts}
              loading={isMutating}
              className={clsx(s.button, s.modalContactBtn)}
            >
              Yes, remove
            </Button>
          </div>
        </div>
      </ReusableModal>
      <ReusableModal
        footer={false}
        title={""}
        isModalOpen={mobileActive}
        handleModal={handleMobileModal}
      >
        <div className={s.modal}>
          <div className={s.aboutContact}>
            {isLoading || error || loadingContact ? (
              <div className={s.spin}>
                <Spin size="large" />
              </div>
            ) : !selectedContact && !loadingContact ? (
              <NoData
                Message="No contact found"
                icon={<User height={120} width={120} />}
                subMessage="Add a new or existing contact, or upload a CSV file to import multiple contacts"
              />
            ) : (
              <section className={s.contactDetails}>
                <div className={s.profileDetails}>
                  {!selectedContact?.contact.firstName ||
                  !selectedContact?.contact.lastName ? (
                    <ContactAvatar width={56} height={56} />
                  ) : (
                    <div className={s.profilePic}>
                      <h1>
                        {data
                          ? selectedContact?.contact.firstName[0].toUpperCase() +
                            selectedContact?.contact.lastName[0].toUpperCase()
                          : "N/A"}
                      </h1>
                    </div>
                  )}
                  <span>
                    <h2>
                      {selectedContact?.contact.firstName &&
                      selectedContact?.contact.lastName
                        ? (selectedContact?.contact.firstName || "N/A") +
                          " " +
                          (selectedContact?.contact.lastName || "N/A")
                        : "N/A"}
                    </h2>
                  </span>
                </div>
                <table className={s.table}>
                  <tbody>
                    <tr>
                      <td className={s.tableLabel}>Email address</td>
                      {selectedContact?.contact && (
                        <td className={clsx(s.tableLabel, s.tableData)}>
                          {selectedContact?.contact.email || "No email found"}
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td className={s.tableLabel}>Phone number</td>
                      <td className={clsx(s.tableLabel, s.tableData)}>
                        {selectedContact?.contact.phoneNo ||
                          "No phone number found"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            )}
          </div>
        </div>
      </ReusableModal>
      <div className={s.wrapper}>
        <div className={s.header}>
          <div className={s.headerText}>
            <h2 className={s.groupName}>{data?.name}</h2>
            {data?.contacts && data?.contacts.docs.length > 0 && (
              <p className={s.number}>{data?.contacts.docs.length} Contacts</p>
            )}
          </div>
          <div className={s.dextopBtn}>
            <button onClick={handleModal} className={s.button}>
              Add contact
            </button>
          </div>
        </div>
        <Divider />
        <div className={s.mainWrapper}>
          <div className={s.contactList}>
            <div className={s.form}>
              <div className={s.input}>
                <SearchInput
                  placeholder="search contact ,or any related keyword"
                  handleSearch={handleSearch}
                />
              </div>

              <div>
                {!removeContact ? (
                  <AvatarRemove onClick={() => setRemoveContact(true)} />
                ) : (
                  <Cancel onClick={() => setRemoveContact(false)} />
                )}
              </div>
            </div>
            {!removeContact ? (
              isLoading || error ? (
                <div className={s.spin}>
                  <Spin size="large" />
                </div>
              ) : data?.contacts?.docs.length ? (
                <>
                  <div className={clsx(s.contactsDesktop, s.contacts)}>
                    {data.contacts.docs.map((contact) => {
                      const hasFullName = contact.firstName && contact.lastName;
                      const contactInfo = hasFullName ? (
                        <>
                          <div
                            onClick={() => handleContacts(contact._id)}
                            className={clsx(s.contact, {
                              [s.active]:
                                contact._id === selectedContact?.contact.id,
                            })}
                            key={contact._id}
                          >
                            <div className={s.profile}>
                              {contact.firstName[0].toUpperCase() +
                                " " +
                                contact.lastName[0].toUpperCase()}
                            </div>
                            <div>
                              <p className={s.profileInfo}>
                                {contact.firstName
                                  ? contact.firstName.charAt(0).toUpperCase() +
                                    contact.firstName.slice(1)
                                  : ""}{" "}
                                {contact.lastName
                                  ? contact.lastName.charAt(0).toUpperCase() +
                                    contact.lastName.slice(1)
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div
                          onClick={() => setContactID(contact._id)}
                          className={s.contact}
                          key={contact._id}
                        >
                          <ContactAvatar width={32} height={32} />
                          <div>
                            <h3 className={s.profileInfo}>
                              {contact.email || contact.phoneNo}
                            </h3>
                          </div>
                        </div>
                      );

                      return contactInfo;
                    })}
                  </div>
                  <div className={clsx(s.contactsMobile, s.contacts)}>
                    {data.contacts.docs.map((contact) => {
                      const hasFullName = contact.firstName && contact.lastName;
                      const contactInfo = hasFullName ? (
                        <>
                          <div
                            onClick={() => handleMobileContacts(contact._id)}
                            className={clsx(s.contact, {
                              [s.active]:
                                contact._id === selectedContact?.contact.id,
                            })}
                            key={contact._id}
                          >
                            <div className={s.profile}>
                              {contact.firstName[0].toUpperCase() +
                                " " +
                                contact.lastName[0].toUpperCase()}
                            </div>
                            <div>
                              <p className={s.profileInfo}>
                                {contact.firstName
                                  ? contact.firstName.charAt(0).toUpperCase() +
                                    contact.firstName.slice(1)
                                  : ""}{" "}
                                {contact.lastName
                                  ? contact.lastName.charAt(0).toUpperCase() +
                                    contact.lastName.slice(1)
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div
                          onClick={() => setContactID(contact._id)}
                          className={s.contact}
                          key={contact._id}
                        >
                          <ContactAvatar width={32} height={32} />
                          <div>
                            <h3 className={s.profileInfo}>
                              {contact.email || contact.phoneNo}
                            </h3>
                          </div>
                        </div>
                      );

                      return contactInfo;
                    })}
                  </div>
                </>
              ) : (
                <p>No contact found!</p>
              )
            ) : (
              data?.contacts && (
                <ContactCheckboxList
                  onSelectionChange={handleSelectionChange}
                  contacts={data.contacts.docs}
                />
              )
            )}

            {checkedContactsId.length > 0 && removeContact && (
              <div className={s.footer}>
                <Divider />
                <div className={s.addContactBtns}>
                  <Button
                    className={clsx(s.button, s.cancelBtn)}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleConfirmationModal}
                    // loading={isMutating}
                    className={clsx(s.button, s.addContactBtn)}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            )}
            {!removeContact && (
              <div className={s.mobileBtn}>
                <button onClick={handleModal} className={s.button}>
                  Add contact
                </button>
              </div>
            )}
          </div>

          <Divider className={s.divider} type="vertical" />

          {checkedContactsId.length > 0 ? (
            <div className={s.aboutContact}>
              <h5 className={s.selected}>
                {checkedContactsId.length} out of {data?.contacts.docs.length}{" "}
                selected
              </h5>
            </div>
          ) : (
            <div className={s.aboutContact}>
              {isLoading || error || loadingContact ? (
                <div className={s.spin}>
                  <Spin size="large" />
                </div>
              ) : !selectedContact && !loadingContact ? (
                <NoData
                  Message="No contact found"
                  icon={<User height={120} width={120} />}
                  subMessage="Add a new or existing contact, or upload a CSV file to import multiple contacts"
                />
              ) : (
                <section className={s.contactDetails}>
                  <div className={s.profileDetails}>
                    {!selectedContact?.contact.firstName ||
                    !selectedContact?.contact.lastName ? (
                      <ContactAvatar width={80} height={80} />
                    ) : (
                      <div className={s.profilePic}>
                        <h1 className={s.name}>
                          {data
                            ? selectedContact?.contact.firstName[0].toUpperCase() +
                              selectedContact?.contact.lastName[0].toUpperCase()
                            : "N/A"}
                        </h1>
                      </div>
                    )}
                    <span>
                      <h2 className={s.name}>
                        {selectedContact?.contact.firstName &&
                        selectedContact?.contact.lastName
                          ? (selectedContact?.contact.firstName || "N/A") +
                            " " +
                            (selectedContact?.contact.lastName || "N/A")
                          : "N/A"}
                      </h2>
                    </span>
                  </div>
                  <table className={s.table}>
                    <tbody>
                      <tr>
                        <td className={s.tableLabel}>Email address</td>
                        {selectedContact?.contact && (
                          <td className={clsx(s.tableLabel, s.tableData)}>
                            {selectedContact?.contact.email || "No email found"}
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td className={s.tableLabel}>Phone number</td>
                        <td className={clsx(s.tableLabel, s.tableData)}>
                          {selectedContact?.contact.phoneNo ||
                            "No phone number found"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default GroupDetails;
