import React from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import s from "./ResetPassword.module.scss";
import clsx from "clsx";
import { Button, Input } from "antd";
import EyeOpen from "../../../global components/Icons/EyeOpen/EyeOpen";
import EyeClose from "../../../global components/Icons/EyeClose/EyeClose";
import useSWRMutation from "swr/mutation";
import {
  parseClientError,
  swrMutationFetcher,
  SwrMutationFetcherArgType,
} from "../../../libs/api/Endpoints";
import Navigation from "../../../global components/Navigation/Navigation";
import { generateResult } from "../../../global components/ResultModal/ResultModal";
import { capitalize } from "../../../libs/common";

type Inputs = {
  newPassword: string;
  confirmPassword: string;
};
type locationState = {
  token: string;
};
function ResetPassword() {
  const location = useLocation();

  const { token = "" } = (location.state as locationState) || {};
  const { trigger: ResetPassword, isMutating } = useSWRMutation(
    `api/auth/reset-password/${token}`,
    swrMutationFetcher
  );
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm<Inputs>({ shouldFocusError: false });
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const urlEncodedData = new URLSearchParams();
    urlEncodedData.append("password", data.newPassword);
    urlEncodedData.append("passwordConfirmation", data.confirmPassword);

    try {
      const fetchArg: SwrMutationFetcherArgType = {
        requestOption: {
          body: urlEncodedData,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      };
      await ResetPassword(fetchArg);
      generateResult("success", "Password reset successful");
      reset();
      navigate("/auth");
    } catch (error) {
      const e = parseClientError(error);
      generateResult(
        "error",
        capitalize(e.message) || "An unexpected error occurred."
      );
    }
  };

  return (
    <div className={s.wrapper}>
      <div className={s.navigate}>
        <Navigation />
      </div>
      <div className={s.main}>
        <span className={s.titles}>
          <h3 className={s.title}>Reset password</h3>
          <p className={s.subtitle}>Create and confirm a new password </p>
        </span>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* New Password Input */}
          <div className={clsx(s.inputWrapper)}>
            <h5 className={clsx(s.inputLabel)}>New password</h5>
            <Controller
              name="newPassword"
              control={control}
              rules={{
                required: "Enter your new password",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters long",
                },
                pattern: {
                  value:
                    /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#._])[A-Za-z\d@$!%*?&#._]{6,}$/,
                  message:
                    "Password must include at least one uppercase letter, one number, and one special character",
                },
              }}
              render={({ field }) => (
                <Input.Password
                  {...field} // Spread the field props here
                  placeholder="input password"
                  className={clsx(s.input, s.customPasswordInput, {
                    [s.inputPasswordError]: errors.newPassword,
                  })}
                  iconRender={(visible) =>
                    visible ? (
                      <EyeOpen fill="#6A6767" />
                    ) : (
                      <EyeClose fill="#6A6767" />
                    )
                  }
                />
              )}
            />
            {/* Display new password error */}
            {errors.newPassword && (
              <span className={clsx(s.errorMessage)}>
                {errors.newPassword.message}
              </span>
            )}
          </div>

          {/* Confirm Password Input */}
          <div className={clsx(s.inputWrapper)}>
            <h5 className={clsx(s.inputLabel)}>Re-enter password</h5>
            <Controller
              name="confirmPassword"
              control={control}
              rules={{
                required: "Confirm your password",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters long",
                },
                pattern: {
                  value:
                    /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#._])[A-Za-z\d@$!%*?&#._]{6,}$/,
                  message:
                    "Password must include at least one uppercase letter, one number, and one special character",
                },
                validate: (value) =>
                  value === watch("newPassword") || "Passwords do not match", // Custom validation
              }}
              render={({ field }) => (
                <Input.Password
                  {...field} // Spread the field props here
                  placeholder="input password"
                  className={clsx(s.input, s.customPasswordInput, {
                    [s.inputPasswordError]: errors.confirmPassword,
                  })}
                  iconRender={(visible) =>
                    visible ? (
                      <EyeOpen fill="#6A6767" />
                    ) : (
                      <EyeClose fill="#6A6767" />
                    )
                  }
                />
              )}
            />
            {/* Display confirm password error */}
            {errors.confirmPassword && (
              <span className={clsx(s.errorMessage)}>
                {errors.confirmPassword.message}
              </span>
            )}
          </div>

          <Button
            type="default"
            className={clsx(s.button)} // Button style
            htmlType="submit" // Ensures it's tied to form submission
            loading={isMutating}
            disabled={isMutating}
          >
            Confirm
          </Button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
