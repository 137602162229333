import React from "react";
import { IconsType } from "../../libs/Utils/types";

function Contact({ height, width, fill }: IconsType) {
  return (
    <div  >
      <svg
        width={width}
        height={height}
        viewBox="0 0 70 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M35.0039 47.3684C48.3862 47.3684 59.2347 36.7646 59.2347 23.6842C59.2347 10.6038 48.3862 -3.05176e-05 35.0039 -3.05176e-05C21.6216 -3.05176e-05 10.7731 10.6038 10.7731 23.6842C10.7731 36.7646 21.6216 47.3684 35.0039 47.3684Z"
          fill={fill || "#F0EFEE"}
        />
        <path
          d="M43.0808 52.6316H26.927C19.7892 52.6399 12.9461 55.4151 7.89894 60.3485C2.85173 65.2819 0.0124563 71.9705 0.00390625 78.9473V94.7368C0.00390625 96.1327 0.571213 97.4714 1.58102 98.4584C2.59084 99.4455 3.96043 100 5.38852 100H64.6193C66.0474 100 67.417 99.4455 68.4268 98.4584C69.4366 97.4714 70.0039 96.1327 70.0039 94.7368V78.9473C69.9954 71.9705 67.1561 65.2819 62.1089 60.3485C57.0617 55.4151 50.2187 52.6399 43.0808 52.6316Z"
          fill={fill || "#F0EFEE"}
        />
      </svg>
    </div>
  );
}

export default Contact;
