import React, { useEffect, useState } from 'react';
import { Tag } from 'antd';
import "./tags.scss"
// Define the structure of a tag
interface CustomTag {
  key: string;
  label: string;
}

interface TagsProps {
  initialTags?: CustomTag[]; // Initialize with existing tags
  newTag?: string; // New prop to receive new tag value
  onTagsChange?: (updatedTags: CustomTag[]) => void; // Callback for tag changes
  renderTag?: (tag: CustomTag, closable: boolean, onClose: (key: string) => void) => React.ReactNode;
}

const Tags: React.FC<TagsProps> = ({
  initialTags = [],
  newTag,
  onTagsChange,
  renderTag,
}) => {
  const [tags, setTags] = useState<CustomTag[]>(initialTags);

  useEffect(() => {
    // Initialize with initialTags
    setTags(initialTags);
  }, [initialTags]);

  useEffect(() => {
    // Add the new tag if it's provided and not already in the tags list
    if (newTag && !tags.some(tag => tag.label === newTag)) {
      const newTagObject: CustomTag = { key: Date.now().toString(), label: newTag };
      const updatedTags = [...tags, newTagObject];
      setTags(updatedTags);
      onTagsChange?.(updatedTags); // Notify parent component
    }
  }, [newTag, onTagsChange, tags]);

  const handleClose = (removedKey: string) => {
    const newTags = tags.filter((tag) => tag.key !== removedKey);
    setTags(newTags);
    onTagsChange?.(newTags); // Notify parent component
  };

  const defaultRenderTag = (tag: CustomTag, closable: boolean, onClose: (key: string) => void) => (
    <Tag
      key={tag.key}
      closable={closable}
      
      onClose={(e) => {
        e.preventDefault();
        onClose(tag.key);
      }}
    >
      {tag.label}
    </Tag>
  );

  return (
    <div style={{ marginBottom: 16 }}>
      {tags.map((tag) =>
        renderTag
          ? renderTag(tag, true, handleClose)
          : defaultRenderTag(tag, true, handleClose)
      )}
    </div>
  );
};

export default Tags;
