import axios from "axios";
import React, { createContext, ReactNode, useContext, useState } from "react";
interface ContextType {
  countryCode: string;
}
const CountryCodeContext = createContext<ContextType | undefined>(undefined);

export const CountryCodeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [countryCode, setCountryCode] = useState("ng"); // Default country code

  axios
    .get("https://ipapi.co/json/")
    .then((response) => {
      let data = response.data;
      setCountryCode(data.country_code);
    })
    .catch((error) => {
      console.log(error);
    });
  return (
    <CountryCodeContext.Provider value={{ countryCode }}>
      {children}
    </CountryCodeContext.Provider>
  );
};
export const useCountryCode = (): ContextType => {
    const context = useContext(CountryCodeContext);
    if (context === undefined) {
      throw new Error('useToggle must be used within a ToggleProvider');
    }
    return context;
  };