import clsx from "clsx";
import React from "react";
import s from "./TypeBox.module.scss";
import Typeanimation from "../Type Animation";
type TypeBoxProp = {
  title: string;
  body: string;
};
function TypeBox({ title, body }: TypeBoxProp) {
  return (
    <div className={clsx(s.wrapper)}>
      <span>
        <h4 className={clsx(s.title)}>{title}</h4>
      </span>
      <span>
        <p className={s.body}>{body}</p>
      </span>
    </div>
  );
}

export default TypeBox;
