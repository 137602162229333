import React, { useEffect, useState } from "react";
import UploadContacts from "../Icons/UploadContacts/UploadContacts";
import DragAndDrop from "../DragAndDrop/DragAndDrop";
import useSWRMutation from "swr/mutation";
import {
  swrMutationFetcher,
  SwrMutationFetcherArgType,
  parseClientError,
} from "../../libs/api/Endpoints";
import s from "./UploadContact.module.scss";
import clsx from "clsx";
import { Button, Table, TableColumnsType } from "antd";
import { UseAuthToken } from "../../libs/api/userSession";
import { ContactData } from "../../libs/Utils/types";
import { mutate as mutateContact } from "swr";
import { generateResult } from "../ResultModal/ResultModal";
import { capitalize } from "../../libs/common";

type DataType = ContactData & {
  key: React.Key;
};

interface DataRow {
  [key: string]: string;
}

type UploadContactProps = {
  handleModal: () => void;
  contactGroupId?: string | null;
  mutate?: () => void;
  Close: boolean;
};

const REQUIRED_HEADERS = [
  "first name",
  "last name",
  "email address",
  "phone number",
];

const UploadContactForm: React.FC<UploadContactProps> = ({
  handleModal,
  contactGroupId,
  mutate,
  Close,
}) => {
  const [csvData, setCsvData] = useState<string>("");
  const [jsonData, setJsonData] = useState<DataRow[]>([]);
  const [error, setError] = useState<string | null>(null);

  const token = UseAuthToken();
  const { trigger: uploadContact, isMutating: isUploading } = useSWRMutation(
    "api/contacts/bulk-create",
    swrMutationFetcher
  );

  const columns: TableColumnsType<DataType> = [
    {
      title: "S/N",
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (serialNumber: number) => (
        <span className={clsx(s.cell, s.name)}>{serialNumber}</span>
      ),
    },
    {
      title: "first name",
      dataIndex: "firstName",
      render: (firstName: string) => (
        <span className={clsx(s.cell, s.name)}>{firstName}</span>
      ),
    },
    {
      title: "last name",
      dataIndex: "lastName",
      render: (lastName: string) => (
        <span className={clsx(s.cell, s.name)}>{lastName}</span>
      ),
    },
    {
      title: "Email address",
      dataIndex: "email",
      render: (email: string) => <span className={s.cell}>{email}</span>,
    },
    {
      title: "Phone number",
      dataIndex: "pNumber",
      render: (pNumber: string) => <span className={s.cell}>{pNumber}</span>,
    },
  ];

  const parseCSV = (csv: string): DataRow[] | string => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+[1-9]\d{1,14}$/; // Updated regex for mandatory '+'

    const rows = csv.trim().split("\n");
    const headers = rows[0]
      .split(",")
      .map((header) => header.trim().toLowerCase());

    const missingHeaders = REQUIRED_HEADERS.filter(
      (header) => !headers.includes(header)
    );
    if (missingHeaders.length > 0) {
      return `Missing required headers: ${missingHeaders.join(", ")}`;
    }

    const data = rows.slice(1).map((row, rowIndex) => {
      const values = row.split(",");
      const entry: DataRow = headers.reduce((acc, header, index) => {
        acc[header] = values[index]?.trim() || "";
        return acc;
      }, {} as DataRow);

      // Validate email
      const email = entry["email address"];
      if (email && !emailRegex.test(email)) {
        generateResult(
          "error",
          `Invalid email address at row ${rowIndex + 2}: ${email}`
        );
      }

      // Validate phone number
      // const phone = entry["phone number"];
      // if (phone && !phoneRegex.test(phone)) {
      //   throw new Error(
      //     `Invalid phone number at row ${rowIndex + 2}: ${phone}`
      //   );
      // }
      // Map to required format
      return {
        firstName: entry["first name"],
        lastName: entry["last name"],
        email: entry["email address"],
        phoneNo: entry["phone number"],
      };
    });

    return data;
  };

  const handleBlobToJson = async (blob: Blob): Promise<void> => {
    const reader = new FileReader();
    setError(null);

    reader.onload = () => {
      const csvContent = reader.result as string;
      const result = parseCSV(csvContent);

      if (typeof result === "string") {
        setError(result);
      } else {
        setJsonData(result);
      }
    };

    reader.readAsText(blob);
  };

  const handleFileChange = (file: string | File) => {
    if (file instanceof File) {
      const reader = new FileReader();
      reader.onload = () => {
        setCsvData(reader.result as string);
      };
      reader.readAsText(file);
    } else {
      setCsvData(file);
    }
  };

  const handleCsv = () => {
    if (!csvData) {
      generateResult("error", "No CSV data found.");
      return;
    }
    try {
      const blob = new Blob([csvData], { type: "text/csv" });
      handleBlobToJson(blob);
      console.log(handleBlobToJson(blob));
    } catch (error) {
      generateResult(
        "error",
        typeof error === "string" ? error : "Something went wrong"
      );
    }
  };

  const uploadCsv = async () => {
    if (!csvData) {
      generateResult("error", "No CSV data found.");
      return;
    }

    try {
      const jsonBody = {
        contacts: jsonData,
        ...(contactGroupId && { contactGroupId }), // Conditional spread syntax
      };
      const fetcherArg: SwrMutationFetcherArgType = {
        token,
        requestOption: {
          body: JSON.stringify(jsonBody),
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Specify JSON content type
          },
        },
      };

      await uploadContact(fetcherArg);
      generateResult("success", "Contacts Imported Successfully");
      handleModal();
      mutate ? mutate() : mutateContact("api/contacts");
      setCsvData("");
      setJsonData([]);
    } catch (error) {
      const e = parseClientError(error);
      generateResult("error", capitalize(e.message) || "Something went wrong");
    }
  };

  useEffect(() => {
    if (error) {
      generateResult("error", error);
    }
  }, [error]);
  useEffect(() => {
    if (Close) {
      setCsvData("");
      setJsonData([]);
    }
  }, [Close]);
  console.log(jsonData);
  function handleClear() {
    setCsvData("");
    setJsonData([]);
  }
  return (
    <div>
      {jsonData.length <= 0 && (
        <DragAndDrop
          onFileChange={handleFileChange}
          icon={<UploadContacts width={26} height={22} fill="#BEC2CA" />}
        />
      )}
      {jsonData.length > 0 && (
        <Table<DataType>
          columns={columns}
          dataSource={jsonData.map((contact, index) => ({
            key: index,
            serialNumber: index + 1,
            firstName: contact.firstName || "N/A",
            lastName: contact.lastName || "N/A",
            email: contact.email,
            pNumber: contact.phoneNo,
          }))}
          pagination={false}
        />
      )}
      {csvData && jsonData.length <= 0 && (
        <Button onClick={handleCsv} className={clsx(s.button)}>
          Continue to Organize
        </Button>
      )}
      {jsonData.length > 0 && (
        <div className={s.btnGroups}>
          <Button
            onClick={uploadCsv}
            loading={isUploading}
            className={clsx(s.button)}
          >
            Import Contacts
          </Button>
          <Button onClick={handleClear} className={s.button}>
            Clear records
          </Button>
        </div>
      )}
    </div>
  );
};

export default UploadContactForm;
