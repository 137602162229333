import React, { useState } from "react";
import s from "./DestructiveModal.module.scss";
import clsx from "clsx";
import { Modal, Button } from "antd";

interface ModalContentProps {
  title: string;
  message?: string;
  handleDelete: () => Promise<void>; // Assuming deletion is an async function
  isMutating?: boolean;
  logout?: string;
  onClose: () => void; // Pass a close handler to this component
  cancel?: "cancel";
}

const ModalContent: React.FC<ModalContentProps> = ({
  title,
  message,
  handleDelete,
  logout,
  onClose,
  cancel,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteClick = async () => {
    setLoading(true);
    await handleDelete(); // Await the deletion action
    setLoading(false);

    onClose();
  };

  return (
    <div className={s.wrapper} style={{ textAlign: "center" }}>
      <div className={s.titles}>
        <h3 className={s.title}>{title}</h3>
        {message && <p className={s.message}>{message}</p>}
      </div>
      <div className={s.modalContactBtns}>
        <Button
          className={clsx(s.button, s.cancelBtn)}
          onClick={() => onClose()} // Close modal on cancel
        >
          No, cancel
        </Button>
        <Button
          onClick={handleDeleteClick}
          loading={loading} // Show loading state on delete
          className={clsx(s.button, s.modalContactBtn)}
        >
          Yes, {logout ? logout : cancel ? cancel : "delete"}
        </Button>
      </div>
    </div>
  );
};

// Function to generate the confirmation modal
export function generateConfirmation(
  title: string,
  message: string,
  handleDelete: () => Promise<void>, // Async deletion function
  isMutating?: boolean,
  logout?: string,
  cancel?: "cancel"
) {
  const modalInstance = Modal.success({
    title: "", // Empty title for no default title
    icon: <></>, // Remove the default icon
    content: (
      <ModalContent
        cancel={cancel}
        title={title}
        message={message}
        handleDelete={handleDelete}
        logout={logout}
        onClose={() => modalInstance.destroy()}
      />
    ), // Use ModalContent
    centered: true,
    closable: true,
    footer: null, // Custom footer is now inside ModalContent
  });
}
