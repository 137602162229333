import React from "react";
import { IconsType } from "../../libs/Utils/types";
function Linkeldin() {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 13.13V16.827H14.857V13.377C14.857 12.511 14.547 11.92 13.771 11.92C13.179 11.92 12.826 12.318 12.671 12.704C12.615 12.842 12.6 13.034 12.6 13.226V16.827H10.456C10.456 16.827 10.485 10.985 10.456 10.38H12.6V11.293L12.586 11.314H12.6V11.293C12.885 10.853 13.393 10.228 14.532 10.228C15.942 10.228 17 11.15 17 13.13ZM8.213 7.271C7.48 7.271 7 7.753 7 8.385C7 9.005 7.466 9.5 8.185 9.5H8.199C8.947 9.5 9.412 9.004 9.412 8.385C9.398 7.753 8.947 7.271 8.213 7.271ZM7.127 16.827H9.271V10.38H7.127V16.827Z"
          fill="#F9F9F9"
        />
        <path
          d="M12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22Z"
          fill="#F9F9F9"
        />
      </svg>
    </div>
  );
}

export default Linkeldin;
