import React, { ComponentPropsWithRef } from "react";
import { Drawer } from "antd";
import "./Drawer.scss";

type ReusableDrawerProps = ComponentPropsWithRef<typeof Drawer> & {
  Open: boolean; // Corrected prop name for clarity
  handleOpen: () => void; // Function to handle opening/closing the modal
  children: React.ReactNode;
};

function ReusableDrawer({
  Open,
  handleOpen,
  children,
  title,
  ...drawerProps
}: ReusableDrawerProps) {
  function handleClose() {
    //handleOpen();
  }

  return (
    <>
      <Drawer
      {...drawerProps}
        width={468}
        title={title}
        onClose={handleClose}
        open={Open}
        maskClosable={false}
      >
        {children}
      </Drawer>
    </>
  );
}

export default ReusableDrawer;
