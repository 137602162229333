import React from "react";
import { ThreeDots } from "react-loader-spinner";
import s from "./Loading.module.scss";
function Loading() {
  return (
    <div className={s.container}>
      <ThreeDots
        visible={true}
        height="100"
        width="100"
        color="#d52b1e"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperClass={s.iconWrapper}
      />
    </div>
  );
}

export default Loading;
