import React from "react";
import InfoIcon from "../../../../../global components/Icons/Info";
import s from "./Info.module.scss";
import { Alert, message, Skeleton } from "antd";
import useSWR from "swr";
import { UseAuthToken } from "../../../../../libs/api/userSession";
import {
  parseClientError,
  swrFetcher,
} from "../../../../../libs/api/Endpoints";
import { capitalize } from "../../../../../libs/common";
type Rate = {
  country: {
    name: string;
    prefix: string;
    code: string;
  };
  cost: {
    email: number;
    sms: number;
    mms: number;
    voice: number;
  };
  createdAt: string; // You might prefer Date if handling these as Date objects
  updatedAt: string;
  id: string;
};

type PricingData = {
  unitPrice: number;
  currency: string;
  rates: Rate[];
};

function Info() {
  const token = UseAuthToken();
  const {
    data: msgRates,
    error,
    isLoading,
    mutate,
  } = useSWR<PricingData, any, any>(
    token ? ["api/message-rates", token] : null,
    swrFetcher,
    {
      //refreshInterval: 10000,

      shouldRetryOnError: false, // Disable retrying on errors
    }
  );
  if (error) {
    const e = parseClientError(error);
    message.error(capitalize(e.message) || "Message rate failed to load");
  }
  return (
    <>
      {isLoading || error ? (
        <Skeleton.Button
          size="large"
          style={{ height: "131px" }}
          active={isLoading || error}
          shape={"square"}
          block={true}
        />
      ) : (
        <Alert
          banner
          rootClassName={s.banner}
          message="Cost of message"
          showIcon={true}
          icon={<InfoIcon width={18} height={18} />}
          description={
            <ul>
              <li>1 SMS costs {msgRates?.rates[0].cost.sms} credits</li>
              <li>1 email costs {msgRates?.rates[0].cost.email} credits</li>
              <li>
                1 voice message costs {msgRates?.rates[0].cost.voice} credits
              </li>
            </ul>
          }
        />
      )}
    </>
  );
}

export default Info;
