import { Modal, Button } from "antd";
import React from "react";
import SuccessIcon from "../../assets/images/Success.png";
import FailedIcon from "../../assets/images/Failed.png";
import s from "./ResultModal.module.scss";
type NotificationType = "success" | "info" | "warning" | "error";

interface ModalContentProps {
  title: string;
  message?: string;
  icon?: React.ReactNode;
}

// Render the content based on notification type
const renderContent = ({ title, message, icon }: ModalContentProps) => (
  <div className={s.wrapper} style={{ textAlign: "center" }}>
    {icon && <div className={s.state}>{icon}</div>}
    {/* Render custom icon if provided */}
    <div className={s.titles}>
      <h3 className={s.title}>{title}</h3>
      {message && <p className={s.message}>{message}</p>}
    </div>
  </div>
);

export function generateResult(
  type: NotificationType,
  title: string,
  message?: string
) {
  let icon;

  // Configure icon based on type
  switch (type) {
    case "success":
      icon = <img className={s.state} src={SuccessIcon} alt="success" />;
      break;

    case "error":
      icon = <img className={s.state} src={FailedIcon} alt="failed" />;
      break;

    default:
      icon = <img className={s.state} src={SuccessIcon} alt="success" />;
      break;
  }

  // Open the modal with a custom footer and no default icon or close button
  Modal.success({
    title: "", // Empty title for no default title
    icon: <></>,
    content: renderContent({ title, message, icon }),
    centered: true,
    closable: true, // Disable the default close icon (x) in the top-right corner
    footer: [
      <Button
        onClick={() => {
          // Perform any custom action here if needed
          Modal.destroyAll(); // Close the modal after the action
        }}
        className={s.button}
        key="ok"
        type="primary"
      >
        {"Got it"}
      </Button>,
    ],
  });
}
