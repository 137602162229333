import React from "react";
import s from "./PopoverItems.module.scss";


type Inputs = {
  handleTime: (time:string)=>void
};
function NewPopOverItems({handleTime}:Inputs) {

  return (
    <>
      
      <div className={s.wrapper}>
        <span className={s.PopOverItem} onClick={()=>handleTime("AM")}>
          <h5>AM</h5>
        </span>
        <span onClick={()=>handleTime("PM")} className={s.PopOverItem}>
          <h5>PM</h5>
        </span>
      </div>
    </>
  );
}

export default NewPopOverItems;
