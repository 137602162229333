import React, { createContext, useContext, useState, ReactNode } from "react";

// Define the shape of our context
interface ToggleContextType {
  isTrue: boolean;
  toggle: (value: boolean) => void;
  signUp: boolean;
  isFromSignUp: (value: boolean) => void;
}

// Create the context with a default value
const WelcomeContext = createContext<ToggleContextType | undefined>(undefined);

// Create a provider component
export const WelcomeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isTrue, setIsTrue] = useState(false);
  const [signUp, setSignUp] = useState(false);
  // Define a toggle function to switch between true and false
  const toggle = (a: boolean) => {
    setIsTrue(a);
  };
  const isFromSignUp = (a: boolean) => {
    setSignUp(a);
  };
  return (
    <WelcomeContext.Provider value={{ isTrue, toggle, isFromSignUp, signUp }}>
      {children}
    </WelcomeContext.Provider>
  );
};

// Custom hook for consuming the context
export const useToggle = (): ToggleContextType => {
  const context = useContext(WelcomeContext);
  if (context === undefined) {
    throw new Error("useToggle must be used within a ToggleProvider");
  }
  return context;
};
