import s from "./Checkout.module.scss";
import clsx from "clsx";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Button, DatePicker, Divider, Input } from "antd";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// Define Yup Schema for Validation
const schema = Yup.object().shape({
  holderName: Yup.string().required("Card holder name is required"),
  cardNo: Yup.string()
    .required("Card number is required")
    .matches(/^[0-9]{16}$/, "Card number must be 16 digits"),
  expiryDate: Yup.string().required("Expiry date is required"),
  cvv: Yup.number()
    .required("CVV is required")
    .min(100, "CVV must be 3 digits")
    .max(999, "CVV must be 3 digits"),
  promoCode: Yup.string().required("Enter promo code"),
});

type Inputs = {
  holderName: string;
  cardNo: string;
  expiryDate: string;
  cvv: number;
  promoCode: string;
};

function Checkout() {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    watch,
    reset,
  } = useForm<Inputs>({
    resolver: yupResolver(schema), // Apply Yup schema validation
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const urlEncodedData = new URLSearchParams();
    urlEncodedData.append("cardNo", data.cardNo);
    urlEncodedData.append("cvv", data.cvv.toString());
    urlEncodedData.append("expiryDate", data.expiryDate);
    urlEncodedData.append("holderName", data.holderName);

    reset();
  };
  return (
    <div className={clsx(s.wrapper)}>
      <section className={s.titles}>
        <h2 className={s.title}>Check out</h2>
        <h5 className={s.subtitle}>
          Provide your payment details to complete your purchase
        </h5>
      </section>
      <section className={s.mainWrapper}>
        <section className={s.sectionOne}>
          <h5 className={s.tableTitle}>Payment details</h5>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={clsx(s.inputWrapper)}>
              <h5 className={clsx(s.inputLabel)}>Card holder name</h5>
              <input
                type="text"
                placeholder="Name on card"
                className={clsx(s.input, {
                  [s.inputError]: errors.holderName,
                })}
                {...register("holderName")}
              />
              {errors.holderName && (
                <p className={s.errorMessage}>{errors.holderName.message}</p>
              )}
            </div>
            <div className={clsx(s.inputWrapper)}>
              <h5 className={clsx(s.inputLabel)}>Card number</h5>
              <input
                type="text"
                placeholder="xxxx xxxx xxxx xxxx"
                className={clsx(s.input, { [s.inputError]: errors.cardNo })}
                {...register("cardNo")}
              />
              {errors.cardNo && (
                <p className={s.errorMessage}>{errors.cardNo.message}</p>
              )}
            </div>
            <div className={clsx(s.inputWrapper)}>
              <h5 className={clsx(s.inputLabel)}>Expiry date</h5>
              <Controller
                name="expiryDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    onChange={(date, dateString) => field.onChange(dateString)}
                    picker="month"
                    className={clsx(s.input, {
                      [s.inputError]: errors.expiryDate,
                    })}
                    placeholder="MM/YYYY"
                  />
                )}
              />
              {errors.expiryDate && (
                <p className={s.errorMessage}>{errors.expiryDate.message}</p>
              )}
            </div>
            <div className={clsx(s.inputWrapper)}>
              <h5 className={clsx(s.inputLabel)}>CVV</h5>
              <input
                type="number"
                placeholder="000"
                className={clsx(s.input, { [s.inputError]: errors.cvv })}
                {...register("cvv")}
              />
              {errors.cvv && (
                <p className={s.errorMessage}>{errors.cvv.message}</p>
              )}
            </div>
          </form>
        </section>
        <Divider type="vertical" className={s.divider} />

        <section className={s.sectionTwo}>
          <h2 className={s.tableTitle}>Purchase summary</h2>
          <span className={s.applyWrapper}>
            <Controller
              name="promoCode"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  placeholder="Enter discount code"
                  className={clsx(s.input, {
                    [s.inputError]: errors.promoCode,
                  })}
                />
              )}
            />
            <Button
              className={clsx(s.applyBtn, {
                [s.activeApplyBtn]: watch("promoCode"),
              })}
              disabled={!watch("promoCode")}
            >
              Apply
            </Button>
          </span>
          <table className={s.table}>
            <tbody>
              <tr>
                <td className={s.credits}>5000 credits</td>
                <td className={s.leftAlign}>
                  <span className={s.priceDetails}>
                    <h5 className={s.price}>$50</h5>
                    <p className={s.quantity}>$0.01 X 5000</p>
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className={s.priceDiscount}>
                    <h5 className={s.credits}>500 credits</h5>
                    <p className={s.quantity}>Discount (10% bonus)</p>
                  </span>
                </td>

                <td className={s.leftAlign}>
                  <span className={s.priceDetails}>
                    <h5 className={s.price}>$0</h5>
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <Divider
                    variant="dotted"
                    style={{ borderColor: "#7cb305" }}
                  />
                </td>
                <td>
                  <Divider
                    variant="dotted"
                    style={{ borderColor: "#7cb305" }}
                  />
                </td>
              </tr>
              <tr>
                <td className={s.price}>Subtotal</td>
                <td className={s.leftAlign}>
                  <span className={s.priceDetails}>
                    <h5 className={s.price}>$50</h5>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <Button
            type="primary"
            htmlType="submit"
            className={s.button}
            loading={isSubmitting}
          >
            Checkout
          </Button>
        </section>
      </section>
    </div>
  );
}

export default Checkout;
