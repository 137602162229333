import { notification } from "antd";
import React, { createContext, ReactNode, useContext } from "react";

type NotificationType = "success" | "info" | "warning" | "error";

// `generatetoast` is now directly defined and exported
export const generatetoast = (
  type: NotificationType,
  message: string,
  icon?: React.ReactNode
) => {
  notification[type]({
    icon: icon ? icon : "",
    message: message,
    //description: "This is the content of the notification.",
    placement: "top",
  });
};

const ToastContext = createContext<{
  contextHolder: ReactNode;
}>({
  contextHolder: null,
});

export const useToast = () => useContext(ToastContext);

const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [api, contextHolder] = notification.useNotification();

  return (
    <ToastContext.Provider value={{ contextHolder }}>
      {contextHolder}
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
