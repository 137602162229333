import React from "react";
import { IconsType } from "../../../libs/Utils/types";
import clsx from "clsx";
import s from "./Home.module.scss";

function Home({ width, height, fill }: IconsType) {
  return (
    <div className={clsx(!width && s.wrapper)}>
      <svg
        viewBox="0 0 20 21"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: width || "100%",
          height: height || "100%",
        }}
        preserveAspectRatio="xMidYMid meet"
      >
        <path
          d="M19.7189 10.1274L17.7165 8.12734L10.7079 1.12726C10.5201 0.939789 10.2655 0.834473 10 0.834473C9.73451 0.834473 9.47989 0.939789 9.29213 1.12726L2.28352 8.12734L0.281057 10.1274C0.098675 10.316 -0.00224337 10.5686 3.78491e-05 10.8308C0.00231907 11.093 0.107617 11.3438 0.293254 11.5292C0.47889 11.7146 0.730011 11.8198 0.99253 11.8221C1.25505 11.8243 1.50796 11.7235 1.6968 11.5414L1.99016 11.2484V18.8345C1.99016 19.3649 2.20113 19.8736 2.57666 20.2487C2.9522 20.6238 3.46153 20.8345 3.99262 20.8345H6.99631C7.26185 20.8345 7.51652 20.7291 7.70429 20.5416C7.89205 20.354 7.99754 20.0997 7.99754 19.8345V15.8344C7.99754 15.5692 8.10303 15.3148 8.29079 15.1273C8.47856 14.9398 8.73323 14.8344 8.99877 14.8344H11.0012C11.2668 14.8344 11.5214 14.9398 11.7092 15.1273C11.897 15.3148 12.0025 15.5692 12.0025 15.8344V19.8345C12.0025 20.0997 12.1079 20.354 12.2957 20.5416C12.4835 20.7291 12.7381 20.8345 13.0037 20.8345H16.0074C16.5385 20.8345 17.0478 20.6238 17.4233 20.2487C17.7989 19.8736 18.0098 19.3649 18.0098 18.8345V11.2484L18.3032 11.5414C18.492 11.7235 18.7449 11.8243 19.0075 11.8221C19.27 11.8198 19.5211 11.7146 19.7067 11.5292C19.8924 11.3438 19.9977 11.093 20 10.8308C20.0022 10.5686 19.9013 10.316 19.7189 10.1274Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}

export default Home;
