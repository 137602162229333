import React from "react";
import { generateResult } from "../../../../global components/ResultModal/ResultModal";
import { parseClientError, swrMutationFetcher, SwrMutationFetcherArgType } from "../../../../libs/api/Endpoints";
import { capitalize } from "../../../../libs/common";
import { Link, useNavigate } from "react-router-dom";
import Facebook from "../../../../global components/Icons/Facebook";
import Instargram from "../../../../global components/Icons/Instargram";
import Twitter from "../../../../global components/Icons/Twitter";
import Linkeldin from "../../../../global components/Icons/Linkeldin";
import { Button } from "antd";
import s from "./Footer.module.scss";
import { SubmitHandler, useForm } from "react-hook-form";
import useSWRMutation from "swr/mutation";
import clsx from "clsx";
import logo from "../../../../assets/images/Cloudivi white.png";

type Inputs = {
  email: string;
};
function Footer() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
    reset,
  } = useForm<Inputs>({ shouldFocusError: false });
  const navigate = useNavigate();
  const { trigger: buyCredit, isMutating } = useSWRMutation(
    "api/newsletter/subscribe",
    swrMutationFetcher
  );
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const urlEncodedData = new URLSearchParams();
    urlEncodedData.append("email", data.email);
    try {
      const fetcherArg: SwrMutationFetcherArgType = {
        requestOption: {
          method: "POST",
          body: urlEncodedData,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      };
      const result = await buyCredit(fetcherArg);
      console.log(result);
      generateResult("success", capitalize(result.message));
      reset();
    } catch (error) {
      const e = parseClientError(error);
      console.log(e);
      generateResult(
        "error",
        capitalize(e.message) || "An unexpected error occurred."
      );
    }
  };
  return (
    <footer>
      <div className={s.footerWrapper}>
        <div className={s.footerMain}>
          <a href="#top">
            <img src={logo} className={s.logo} alt="Cloudivi log" />
          </a>
          <h3 className={s.about}>
            A powerful communication platform designed to help businesses and
            individuals effectively reach their audiences
          </h3>
          <h3 className={s.contact}>+17039923388 </h3>
          <h3 className={s.contact}>help@cloudivi.com</h3>
          <div className={s.links}>
            <Link to="#">
              <Facebook />
            </Link>
            <Link to="#">
              <Instargram />
            </Link>
            <Link to="#">
              <Twitter />
            </Link>
            <Link to="#">
              <Linkeldin />
            </Link>
          </div>
        </div>
        <div className={s.newsletter}>
          <div className={s.subscribe}>
            <h3>Subscribe to our newsletter</h3>
            <h4>Stay in the loop with all you need to know</h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className={s.form}>
            <input
              type="email"
              placeholder="Enter your email address"
              className={clsx(s.input, { [s.inputError]: errors.email })}
              {...register("email", { required: true })}
            />
            <Button htmlType="submit" loading={isMutating}>
              Subscribe now
            </Button>
          </form>
        </div>
      </div>

      <hr />
      <h3 className={s.copyright}>
        Copyright &copy; {new Date().getFullYear()} Cloudivi. All Rights
        Reserved.
      </h3>
    </footer>
  );
}

export default Footer;
