import React from "react";
import s from "./Card.module.scss";
import clsx from "clsx";

type CardProps = {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  onClick?: () => void;
  handleModal: () => void;
  handleUploadModal?: () => void;
};

function Card({
  icon,
  title,
  subtitle,
  onClick,
  handleModal,
  handleUploadModal,
}: CardProps) {
  function handleClick() {
    if (onClick) {
      onClick();
    }
    if (handleUploadModal) {
      handleUploadModal();
    }
    handleModal();
  }
  return (
    <div className={clsx(s.wrapper)} onClick={handleClick}>
      <span className={clsx(s.icon)}>{icon}</span>
      <span>
        <h5 className={clsx(s.title)}>{title}</h5>
        <h5 className={clsx(s.subtitle)}>{subtitle}</h5>
      </span>
    </div>
  );
}

export default Card;
