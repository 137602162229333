import React, { ComponentPropsWithRef } from 'react';
import { Breadcrumb } from 'antd';
import "./BreadCrumb.scss"
type Links = {
  title: React.ReactNode;
  icon?: React.ReactNode;
};

type BreadCrumbProp = ComponentPropsWithRef<typeof Breadcrumb> & {
  items: Links[];
  separator:React.ReactNode
};

const BreadCrumb = ({ items , separator}: BreadCrumbProp) => (
  <Breadcrumb
    separator={separator || "/"}
    items={items}
  />
);

export default BreadCrumb;
