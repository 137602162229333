import { jwtDecode } from "jwt-decode";
import type { RootStore, AppDispatch } from "./store";
import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux";
import { DecodedTokenType } from "./types";
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootStore> = useSelector;
export const isTokenExpired = (token: string): boolean => {
  if (!token) return true; // Return true if token is not provided
  try {
    const decoded: DecodedTokenType = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return currentTime > decoded.exp; // Check if the current time is greater than the expiration time
  } catch (error) {
    console.error("Failed to decode token:", error);
    return true; // If decoding fails, consider the token expired
  }
};
