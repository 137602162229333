import React from "react";
import { IconsType } from "../../../libs/Utils/types";
import s from "./Edit.module.scss";
import clsx from "clsx";
function Edit({ height, width, fill }: IconsType) {
  return (
    <div className={clsx(!width && s.wrapper)}>
      <svg
       
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: width || "100%",
          height: height || "100%",
        }}
        preserveAspectRatio="xMidYMid meet"
      >
        <path
          d="M5.4913 7.10526C7.39974 7.10526 8.94682 5.5147 8.94682 3.55263C8.94682 1.59057 7.39974 0 5.4913 0C3.58287 0 2.03579 1.59057 2.03579 3.55263C2.03579 5.5147 3.58287 7.10526 5.4913 7.10526Z"
          fill={fill || "#240806"}
        />
        <path
          d="M6.64314 7.89474H4.33947C3.32155 7.89599 2.34568 8.31228 1.6259 9.05228C0.906125 9.79228 0.501219 10.7956 0.5 11.8421V14.2105C0.5 14.4199 0.580903 14.6207 0.724911 14.7688C0.868919 14.9168 1.06424 15 1.26789 15H4.53835C4.38236 14.5884 4.34244 14.1399 4.42317 13.7061L4.96607 10.9153C5.05517 10.4568 5.27426 10.0357 5.59574 9.705L7.29125 7.96184C7.07745 7.92064 6.86064 7.89819 6.64314 7.89474Z"
          fill={fill || "#240806"}
        />
        <path
          d="M14.8273 5.7971C14.3946 5.3542 13.8089 5.10555 13.1983 5.10555C12.5877 5.10555 12.0019 5.3542 11.5692 5.7971L6.68308 10.8213C6.57573 10.9315 6.50252 11.0718 6.47267 11.2247L5.92901 14.0163C5.90622 14.1311 5.90856 14.2497 5.93586 14.3634C5.96317 14.4771 6.01475 14.5832 6.08687 14.6739C6.15899 14.7646 6.24984 14.8376 6.35282 14.8878C6.45581 14.9379 6.56836 14.9638 6.68231 14.9637C6.73384 14.9636 6.78526 14.9586 6.83589 14.9487L9.55116 14.3897C9.69986 14.3591 9.8364 14.2838 9.94355 14.1734L10.4826 13.6121L14.8273 9.14684C15.2581 8.70195 15.5 8.09974 15.5 7.47197C15.5 6.8442 15.2581 6.24199 14.8273 5.7971Z"
          fill={fill || "#240806"}
        />
      </svg>
    </div>
  );
}

export default Edit;
