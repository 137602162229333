import React, { useEffect, useState } from "react";
import SearchInput from "../../../../../../global components/Forms/SearchInput/SearchInput";
import s from "./AddExistingContacts.module.scss";
import { PaginatedContacts } from "../../../../../../libs/Utils/Types/Contact";
import useSWR, { mutate } from "swr";
import {
  parseClientError,
  swrFetcher,
  swrMutationFetcher,
  SwrMutationFetcherArgType,
} from "../../../../../../libs/api/Endpoints";
import { UseAuthToken } from "../../../../../../libs/api/userSession";
import ContactCheckboxList from "../../../../../../global components/ContactCheckbox/ContactCheckbox";
import { Button, Divider, message, Spin } from "antd";
import clsx from "clsx";
import useSWRMutation from "swr/mutation";
import { generateResult } from "../../../../../../global components/ResultModal/ResultModal";
import { singleContactGroup } from "../../../../../../libs/Utils/Types/ContactGroup";
import ConfirmationModal from "../../../../../../global components/CofirmationModal/ConfirmationModal";
import { capitalize } from "../../../../../../libs/common";
type props = {
  handleOpen: () => void;
  groupID: string | null;
  mutate: () => void;
};
function AddExistingContacts({ handleOpen, groupID, mutate }: props) {
  const [search, setSearch] = useState<string>("");
  const [contactsId, setContactsId] = useState<string[]>([]);
  const token = UseAuthToken();
  const [allContactsID, setAllContactsID] = useState<string[]>();
  const [open, setOpen] = useState<boolean>(false);

  const { trigger: addToGroup, isMutating } = useSWRMutation(
    `api/contact-groups/${groupID}`,
    swrMutationFetcher
  );
  const { data } = useSWR<singleContactGroup, any, any>(
    token ? [`api/contact-groups/${groupID}`, token, { search: search }] : null,
    swrFetcher,

    {
      refreshInterval: 10000, // Refetch when the window regains focus
      // Refetch every 10 seconds (10000 ms)
      shouldRetryOnError: false, // Disable retrying on errors
    }
  );

  const {
    data: TableContacts,
    isLoading,
    error,
  } = useSWR<PaginatedContacts, any, any>(
    token ? ["api/contacts", token, { search: search }] : null,
    swrFetcher,
    {
      revalidateOnFocus: true, // Refetch when the window regains focus
      // Refetch every 10 seconds (10000 ms)
      shouldRetryOnError: false, // Disable retrying on errors
    }
  );
  function handleSearch(input: string) {
    setSearch(input);
  }
  const handleSelectionChange = (selectedItems: string[]) => {
    setContactsId(selectedItems);
  };

  const addContacts = async () => {
    const jsonBody = { contactIds: contactsId };
    try {
      const fetcherArg: SwrMutationFetcherArgType = {
        token,
        requestOption: {
          body: JSON.stringify(jsonBody),
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
        },
      };
      await addToGroup(fetcherArg);
      generateResult("success", "Contacts added successfully");
      mutate();
      handleOpen();
    } catch (error) {
      const e = parseClientError(error);
      generateResult("error", capitalize(e.message) || "Something went wrong. Try again later");
    }
  };
  const handleConfirmationModal = () => {
    setOpen((prev) => !prev);
  };
  useEffect(() => {
    const allIds = data?.contacts.docs.map((contact) => contact._id);
    setAllContactsID(allIds);
  }, [data?.contacts.docs]);
  if (error) {
    const e = parseClientError(error);
    message.error(capitalize(e.message) || "Something went wrong");
  }
  return (
    <>
      <ConfirmationModal
        handleCancel={handleOpen}
        open={open}
        handleConfirmationModal={handleConfirmationModal}
      />

      <div className={s.wrapper}>
        <div className={s.upper}>
          <SearchInput
            width={425}
            height={45}
            handleSearch={handleSearch}
            className={s.input}
            placeholder="search contact"
          />
          {TableContacts && !isLoading && !error ? (
            <ContactCheckboxList
              defaultChecked={allContactsID}
              onSelectionChange={handleSelectionChange}
              contacts={TableContacts?.docs}
            />
          ) : (
            <div className={s.spin}>
              <Spin />
            </div>
          )}
        </div>

        <div className={s.add_to_group_footer}>
          <Divider />
          <div className={s.addBtns}>
            <Button
              className={clsx(s.button, s.cancelBtn)}
              onClick={handleConfirmationModal}
            >
              Cancel
            </Button>
            <Button
              onClick={addContacts}
              loading={isMutating}
              className={clsx(s.button, s.addContactBtn)}
            >
              Done
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddExistingContacts;
