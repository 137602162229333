import React from "react";
import { IconsType } from "../../libs/Utils/types";

function Exit({ width, height, fill }: IconsType) {
  return (
    <div  >
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.923 7.618C15.872 7.495 15.799 7.385 15.706 7.292L11.707 3.293C11.316 2.902 10.684 2.902 10.293 3.293C9.902 3.684 9.902 4.316 10.293 4.707L12.586 7H4C3.447 7 3 7.448 3 8C3 8.552 3.447 9 4 9H12.586L10.293 11.293C9.902 11.684 9.902 12.316 10.293 12.707C10.488 12.902 10.744 13 11 13C11.256 13 11.512 12.902 11.707 12.707L15.706 8.708C15.799 8.616 15.872 8.505 15.923 8.382C16.024 8.138 16.024 7.862 15.923 7.618Z"
          fill={fill}
        />
        <path
          d="M6 14H3C2.448 14 2 13.551 2 13V3C2 2.449 2.448 2 3 2H6C6.553 2 7 1.552 7 1C7 0.448 6.553 0 6 0H3C1.346 0 0 1.346 0 3V13C0 14.654 1.346 16 3 16H6C6.553 16 7 15.552 7 15C7 14.448 6.553 14 6 14Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}

export default Exit;
