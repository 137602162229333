import React, { useState, useEffect } from "react";
import BackArrow from "../Icons/BackArrow/BackArrow";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmationModal from "../CofirmationModal/ConfirmationModal";

function Navigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);
  const [isLeaving, setIsLeaving] = useState<boolean>(false);

  const pathName = location.pathname;

  const handleConfirmationModal = () => {
    setOpen((prev) => !prev);
  };

  const handleOpen = () => {
    setOpen(false);
  };

  const handleNavigate = () => {
    if (
      pathName === "/dashboard/Message/email" ||
      pathName === "/dashboard/Message/sms" ||
      pathName === "/dashboard/Message/voice-message"
    ) {
      handleConfirmationModal();
    } else {
      navigate(-1);
    }
  };

  // Triggered when user attempts to leave the page
  // useEffect(() => {
  //   const handleBeforeUnload = (e: BeforeUnloadEvent) => {
  //     if (
  //       pathName === "/dashboard/Message/email" ||
  //       pathName === "/dashboard/Message/sms" ||
  //       pathName === "/dashboard/Message/voice-message"
  //     ) {
  //       e.preventDefault()
  //       //e.returnValue = ''

  //      handleConfirmationModal()
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [ pathName]);

  // Prevent navigation while modal is open
  

  return (
    <div>
      <ConfirmationModal
        msg="Are you sure you want to leave this page?"
        handleCancel={handleOpen}
        open={open}
        handleConfirmationModal={handleConfirmationModal}
      />
      <BackArrow onClick={handleNavigate} />
    </div>
  );
}

export default Navigation;
