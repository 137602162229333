import React, { useCallback, useEffect, useRef, useState } from "react";
import RichTextEditor from "../../../../global components/React Quill Text Editor/QuilEditor";
import s from "./Email.module.scss";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  DatePicker,
  Input,
  message,
  Spin,
  TimePicker,
  TimePickerProps,
} from "antd";
import SelectField from "../../../../global components/Forms/SelectField/SelectField";
import {
  ContactGroup,
  PaginatedContactGroups,
  singleContactGroup,
} from "../../../../libs/Utils/Types/ContactGroup";
import { UseAuthToken } from "../../../../libs/api/userSession";
import useSWR from "swr";
import {
  parseClientError,
  swrFetcher,
  swrMutationFetcher,
  SwrMutationFetcherArgType,
} from "../../../../libs/api/Endpoints";
import { useNavigate } from "react-router-dom";
import { generateResult } from "../../../../global components/ResultModal/ResultModal";
import ReusableDrawer from "../../../../global components/Drawer/ReuseableDrawer";
import ReusablePopover from "../../../../global components/Popover/ReuseablePopover";
import NewPopOverItems from "../TimePopOver/PopOverItems";
import ArrowDown from "../../../../global components/Icons/ArrowDown";
import dayjs, { Dayjs } from "dayjs";
import { capitalize, useQuery } from "../../../../libs/common";
import { MessageInstance } from "antd/es/message/interface";
import useSWRMutation from "swr/mutation";
import { useDraft } from "../../../../global components/Context/DraftContext";
import CheckboxList from "../../../../global components/CheckBox/CheckBox";
import CheckboxGroup from "../../../../global components/CheckBoxGroup/CheckBoxGroup";
import ReusableModal from "../../../../global components/Modal/Modal";
import { MessageDetailsType } from "../../../../libs/Utils/Types/Message";
import ConfirmationModal from "../../../../global components/CofirmationModal/ConfirmationModal";
import { Oval } from "react-loader-spinner";
import utc from "dayjs/plugin/utc";
import { SuccessDataType } from "../../../../libs/api/types";

dayjs.extend(utc);
type Inputs = {
  msgTitle: string;
  emailSubject: string;
  recipient: string;
  date: string;
  time: string;
  from: string;
};

function Email() {
  const query = useQuery();
  const groupId = query.get("groupId");
  const msgId = query.get("msgId");
  const [openMobile, setOpenMobile] = useState<boolean>(false);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [content, setContent] = useState("");
  const [openGroup, setOpenGroup] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<string | string[]>("");
  const [checkboxGroups, setCheckboxGroups] = useState<ContactGroup[]>();
  const hasUpdated = useRef(false); // Tracks if state has been set
  const [recipient, setRecipients] = useState<string>("");
  const token = UseAuthToken();
  const [msgID, setmsgId] = useState<string>("");

  const { trigger: submitMsg } = useSWRMutation(
    `api/messages/${msgID ? msgID : ""}?`,
    swrMutationFetcher
  );
  const { trigger: uploadImage, isMutating } = useSWRMutation(
    "api/media/upload",
    swrMutationFetcher
  );
  const { data: groups } = useSWR<PaginatedContactGroups, any, any>(
    token ? [`api/contact-groups`, token] : null,
    swrFetcher,
    {
      shouldRetryOnError: true,
    }
  );
  const { cancelDraft, debounceTimeout } = useDraft();

  const { data: group } = useSWR<singleContactGroup, any, any>(
    token && groupId ? [`api/contact-groups/${groupId}`, token] : null,
    swrFetcher,
    {
      onpause: () => !groupId,
    }
  );
  const { data: Recipient, isLoading: loading } = useSWR<
    singleContactGroup,
    any,
    any
  >(
    token && recipient ? [`api/contact-groups/${recipient}`, token] : null,
    swrFetcher,
    {
      onpause: () => !recipient,
    }
  );
  const {
    data: MessageDetails,
    isLoading,
    error,
  } = useSWR<MessageDetailsType, any, any>(
    token && msgId ? [`api/messages/${msgId}`, token] : null,
    swrFetcher,
    {
      shouldRetryOnError: true,
    }
  );
  console.log(MessageDetails?.body);
  const {
    watch,
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
    reset,
  } = useForm<Inputs>({ shouldFocusError: false });
  const [groupIds, setGroupsId] = useState<string[]>([]);
  const [recipients, setRecipient] = useState<string>("");
  const navigate = useNavigate();
  const [images, setImages] = useState<Blob[]>([]);
  // Handler to receive edited content
  const handleEdit = (newContent: string) => {
    setContent(newContent);
  };
  const handleImageUpload = async (blob: Blob) => {
    // You can handle the blob here, for example, upload it to a server

    // Optionally, convert the blob to an object URL if you want to display it

    setImages((prevImages) => [...prevImages, blob]);
  };
  const handleDraft = useCallback(() => {
    // Clear the previous timeout if it exists
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout
    debounceTimeout.current = setTimeout(async () => {
      try {
        const formData = new FormData();

        if (watch("date") && watch("time")) {
          const combinedDateTime = combineDateTime(
            watch("date"),
            watch("time")
          );
          console.log(combineDateTime(watch("date"), watch("time")));

          if (combinedDateTime)
            formData.append("scheduledFor", combinedDateTime);
        }
        if (watch("msgTitle")) formData.append("title", watch("msgTitle"));
        if (watch("from")) formData.append("senderName", watch("from"));
        formData.append("type", "email");
        if (watch("emailSubject"))
          formData.append("subject", watch("emailSubject"));
        let newString: string = "";
        if (watch("recipient")) {
          const contactGroupIds = [];
          contactGroupIds.push(watch("recipient"));
          contactGroupIds.forEach((id) => {
            formData.append("contactGroupIds[]", id); // Append each ID as part of an array
          });
        }
        const fetcherArg: SwrMutationFetcherArgType = {
          token,
          requestOption: {
            method: msgID ? "PATCH" : "POST",
            body: formData,
          },
        };
        if (images.length > 0) {
          let newString = content;

          // Process each image in the images array
          for (const image of images) {
            const img = await handleEmail(image);
            console.log(img);
            // Replace each <img> tag with the processed image URL
            newString = newString.replace(
              /<img[^>]*src=["']data:image\/[a-zA-Z]+;base64,[^"']+["'][^>]*>/,
              `<img src="${img}" alt="image" title="img" style="max-width: 100%; max-height: 257px; width: 400px; height: auto;" />`
            );
          }

          // Update any remaining content as necessary
          newString = newString.replace(/https:\/\//g, "http://");
          formData.append("body", newString);

          formData.append("isDraft", "true");

          if (
            watch("msgTitle") &&
            watch("emailSubject") &&
            watch("recipient")
          ) {
            type response = {
              id: string;
            };
            const result: SuccessDataType<response> = await submitMsg(
              fetcherArg
            );
            hasUpdated.current = true; // Set the flag to prevent further updates
            if (!msgId) {
              if (!msgID || !hasUpdated.current) setmsgId(result.data.id);
            }
          }
        } else {
          newString = content;
          formData.append("body", newString);

          formData.append("isDraft", "true");

          if (
            watch("msgTitle") &&
            watch("emailSubject") &&
            watch("recipient")
          ) {
            type response = {
              id: string;
            };
            const result: SuccessDataType<response> = await submitMsg(
              fetcherArg
            );
            hasUpdated.current = true; // Set the flag to prevent further updates
            if (!msgId)
              if (!msgID || !hasUpdated.current) setmsgId(result.data.id);
          }
        }

        // if (groupIds) {
        //   groupIds.forEach((id) => formData.append("contactGroupIds[]", id));
        // }
      } catch (error) {
        console.error("Failed to submit draft:", error);
      }
    }, 5000); // 45 seconds delay

    // Clean up the timeout on unmount or when the effect is re-run
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [
    debounceTimeout,
    watch,
    schedule,
    token,
    msgID,
    images,
    content,
    handleEmail,
    submitMsg,
  ]);

  const [open, setOpen] = useState<boolean>(false);
  const watchedFields = watch(["msgTitle", "emailSubject", "recipient"]);
  const watchedRecipient = watch(["recipient"]);

  // Effect to call handleDraft whenever content changes
  useEffect(() => {
    handleDraft();
  }, [handleDraft, watchedFields, content]);
  useEffect(() => {
    if (watch("recipient")) {
      setRecipients(watch("recipient"));
    }
  }, [watch, watchedRecipient]);
  // Submit handler for the form
  console.log(recipient, Recipient);
  const onSubmit = async (data: Inputs) => {
    if (!content) {
      generateResult("error", "You must have a body!");
      return;
    }
    if (Recipient?.contacts.docs.length === 0) {
      generateResult(
        "error",
        "0 contacts found!",
        "Select a group with at least one contact or add contacts to the group you want to sent message to"
      );
      return;
    }

    try {
      if (watch("date") && watch("time")) {
        const combinedDateTime = combineDateTime(watch("date"), watch("time"));
        // Navigate to the review page with the form data and formatted date/time
        let newString: string = "";
        if (images.length > 0) {
          let newString = content;

          // Process each image in the images array
          for (const image of images) {
            const img = await handleEmail(image);
            console.log(img);
            // Replace each <img> tag with the processed image URL
            newString = newString.replace(
              /<img[^>]*src=["']data:image\/[a-zA-Z]+;base64,[^"']+["'][^>]*>/,
              `<img src="${img}" alt="image" title="img" style="max-width: 100%; max-height: 257px; width: 400px; height: auto;" />`
            );
          }

          // Update any remaining content as necessary
          newString = newString.replace(/https:\/\//g, "http://");
          navigate("review", {
            state: {
              ...data,
              type: "email",
              content: newString,
              //recipient: groupIds,
              schedule: combinedDateTime
                ? combinedDateTime
                : MessageDetails?.scheduledFor && MessageDetails?.scheduledFor,

              msgId: msgID || msgId,
              reset: reset(),
            },
          });
        } else {
          newString = content;
          navigate("review", {
            state: {
              ...data,
              type: "email",
              content: newString,
              //recipient: groupIds,
              schedule: combinedDateTime
                ? combinedDateTime
                : MessageDetails?.scheduledFor && MessageDetails?.scheduledFor,

              msgId: msgID || msgId,
              reset: reset(),
            },
          });
        }

        generateResult(
          "success",
          "Review your email",
          "Review your message before sending or check your drafts to edit the message"
        );
      } else {
        let newString: string = "";
        if (images.length > 0) {
          let newString = content;

          // Process each image in the images array
          for (const image of images) {
            const img = await handleEmail(image);

            // Replace each <img> tag with the processed image URL
            newString = newString.replace(
              /<img[^>]*src=["']data:image\/[a-zA-Z]+;base64,[^"']+["'][^>]*>/,
              `<img src="${img}" alt="image" title="img" style="max-width: 100%; max-height: 257px; width: 400px; height: auto;" />`
            );
          }

          // Update any remaining content as necessary
          newString = newString.replace(/https:\/\//g, "http://");
          navigate("review", {
            state: {
              ...data,
              type: "email",
              content: newString,
              //recipient: groupIds,

              msgId: msgID || msgId,
            },
          });
          generateResult(
            "success",
            "Review your email",
            "Review your message before sending or check your drafts to edit the message"
          );
        } else {
          newString = content;
          navigate("review", {
            state: {
              ...data,
              type: "email",
              content: newString,
              //recipient: groupIds,

              msgId: msgID || msgId,
              reset: reset(),
            },
          });

          generateResult(
            "success",
            "Review your email",
            "Review your message before sending or check your drafts to edit the message"
          );
        }
      }
    } catch (error) {
      const e = parseClientError(error);
      generateResult("error", e.message || "An unexpected error occurred.");
    }
  };

  function handleNewGroup() {
    setOpenGroup((prev) => !prev);
  }
  function handleModal() {
    setOpenModal((prev) => !prev);
  }
  const onChange: TimePickerProps["onChange"] = (time, timeString) => {
    setSchedule(timeString);
  };
  const handleTime = (time: string) => {
    setSchedule((prev) => prev + time);
  };
  const combineDateTime = (
    date: string | Dayjs | null,
    time: string | Dayjs | null
  ) => {
    if (date && time) {
      const parsedDate = dayjs(date); // Convert to Dayjs if it's a string
      const parsedTime = dayjs(time); // Convert to Dayjs if it's a string

      if (parsedDate.isValid() && parsedTime.isValid()) {
        const combined = parsedDate
          .hour(parsedTime.hour())
          .minute(parsedTime.minute())
          .second(0)
          .millisecond(0);

        return combined.toISOString();
      }
    }
    return null; // Handle invalid or null inputs
  };
  const handleSelectionChange = (selectedItems: string[]) => {
    // Update tag state based on checkbox selection
    const updatedTags = selectedItems.map((label) => {
      const group = groups?.docs.find((g) => g.name === label);
      return { key: group?._id || "", label };
    });
    // Update groupId based on selected checkbox items
    const recipient = watch("recipient");

    // Add the recipient to the list of keys
    const idsWithRecipient = [...updatedTags.map((tag) => tag.key), recipient];

    // Update groupId based on selected checkbox items (including the recipient)
    setGroupsId(idsWithRecipient);
  };

  useEffect(() => {
    if (watch("recipient") && groups) {
      setRecipient(watch("recipient"));
      const recipient = watch("recipient");
      const id = [];
      id.push(recipient);
      setGroupsId(id);
      const ids = groups.docs.filter((doc) => doc._id !== recipient); // Filter out the object where _id equals recipient

      setCheckboxGroups(ids);
    }
  }, [groups, watch]);
  const selectedRecipient = watch("recipient");
  async function handleEmail(blob: Blob) {
    type Response = {
      secure_url: string;
    };
    if (blob) {
      const formData = new FormData();
      formData.append("media", blob);
      formData.append("type", "Message");
      const response: SuccessDataType<Response> = await uploadImage({
        token,
        requestOption: {
          method: "POST",
          body: formData,
        },
      });
      return response.data.secure_url;
    }
  }
  const handleConfirmationModal = () => {
    setOpen((prev) => !prev);
  };
  const handleMobileConfirmationModal = () => {
    setOpenMobile((prev) => !prev);
  };
  if (error) {
    const e = parseClientError(error);
    message.error(capitalize(e.message) || "Something went wrong");
  }

  useEffect(() => {
    if (MessageDetails) {
      setValue("msgTitle", MessageDetails.title);
      setValue("emailSubject", MessageDetails.subject);
      // setValue("date", dayjs(MessageDetails.scheduledFor).toISOString() || "");
      console.log(MessageDetails)
      // setValue("time", dayjs(MessageDetails.scheduledFor).toISOString());
      setValue("from", MessageDetails.senderName);
      setContent(MessageDetails.body || "");
    }
  }, [MessageDetails, setValue]);
  function handleReset() {
    setValue("date", "");
    setValue("time", "");
  }
  useEffect(() => {
    if (msgId) {
      setmsgId(msgId);
    }
  }, [msgId]);
  console.log(combineDateTime(watch("date"), watch("time")));

  return (
    <>
      <ConfirmationModal
        handleCancel={handleModal}
        open={openMobile}
        reset={handleReset}
        handleConfirmationModal={handleMobileConfirmationModal}
      />
      <ConfirmationModal
        handleCancel={handleNewGroup}
        open={open}
        reset={handleReset}
        handleConfirmationModal={handleConfirmationModal}
      />
      <ReusableModal
        title="Schedule message"
        handleModal={handleModal}
        isModalOpen={openModal}
        centered
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={s.form}>
            <div className={s.scheduleWrapper}>
              <div className={clsx(s.inputWrapper)}>
                <h5 className={clsx(s.inputLabel)}>Select date</h5>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      // value={
                      //   watch("date")
                      // } // Ensure value is a Day.js object or null
                      onChange={(date, dateString) => {
                        if (date) {
                          const isValidDate = dayjs(date.toString()).isValid();
                          if (isValidDate) {
                            field.onChange(date); // Set the value if valid
                          } else {
                            field.onChange(null); // Clear the field if invalid
                          }
                        }
                      }}
                      value={watch("date") ? dayjs(watch("date")) : null} // Ensure value is a Day.js object
                      className={clsx(s.input, {
                        [s.inputError]: errors.date,
                      })}
                      placeholder="MM/YYYY"
                    />
                  )}
                />
              </div>
              <div className={clsx(s.inputWrapper)}>
                <h5 className={clsx(s.inputLabel)}>Time</h5>
                <Controller
                  name="time"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      // Use dayjs to convert the string to a Dayjs object
                      className={clsx(s.input)}
                      defaultValue={
                        msgId ? dayjs(MessageDetails?.scheduledFor) : null
                      } // Use dayjs to convert the string to a Dayjs object
                      onChange={(time, timeString) => {
                        if (time) {
                          field.onChange(time); // Set the value
                        }
                      }}
                      changeOnScroll
                      format="HH:mm"
                      needConfirm={true}
                      suffixIcon={
                        <ReusablePopover
                          placement="left"
                          trigger="click"
                          content={<NewPopOverItems handleTime={handleTime} />}
                        >
                          <ArrowDown height={16} width={16} fill="#6A6767" />
                        </ReusablePopover>
                      }
                    />
                  )}
                />
              </div>
            </div>
            <div className={s.addContactBtns}>
              <Button
                type="default"
                className={clsx(s.button, s.cancelBtn)}
                onClick={handleMobileConfirmationModal}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className={clsx(s.button, s.addContactBtn)}
                onClick={() => {
                  generateResult("success", "Schedule set successfully");
                  handleModal();
                }}
              >
                Schedule
              </Button>
            </div>
          </div>
        </form>
      </ReusableModal>
      <ReusableDrawer
        title="Schedule message"
        Open={openGroup}
        handleOpen={handleNewGroup}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={s.form}>
            <div className={s.scheduleWrapper}>
              <div className={clsx(s.inputWrapper)}>
                <h5 className={clsx(s.inputLabel)}>Select date</h5>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      // value={
                      //   watch("date")
                      // } // Ensure value is a Day.js object or null
                      onChange={(date, dateString) => {
                        if (date) {
                          const isValidDate = dayjs(date.toString()).isValid();
                          if (isValidDate) {
                            field.onChange(date); // Set the value if valid
                          } else {
                            field.onChange(null); // Clear the field if invalid
                          }
                        }
                      }}
                      value={watch("date") ? dayjs(watch("date")) : null} // Ensure value is a Day.js object
                      className={clsx(s.input, {
                        [s.inputError]: errors.date,
                      })}
                      placeholder="MM/YYYY"
                    />
                  )}
                />
              </div>
              <div className={clsx(s.inputWrapper)}>
                <h5 className={clsx(s.inputLabel)}>Time</h5>
                <Controller
                  name="time"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      defaultValue={
                        msgId ? dayjs(MessageDetails?.scheduledFor) : null
                      } // Use dayjs to convert the string to a Dayjs object
                      className={clsx(s.input)}
                      onChange={(time, timeString) => {
                        if (time) {
                          field.onChange(time); // Set the value
                        }
                      }}
                      changeOnScroll
                      format="HH:mm"
                      needConfirm={true}
                      suffixIcon={
                        <ReusablePopover
                          placement="left"
                          trigger="click"
                          content={<NewPopOverItems handleTime={handleTime} />}
                        >
                          <ArrowDown height={16} width={16} fill="#6A6767" />
                        </ReusablePopover>
                      }
                    />
                  )}
                />
              </div>
            </div>
            <div className={s.addContactBtns}>
              <Button
                type="default"
                className={clsx(s.button, s.cancelBtn)}
                onClick={handleConfirmationModal}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className={clsx(s.button, s.addContactBtn)}
                onClick={() => {
                  generateResult("success", "Schedule set successfully");
                  handleNewGroup();
                }}
              >
                Schedule
              </Button>
            </div>
          </div>
        </form>
      </ReusableDrawer>
      {isLoading || error ? (
        <div className={s.spin}>
          <Spin />
        </div>
      ) : (
        <div className={s.wrapper}>
          <div className={s.emailTitles}>
            <div className={s.msgTitleWrapper}>
              <h1 className={s.msgTitle}>
                {msgId && MessageDetails
                  ? MessageDetails.title
                  : watch("msgTitle") || "Untitled"}
              </h1>
            </div>
            <div className={s.inputWrapper}>
              <h5 className={s.label}>Message title</h5>
              <Controller
                name="msgTitle"
                control={control}
                rules={{ required: "Message title is required" }}
                render={({ field }) => (
                  <Input
                    {...field}
                    defaultValue={
                      msgId && MessageDetails ? MessageDetails.title : ""
                    }
                    count={{
                      show: true,
                      max: 80,
                    }}
                    className={clsx(s.input, {
                      [s.inputError]: errors.msgTitle,
                    })}
                    maxLength={80}
                  />
                )}
              />
              {!errors.msgTitle && (
                <span className={s.helperText}>
                  <p className={s.titleMsg}>
                    This is just an internal title to help organize and locate
                    your message easily. This does not appear to your audience.
                  </p>
                </span>
              )}
              {errors.msgTitle && (
                <span className={clsx(s.errorMessage)}>
                  {errors.msgTitle.message}
                </span>
              )}
            </div>
            <div className={clsx(s.inputWrapper)}>
              <h5 className={s.label}>Email subject</h5>
              <Controller
                name="emailSubject"
                control={control}
                rules={{ required: "Subject is required" }}
                render={({ field }) => (
                  <Input
                    {...field}
                    defaultValue={
                      msgId && MessageDetails ? MessageDetails.subject : ""
                    } // Option 1
                    count={{
                      show: true,
                      max: 100,
                    }}
                    className={clsx(s.input, {
                      [s.inputError]: errors.emailSubject,
                    })}
                    maxLength={100}
                  />
                )}
              />
              {errors.emailSubject && (
                <span className={clsx(s.errorMessage)}>
                  {errors.emailSubject.message}
                </span>
              )}
            </div>
            <div className={s.inputWrapper}>
              <h5 className={s.label}>Recipient</h5>
              <Controller
                name="recipient"
                control={control}
                rules={{ required: "Recipient is required" }}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    bordered
                    placeholder="Select contact group that will get your message"
                    defaultValue={watch("recipient")}
                    wrapperClassname={s.SelectWrapper}
                    className={clsx(s.selectInput, {
                      [s.inputError]: errors.recipient,
                    })}
                    options={
                      groupId && group
                        ? [
                            {
                              label: group.name,
                              value: group._id,
                            },
                          ]
                        : groups?.docs.map((group) => ({
                            label: group.name,
                            value: group._id,
                          }))
                    }
                    style={{ width: "100%", height: "100%" }}
                    onChange={field.onChange}
                  />
                )}
              />
              {loading ? (
                <Oval
                  visible={loading}
                  height="16"
                  width="16"
                  color="#d52b1e"
                  ariaLabel="oval-loading"
                />
              ) : (
                !errors.recipient &&
                Recipient && (
                  <p className={s.helperText}>
                    {Recipient?.contacts.docs.length} Contacts
                  </p>
                )
              )}
              {errors.recipient && !Recipient?.contacts && (
                <span className={clsx(s.errorMessage)}>
                  {errors.recipient.message}
                </span>
              )}
            </div>
            <div className={clsx(s.inputWrapper)}>
              <h5 className={s.label}>From</h5>
              <input
                type="email"
                style={{ padding: "12px" }}
                className={clsx(s.input, { [s.inputError]: errors.from })}
                {...register("from", { required: false })}
              />
              {errors.from && (
                <span className={clsx(s.errorMessage)}>
                  {errors.from.message}
                </span>
              )}
            </div>
          </div>

          <div className={s.textEditor}>
            <div className={s.editorBtnGroup}>
              <button
                type="button"
                className={clsx(s.scheduleBtn)}
                onClick={handleNewGroup}
                disabled={isSubmitting}
              >
                Schedule
              </button>
              {/* For opening scheduling modal */}
              <button
                className={clsx(s.mobileScheduleBtn)}
                onClick={handleModal}
                disabled={isSubmitting}
              >
                Schedule
              </button>
              <button
                type="submit"
                //loading={isMutating || isSubmitting}
                className={clsx(s.submitBtn)}
                onClick={handleSubmit(onSubmit)}
                disabled={isSubmitting}
              >
                <Oval
                  visible={isSubmitting}
                  height="16"
                  width="16"
                  color="white"
                  ariaLabel="oval-loading"
                />
                Send now
              </button>
            </div>
            <RichTextEditor
              handleImageUpload={handleImageUpload}
              value={content}
              handleEdit={handleEdit}
              modules={null}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Email;
