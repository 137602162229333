import { configureStore } from "@reduxjs/toolkit";
import signup from "../redux/auth/signupSlice";
import signin from "../redux/auth/signinSlice"
export const store = configureStore({
  reducer: {
    signup: signup,
    signin,
  },
});

export type RootStore = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

