import React from "react";
import { IconsType } from "../../../libs/Utils/types";
import s from "./ContactAvatar.module.scss"
import clsx from "clsx";
function ContactAvatar({ height, width, fill }: IconsType) {
  return (
    <div className={clsx(!width && s.wrapper)}>
      <svg
       
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: width || "100%",
          height: height || "100%",
        }}
        preserveAspectRatio="xMidYMid meet"
      >
        <rect width="32" height="32" rx="16" fill="#DAD6D5" />
        <path
          d="M15.9993 15.5622C18.2297 15.5622 20.0378 13.7949 20.0378 11.6148C20.0378 9.43478 18.2297 7.66748 15.9993 7.66748C13.769 7.66748 11.9609 9.43478 11.9609 11.6148C11.9609 13.7949 13.769 15.5622 15.9993 15.5622Z"
          fill="white"
        />
        <path
          d="M17.3455 16.4394H14.6532C13.4636 16.4408 12.3231 16.9033 11.4819 17.7256C10.6407 18.5478 10.1674 19.6626 10.166 20.8254V23.457C10.166 23.6896 10.2606 23.9127 10.4289 24.0772C10.5972 24.2417 10.8254 24.3341 11.0635 24.3341H20.9352C21.1733 24.3341 21.4015 24.2417 21.5698 24.0772C21.7381 23.9127 21.8327 23.6896 21.8327 23.457V20.8254C21.8313 19.6626 21.358 18.5478 20.5168 17.7256C19.6756 16.9033 18.5351 16.4408 17.3455 16.4394Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default ContactAvatar;
