import React, { ComponentPropsWithRef, useEffect } from "react";
import { Modal } from "antd";
import "./Modal.scss";
import cn from "clsx";
import { useToggle } from "../Context/NavbarContext";

type ReusableModalProps = ComponentPropsWithRef<typeof Modal> & {
  isModalOpen: boolean;
  handleModal: () => void;
  children: React.ReactNode;
  title: string;
  subtitle?: React.ReactNode;
};

const ReusableModal = ({
  isModalOpen,
  handleModal,
  title,
  children,
  className,
  subtitle,
  ...modalProps
}: ReusableModalProps) => {
  const { isTrue, toggle } = useToggle();
 
  const handleCancel = () => {
    if(isTrue){
      toggle()
    }
    handleModal();
  };

  return (
    <Modal
      centered
      footer={false}
      open={isModalOpen}
      onCancel={handleCancel}
      {...modalProps}
      className={cn("confirmationModalContainer", className, { "has-title": title },
        { "has-subtitle": subtitle })} // Combine classNames here
    >
      <div className={"titleWrapper"}>
        <p className={"title"}>{title}</p>
        <p className={"subtitle"}>{subtitle}</p>
      </div>
      <div
        data-testid={`confirmationModal-testId`}
        className={"contentContainer"}
      >
        {children}
      </div>
    </Modal>
  );
};

export default ReusableModal;
