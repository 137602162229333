import React, { useEffect, useState } from "react";
import s from "./AddContacts.module.scss";
import Card from "./card";
import AddContacts from "../../../../../../global components/Icons/AddContacts/AddContacts";
import UploadContacts from "../../../../../../global components/Icons/UploadContacts/UploadContacts";
import ReusableDrawer from "../../../../../../global components/Drawer/ReuseableDrawer";
import AddContactForm from "../../../../../../global components/AddContactForm";
import ReusableModal from "../../../../../../global components/Modal/Modal";
import UploadContactForm from "../../../../../../global components/UploadContactForm/UploadContactForm";
import ContactsGroup from "../../../../../../global components/Icons/ContactGroup/ContactsGroup";
import AddExistingContacts from "../AddExistingContacts";
import { useToggle } from "../../../../../../global components/Context/NavbarContext";
type AddContactProp = {
  handleModal: () => void;
  groupID: string | null;
  mutate: () => void;
};
function AddContact({ handleModal, groupID, mutate }: AddContactProp) {
  const [open, setOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalForm, setOpenModalForm] = useState<boolean>(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [mobileModal, setMobileModal] = useState<boolean>(false);
  const [openExistingContact, setOpenExistingContact] =
    useState<boolean>(false);
    const { isTrue, toggle } = useToggle();

  const handleOpenExistingDrawer = () => {
    setOpenExistingContact((prev) => !prev);
  };
  const handleUploadModal = () => {
    if (!isTrue) {
      toggle();
    }
    setOpenModal((prev) => !prev);
  };
  function handleOpen() {
    setOpen((prev) => !prev);
  }
  const handleFormModal = () => {
    if (!isTrue) {
      toggle();
    }
    setOpenModalForm((prev) => !prev);
  };
  useEffect(() => {
    // Handler to update the state with the current width
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener for resize event
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleMobileModal = () => {
    handleModal();
    setMobileModal((prev) => !prev);
  };
  const [close, setClose] = useState<boolean>(false);
  function hanleClose() {
    setClose(true);
  }
  
    
  return (
    <>
      <ReusableModal
        footer={null}
        isModalOpen={openModalForm}
        title={"Add contact"}
        centered
        handleModal={handleFormModal}
      >
        <AddContactForm mutate={mutate} handleOpen={handleFormModal} />
      </ReusableModal>
      <ReusableModal
        onClose={hanleClose}
        footer={null}
        isModalOpen={openModal}
        centered
        title={"Upload contacts"}
        width={557}
        subtitle={
          <>
            Add a list of contacts from a csv file. <br></br> The csv file
            should include first name, last name, email address and phone number
          </>
        }
        handleModal={handleUploadModal}
      >
        <UploadContactForm
         Close={close}
          mutate={mutate}
          contactGroupId={groupID}
          handleModal={handleUploadModal}
        />
      </ReusableModal>
      <ReusableDrawer title={"Add contact"} Open={open} handleOpen={handleOpen}>
        <AddContactForm
          mutate={mutate}
          groupID={groupID}
          handleOpen={handleOpen}
        />
      </ReusableDrawer>
      <ReusableDrawer
        title={"Add existing contact"}
        Open={openExistingContact}
        handleOpen={handleOpenExistingDrawer}
      >
        <AddExistingContacts
          mutate={mutate}
          groupID={groupID}
          handleOpen={handleOpenExistingDrawer}
        />
      </ReusableDrawer>
      <ReusableModal
        title={"Add existing contact"}
        isModalOpen={mobileModal}
        handleModal={handleMobileModal}
        footer={null}
        centered
      >
        <AddExistingContacts
          mutate={mutate}
          groupID={groupID}
          handleOpen={handleMobileModal}
        />
      </ReusableModal>
      <div className={s.wrapper}>
        <div className={s.desktop}>
          <Card
            onClick={handleOpenExistingDrawer}
            title="Add existing contacts"
            subtitle="Select from your contact list"
            icon={<ContactsGroup fill="#D52B1E" />}
            handleModal={handleModal}
          />
        </div>
        <div className={s.mobile}>
          <Card
            onClick={handleOpenExistingDrawer}
            title="Add existing contacts"
            subtitle="Select from your contact list"
            icon={<ContactsGroup fill="#D52B1E" />}
            handleModal={handleMobileModal}
          />
        </div>
        <div className={s.desktop}>
          <Card
            onClick={handleOpen}
            title="Add a contact"
            subtitle="Quickly add a new individual contact"
            icon={<AddContacts fill="#D52B1E" />}
            handleModal={handleModal}
          />
        </div>
        <div className={s.mobile}>
          <Card
            onClick={handleFormModal}
            title="Add a contact"
            subtitle="Quickly add a new individual contact"
            icon={<AddContacts fill="#D52B1E" />}
            handleModal={handleModal}
          />
        </div>
        <Card
          title="Upload contacts"
          subtitle="Add a list of contacts from a csv file"
          icon={<UploadContacts fill="#D52B1E" />}
          handleModal={handleModal}
          handleUploadModal={handleUploadModal}
        />
      </div>
    </>
  );
}

export default AddContact;
