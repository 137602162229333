import React from "react";
import { Outlet } from "react-router-dom";
import s from "./Homepage.module.scss";
import Header from "../LandingPage/Header/Header";
import Footer from "./Local Components/Footer";
function HomePage() {
  return (
    <div>
      <div className={s.header}>
        <Header />
      </div>
      <Outlet />
      <Footer />
    </div>
  );
}

export default HomePage;
