import React from "react";

function Hamburger() {
  return (
    <div>
      <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.0588 2H0.941176C0.691561 2 0.452169 1.89464 0.275664 1.70711C0.0991596 1.51957 0 1.26522 0 1C0 0.734784 0.0991596 0.48043 0.275664 0.292893C0.452169 0.105357 0.691561 0 0.941176 0H15.0588C15.3084 0 15.5478 0.105357 15.7243 0.292893C15.9008 0.48043 16 0.734784 16 1C16 1.26522 15.9008 1.51957 15.7243 1.70711C15.5478 1.89464 15.3084 2 15.0588 2Z"
          fill="#1F2A37"
        />
        <path
          d="M15.0588 8H0.941176C0.691561 8 0.452169 7.89464 0.275664 7.70711C0.0991596 7.51957 0 7.26522 0 7C0 6.73478 0.0991596 6.48043 0.275664 6.29289C0.452169 6.10536 0.691561 6 0.941176 6H15.0588C15.3084 6 15.5478 6.10536 15.7243 6.29289C15.9008 6.48043 16 6.73478 16 7C16 7.26522 15.9008 7.51957 15.7243 7.70711C15.5478 7.89464 15.3084 8 15.0588 8Z"
          fill="#1F2A37"
        />
        <path
          d="M15.0588 14H0.941176C0.691561 14 0.452169 13.8946 0.275664 13.7071C0.0991596 13.5196 0 13.2652 0 13C0 12.7348 0.0991596 12.4804 0.275664 12.2929C0.452169 12.1054 0.691561 12 0.941176 12H15.0588C15.3084 12 15.5478 12.1054 15.7243 12.2929C15.9008 12.4804 16 12.7348 16 13C16 13.2652 15.9008 13.5196 15.7243 13.7071C15.5478 13.8946 15.3084 14 15.0588 14Z"
          fill="#1F2A37"
        />
      </svg>
    </div>
  );
}

export default Hamburger;
