import { AppleFilled } from "@ant-design/icons";
import EyeClose from "../../../global components/Icons/EyeClose/EyeClose";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import google from "../../../assets/images/google logo.png";
import s from "./Login.module.scss";
import clsx from "clsx";
import { Button, Input } from "antd";
import { useAppDispatch } from "../../../libs/Utils/customHooks";
import { signIn } from "../../../libs/redux/auth/signinSlice";
import { parseClientError } from "../../../libs/api/Endpoints";
import EyeOpen from "../../../global components/Icons/EyeOpen/EyeOpen";

import { useToggle } from "../../../global components/Context/WelcomeContex";
import { generateResult } from "../../../global components/ResultModal/ResultModal";
import { capitalize } from "../../../libs/common";
import { SuccessDataType } from "../../../libs/api/types";
type Inputs = {
  email: string;
  password: string;
};
function Login() {
  const { toggle,isFromSignUp } = useToggle();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({ shouldFocusError: false });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const urlEncodedData = new URLSearchParams();
    urlEncodedData.append("email", data.email);
    urlEncodedData.append("password", data.password);
    type id = { id: string };
    try {
      const result: SuccessDataType<id> = await dispatch(
        signIn(urlEncodedData)
      ).unwrap();

      if (result.message.includes("OTP")) {
        navigate(
          `/auth/verify-email?userId=${result.data.id}&email=${data.email}`,
          {
            state: {
              email: data.email,
              password: data.password,
            },
          }
        );
        return;
      }
      toggle(true);
      isFromSignUp(false)
      navigate("/dashboard");
      reset();
    } catch (error) {
      const e = parseClientError(error);
      generateResult("error", capitalize(e.error) || "error");
    }
  };
  return (
    <>
      <div className={s.wrapper}>
        <span className={s.titles}>
          <h3 className={s.title}>Login to Cloudivi</h3>
          <p className={s.subtitle}>
            Welcome back! Provide your details as requested
          </p>
        </span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={clsx(s.inputWrapper)}>
            <h5 className={clsx(s.inputLabel)}>Email address</h5>
            <input
              type="email"
              style={{
                backgroundColor: "white", // Set to your desired color
                boxShadow: "none",
              }}
              className={clsx(s.input, { [s.inputError]: errors.email })}
              {...register("email", { required: "Email is required" })}
            />
            {errors.email && (
              <span className={clsx(s.errorMessage)}>
                {errors.email.message}
              </span>
            )}
          </div>
          <div className={clsx(s.inputWrapper)}>
            <h5 className={clsx(s.inputLabel)}>Password</h5>
            <Controller
              name="password"
              control={control}
              rules={{
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters long",
                },
              }}
              render={({ field }) => (
                <Input.Password
                  {...field} // Spread the field props here
                  placeholder="Input password"
                  style={{
                    backgroundColor: "white", // Your desired background color
                    boxShadow: "none",
                  }}
                  className={clsx(s.input, s.customPasswordInput, {
                    [s.inputPasswordError]: errors.password,
                  })}
                  iconRender={(visible) =>
                    visible ? (
                      <EyeOpen fill="#6A6767" />
                    ) : (
                      <EyeClose fill="#6A6767" />
                    )
                  }
                />
              )}
            />
            {errors.password && (
              <span className={clsx(s.errorMessage)}>
                {errors.password.message}
              </span>
            )}
          </div>

          <span className={s.forgot}>
            <Link to={"/auth/forgot-password"} className={clsx(s.links)}>
              Forgot password?
            </Link>
          </span>
          <Button
            type="default"
            className={clsx(s.button)} // Button style
            htmlType="submit" // Ensures it's tied to form submission
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Log In
          </Button>
          {/* <span className={s.alternative}>
          <p className={s.or}>Or</p>
        </span>
        <span className={s.siginWithGoogle}>
          <img src={google} className={s.google} alt="google logo" />
          <h5>Continue with Google</h5>
        </span>
        <span className={s.siginWithApple}>
          <AppleFilled width={24} height={24} />
          <h5>Continue with Apple</h5>
        </span> */}
          <span className={s.createAccount}>
            <p>Do not have an account?</p>
            <Link to="/auth/signup" className={s.links}>
              Sign up
            </Link>
          </span>
        </form>
      </div>
    </>
  );
}

export default Login;
