import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import TypeBox from "../../global components/TypeBox";

export function useTypeBoxes() {
  const typeboxWrapperRef = useRef<HTMLDivElement>(null);

  const TypeBoxBody = [
    {
      title: "Simplified communication for all",
      body: "Helping both businesses and individuals to communicate with their audience efficiently",
    },
    {
      title: "Flexible and cost effective",
      body: "Pay only for what you use. No hidden fee, no credit expiration",
    },
    {
      title: "Bulk SMS and Emails in few clicks",
      body: "Manage contact, create group and track messages with ease",
    },
  ];

  const TypeBoxes = TypeBoxBody.map((item) => (
    <TypeBox key={item.title} title={item.title} body={item.body} />
  ));

  useEffect(() => {
    const typeBoxes = typeboxWrapperRef.current?.children;

    if (typeBoxes) {
      gsap.set(typeBoxes, { opacity: 0, scale: 0.5 }); // Set the initial state

      gsap.to(typeBoxes, {
        duration: 1,
        opacity: 1,
        scale: 1,
        stagger: 0.3, // Delay between each TypeBox animation
        ease: "back.out(1.7)", // Smooth easing effect
        delay: 0.5, // Start the animation after a slight delay
      });
    }
  }, []);

  return { TypeBoxes, typeboxWrapperRef };
}
