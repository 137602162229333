import { useLocation, useNavigate } from "react-router-dom";
import { singleContactGroup } from "../../../../libs/Utils/Types/ContactGroup";
import { UseAuthToken } from "../../../../libs/api/userSession";
import WaveSurfer from "wavesurfer.js";
import { useEffect, useRef, useState } from "react";
import useSWR from "swr";
import {
  parseClientError,
  swrFetcher,
  swrMutationFetcher,
  SwrMutationFetcherArgType,
} from "../../../../libs/api/Endpoints";
import s from "./Review.module.scss";
import clsx from "clsx";
import { Button, Skeleton } from "antd";
import useSWRMutation from "swr/mutation";
import { generateResult } from "../../../../global components/ResultModal/ResultModal";
import { useDraft } from "../../../../global components/Context/DraftContext";
import {
  PaginatedScheduledMessage,
  PaginatedSentMessage,
} from "../../../../libs/Utils/Types/Message";
import { capitalize } from "../../../../libs/common";
// Define the shape of the state
interface EmailState {
  msgTitle?: string;
  emailSubject?: string;
  recipient?: string;
  content?: string;
  schedule?: "";
  from?: string;
  type: string;
  audioURL: string;
  msgId: string;
  medias: { publicId: any; secureUrl: any }[] | undefined;
  reset?: () => void;
}

function Review() {
  // Use the location hook without generics
  const location = useLocation();
  const { cancelDraft } = useDraft();

  // Destructure the state with default values to avoid issues if the state is undefined
  const {
    msgTitle = "Untitled",
    emailSubject = "No subject",
    recipient = "",
    content = "",
    schedule = "",
    from = "",
    type = "",
    audioURL = "",
    msgId = "",
    medias = undefined,
    reset = () => {},
  } = (location.state as EmailState) || {};
  const token = UseAuthToken();
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageItems, setPageItems] = useState(10);
  const [filter, setFilter] = useState<string>("");
  const {
    data: sentMessages,
    isLoading,
    error,
    mutate,
  } = useSWR<PaginatedSentMessage, any, any>(
    token
      ? [
          "api/messages",
          token,
          {
            page: currentPage,
            limit: pageItems,
            search: searchValue,
            status: "sent",
            deliveryStatus: filter,
          },
        ]
      : null,
    swrFetcher,
    {
      revalidateOnFocus: true,
      shouldRetryOnError: false, // Disable retrying on errors
    }
  );
  const {
    data: group,
    isLoading: Loading,
    error: errors,
  } = useSWR<singleContactGroup, any, any>(
    token ? [`api/contact-groups/${recipient}?search`, token] : null,
    swrFetcher,
    {
      // Refetch every 10 seconds (10000 ms)
      shouldRetryOnError: false, // Disable retrying on errors
    }
  );

  const {
    data: scheduledMessages,

    mutate: mutateSchedule,
  } = useSWR<PaginatedScheduledMessage, any, any>(
    token
      ? [
          "api/messages",
          token,
          {
            page: currentPage,
            limit: pageItems,
            search: searchValue,
            status: "scheduled",
            deliveryStatus: filter,
          },
        ]
      : null,
    swrFetcher,
    {
      revalidateOnFocus: true, // Refetch when the window regains focus
      shouldRetryOnError: false, // Disable retrying on errors
    }
  );
  const { trigger: submitMsg, isMutating } = useSWRMutation(
    `api/messages/${msgId ? msgId : ""}`,
    swrMutationFetcher
  );
  const navigate = useNavigate();
  function htmlToPlainText(htmlString: string): string {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;

    // Extract image tags
    const images = Array.from(tempElement.querySelectorAll("img")).map(
      (img) => {
        return ``;
      }
    );

    // Remove all tags but preserve their text content
    const plainText = tempElement.textContent || tempElement.innerText || "";

    // Append image elements as HTML if any
    if (images.length > 0) {
      return `${plainText}\n${images}`;
    }

    return plainText;
  }
  console.log(schedule);
  const sendMsg = async () => {
    try {
      const formData = new FormData();
      if (msgTitle) formData.append("title", msgTitle);
      formData.append("type", type);

      if (emailSubject) formData.append("subject", emailSubject);

      if (content && type !== "mms") {
        formData.append("body", content);
      } else if (content && type === "mms") {
        formData.append("body", htmlToPlainText(content));
      }
      if (msgId) formData.append("isDraft", "false");
      if (medias && medias.length > 0) {
        medias.forEach((image, index) => {
          formData.append(`medias[${index}][publicId]`, image.publicId);
          formData.append(`medias[${index}][secureUrl]`, image.secureUrl);
        });
      }
      if (audioURL) formData.append("body", audioURL);
      if (from) formData.append("senderName", from);
      const contactGroupIds = [];
      contactGroupIds.push(recipient);
      contactGroupIds.forEach((id) => {
        formData.append("contactGroupIds[]", id); // Append each ID as part of an array
      });
      if (schedule) {
        formData.append("scheduledFor", schedule);
      }
      const fetcherArg: SwrMutationFetcherArgType = {
        token,
        requestOption: {
          method: msgId ? "PATCH" : "POST",
          body: formData,
        },
      };
      cancelDraft();
      await submitMsg(fetcherArg);
      reset && reset();
      generateResult("success", "Success");
      navigate("/dashboard/Message");
      mutate();
      mutateSchedule();
    } catch (error) {
      const e = parseClientError(error);

      generateResult(
        "error",
        capitalize(e.message) || "An unexpected error occurred."
      );
    }
  };
  const waveformRef = useRef(null);
  const wavesurferRef = useRef<WaveSurfer | null>(null);
  // const playAudio = () => {
  //   if (wavesurferRef.current) {
  //     wavesurferRef.current.play();
  //   }
  // };

  // const stopAudio = () => {
  //   if (wavesurferRef.current) {
  //     wavesurferRef.current.stop();
  //   }
  // };
  console.log(content);
  useEffect(() => {
    if (audioURL && waveformRef.current) {
      // Initialize WaveSurfer
      wavesurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: "violet",
        progressColor: "purple",
        height: 100,
      });
      wavesurferRef.current.load(audioURL);

      return () => {
        wavesurferRef.current?.destroy();
      };
    }
  }, [audioURL]);
  console.log(schedule);
  return (
    <div className={s.wrapper}>
      <div className={s.welcomeTab}>
        <div className={s.titles}>
          <h2 className={s.title}>Review</h2>
          <p className={s.subtitle}>
            Take a final look at your message before sending it out to ensure
            accuracy
          </p>
        </div>
        <div className={s.contactBtnGroups}>
          <Button
            loading={isMutating}
            onClick={sendMsg}
            className={clsx(s.createBtn)}
          >
            {schedule ? "Schedule now" : " Send now"}
          </Button>
        </div>
      </div>
      <div className={s.msg}>
        <div className={s.titleWrapper}>
          <div className={s.msgTitleWrapper}>
            <h3>{msgTitle}</h3>
          </div>

          {Loading || errors ? (
            <Skeleton.Button
              active={true}
              size={"default"}
              shape={"square"}
              block={true}
              style={{ height: "161px" }}
            />
          ) : (
            <table className={s.msgTitle}>
              {type === "email" && (
                <tr>
                  <td className={s.emailTitles}>Email subject</td>
                  <td className={s.subtitles}>
                    <p> {emailSubject}</p>
                  </td>
                </tr>
              )}
              <tr>
                <td className={s.emailTitles}>Contact group</td>
                <td className={s.subtitles}>{group?.name}</td>
              </tr>
              <tr>
                <td className={s.emailTitles}>Number of contacts</td>
                <td className={s.subtitles}>
                  {group?.contacts && group?.contacts.docs.length}
                </td>
              </tr>
            </table>
          )}
        </div>
        <div>
          <div className={s.msgBodyWrapper}>
            <h3>Message</h3>
          </div>
          {Loading || errors ? (
            <Skeleton.Button
              active={true}
              size={"default"}
              shape={"square"}
              block={true}
              style={{ height: "333px" }}
            />
          ) : (
            <div className={s.msgBody}>
              {audioURL ? (
                <div className={s.audio}>
                  <audio src={audioURL} controls />
                </div>
              ) : (
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
              )}
            </div>
          )}
          {/* Render rich text */}
        </div>
      </div>
      <div className={s.contactBtnGroupsMobile}>
        <Button
          loading={isMutating}
          onClick={sendMsg}
          className={clsx(s.createBtn)}
        >
          {schedule ? "Schedule now" : "Send now"}
        </Button>
      </div>
    </div>
  );
}

export default Review;
