import React, { ComponentPropsWithRef } from 'react';
import { Popover } from 'antd';
import "./Popover.scss"
type ReusablePopoverProps = ComponentPropsWithRef<typeof Popover> & {
    children: React.ReactNode;
    text?: React.ReactNode; // Title of the popover
    content?: React.ReactNode; // Content of the popover
};

const ReusablePopover = ({ children, text, content, ...props }: ReusablePopoverProps) => (
    <Popover style={{padding:0}} placement="bottomRight" content={content} {...props}>
        {children}
    </Popover>
);

export default ReusablePopover;
