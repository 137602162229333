import React from "react";
import { IconsType } from "../../../libs/Utils/types";
import clsx from "clsx";
import s from "./EyeOpen.module.scss";
type EyeOpenProp = IconsType & {
  onClick?: () => void;
};
function EyeOpen({ height, width, fill, onClick }: EyeOpenProp) {
  return (
    <div onClick={onClick} className={clsx(!width && s.wrapper)}>
      <svg
       
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: width || "100%",
          height: height || "100%",
        }}
        preserveAspectRatio="xMidYMid meet"

      >
        <path
          d="M12 5C6.612 5 2 10.336 2 12C2 13.742 5.546 19 12 19C18.454 19 22 13.742 22 12C22 10.336 17.388 5 12 5ZM12 15C11.4067 15 10.8266 14.8241 10.3333 14.4944C9.83994 14.1648 9.45542 13.6962 9.22836 13.1481C9.0013 12.5999 8.94189 11.9967 9.05764 11.4147C9.1734 10.8328 9.45912 10.2982 9.87868 9.87868C10.2982 9.45912 10.8328 9.1734 11.4147 9.05764C11.9967 8.94189 12.5999 9.0013 13.1481 9.22836C13.6962 9.45542 14.1648 9.83994 14.4944 10.3333C14.8241 10.8266 15 11.4067 15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}
export default EyeOpen;
