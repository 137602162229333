import clsx from "clsx";
import React from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import s from "./verifyphone.module.scss";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { generateResult } from "../../../global components/ResultModal/ResultModal";
import {
  parseClientError,
  SwrMutationFetcherArgType,
} from "../../../libs/api/Endpoints";
import { swrMutationFetcher } from "../../../libs/api/Endpoints";
import useSWRMutation from "swr/mutation";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { UseAuthToken } from "../../../libs/api/userSession";
import Navigation from "../../../global components/Navigation/Navigation";
import { useCountryCode } from "../../../global components/Context/CountryCode";
import { capitalize } from "../../../libs/common";
import { SuccessDataType } from "../../../libs/api/types";
type Inputs = {
  phoneNo: string;
};
type VerifyPhoneNumberResposnse = {
  phoneNo: string;
};
function VerifyPhoneNumber() {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    shouldFocusError: false,
    defaultValues: {
      phoneNo: "", // Setting default value for phoneNo
    },
  });

  const { trigger: verifyPhone, isMutating: loading } = useSWRMutation(
    "api/users/me/verify-phone",
    swrMutationFetcher
  );
  const token = localStorage.getItem("token");
  console.log(token)
  const { countryCode } = useCountryCode();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const urlEncodedData = new URLSearchParams();

    if (data.phoneNo)
      urlEncodedData.append("phoneNo", "+" + data.phoneNo.trimEnd());
    try {
      const fetchArg: SwrMutationFetcherArgType = {
        token,
        requestOption: {
          body: urlEncodedData,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      };
      const response: SuccessDataType<VerifyPhoneNumberResposnse> =
        await verifyPhone(fetchArg);
      navigate(`/auth/verify-phone-number/${response.data.phoneNo}`);
      generateResult(
        "success",
        "Enter the  One-Time Passcode (OTP) sent to you"
      );
    } catch (error: any) {
      const e = parseClientError(error);
      generateResult("error", capitalize(e.message) || "");
    }
  };

  return (
    <div className={s.wrapper}>
      <div className={s.navigate}>
        <Navigation />
      </div>
      <div className={s.main}>
        <span className={s.titles}>
          <h3 className={s.title}>Provide phone number</h3>
          <p className={s.subtitle}>We need to verify your phone number</p>
        </span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={clsx(s.inputWrapper)}>
            <h5 className={clsx(s.inputLabel)}>Phone number</h5>
            <Controller
              name="phoneNo"
              control={control}
              rules={{
                required: "Phone number is required", // Custom required message
                // minLength: {
                //   value: 13,
                //   message: "Phone number must be at least 10 digits",
                // },
              }}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  country={countryCode.toLowerCase()}
                  enableSearch={true}
                  placeholder="Enter phone number"
                  disableDropdown={false}
                  onChange={field.onChange}
                  containerClass={s.phoneInputContainer}
                  dropdownClass={s.phoneInputDropdown}
                  inputClass={clsx(
                    { [s.inputError]: errors.phoneNo }, // Handle input error class
                    s.PhoneInput, // Additional styles for PhoneInput
                    s.input
                  )}
                />
              )}
            />
            {/* Error message */}
            {errors.phoneNo && (
              <span className={clsx(s.errorMessage)}>
                {errors.phoneNo.message}
              </span>
            )}
          </div>

          {/* Continue button */}
          <Button
            type="default"
            className={clsx(s.button)}
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            Continue
          </Button>
        </form>
      </div>
    </div>
  );
}

export default VerifyPhoneNumber;
