import React from "react";
import { IconsType } from "../../../libs/Utils/types";
import clsx from "clsx";
import s from "./ContactGroup.module.scss";
function ContactsGroup({ width, height, fill }: IconsType) {
  return (
    <div className={clsx(!width && s.wrapper)}>
      <svg
        viewBox="0 0 20 21"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: width || "100%",
          height: height || "100%",
        }}
        preserveAspectRatio="xMidYMid meet"
      >
        <path
          d="M6.5 10.3082C8.98528 10.3082 11 8.1874 11 5.57131C11 2.95523 8.98528 0.834473 6.5 0.834473C4.01472 0.834473 2 2.95523 2 5.57131C2 8.1874 4.01472 10.3082 6.5 10.3082Z"
          fill={fill}
        />
        <path
          d="M8 11.3608H5C3.67441 11.3625 2.40356 11.9175 1.46622 12.9042C0.528882 13.8909 0.00158786 15.2286 0 16.6239V19.7818C0 20.061 0.105357 20.3288 0.292893 20.5262C0.48043 20.7236 0.734784 20.8345 1 20.8345H12C12.2652 20.8345 12.5196 20.7236 12.7071 20.5262C12.8946 20.3288 13 20.061 13 19.7818V16.6239C12.9984 15.2286 12.4711 13.8909 11.5338 12.9042C10.5964 11.9175 9.32559 11.3625 8 11.3608Z"
          fill={fill}
        />
        <path
          d="M10.169 8.29552C10.7084 7.50049 10.9988 6.54825 11 5.57131C10.9979 5.22446 10.9596 4.87888 10.886 4.54078C10.4787 5.05419 10.1998 5.66659 10.0746 6.32233C9.9494 6.97806 9.98184 7.65634 10.169 8.29552Z"
          fill={fill}
        />
        <path
          d="M14 2.93974C13.521 2.94359 13.0467 3.03882 12.6 3.22079C13.0113 4.38617 13.1101 5.6482 12.8856 6.86874C12.6611 8.08928 12.1219 9.22127 11.327 10.1408C11.7755 10.4025 12.1957 10.7149 12.58 11.0724C13.0325 11.2595 13.5139 11.3572 14 11.3608C15.0609 11.3608 16.0783 10.9172 16.8284 10.1276C17.5786 9.33793 18 8.26696 18 7.15026C18 6.03356 17.5786 4.9626 16.8284 4.17297C16.0783 3.38334 15.0609 2.93974 14 2.93974Z"
          fill={fill}
        />
        <path
          d="M15 12.4134H13.723C14.5515 13.6445 14.9976 15.1153 15 16.6239V19.7818C14.9968 20.1413 14.9345 20.4974 14.816 20.8345H19C19.2652 20.8345 19.5196 20.7236 19.7071 20.5262C19.8946 20.3288 20 20.061 20 19.7818V17.6766C19.9984 16.2812 19.4711 14.9435 18.5338 13.9568C17.5964 12.9701 16.3256 12.4151 15 12.4134Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}

export default ContactsGroup;
