import React, { useState, FormEvent, useEffect } from "react";
import OtpInput from "react-otp-input";
import clsx from "clsx";
import { Button } from "antd";
import s from "./OtpInputs.module.scss"; // Example style file


type OtpInputProps = {
  numInputs: number;
  handleSubmit: (otp: string) => void;
  otpLengthCheck?: number;
  errorMessage?: string;
  buttonLabel?: string;
  loading?: boolean;
  buttonClassName?: string;
};

const OtpInputs = ({
  loading,
  numInputs,
  handleSubmit,
  otpLengthCheck = numInputs,
  errorMessage = "OTP is incomplete",
  buttonLabel = "Continue",
  buttonClassName = "",
}: OtpInputProps) => {
  const [otp, setOtp] = useState<string>("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // useEffect to handle screen resizing
  useEffect(() => {
    // Handler to update the state with the current width
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener for resize event
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (otp.length < otpLengthCheck) {
      e.preventDefault();
    } else {
      handleSubmit(otp);
    }
  };

  return (
    <form className={s.form} onSubmit={onSubmit}>
      <div className={clsx(s.otpInput)}>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={numInputs}
          containerStyle={{
            display: "flex",
            flexDirection: "row",
            width:"100%",
            gap: "14px",
            margin:"auto",
            justifyItems: "center",
            justifySelf: "center",
           
          }}
        
          inputStyle={{
            width: screenWidth < 465 ? "16%" : "15%",
            height: screenWidth < 465 ? "44px" : "44px",
            fontSize: "20px",
            fontWeight: "700",
            borderWidth: "1px",
            borderColor: "#DAD6D5",
            borderRadius:"4px"
          }}
          renderInput={(props, index) => (
            <input
              {...props}
              style={{
                ...props.style,
                borderColor: otp[index] ? props.style?.borderColor : "#D52B1E", // Red border for empty inputs
              }}
            />
          )}
        />
      </div>
      <Button
        type="default"
        className={clsx(s.button)}
        htmlType="submit"
        loading={loading}
      >
        {buttonLabel}
      </Button>
    </form>
  );
};

export default OtpInputs;
