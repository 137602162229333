import { Button } from "antd";
import React from "react";
import s from "./NetworkErr.module.scss";
function NetworkErr() {
  const handleReloadPreviousPage = () => {
    window.location.reload();
  };
  return (
    <div className={s.container}>
      <h3>Network Error!</h3>
      <Button
        style={{ backgroundColor: "#d52b1e", color: "white" }}
        onClick={handleReloadPreviousPage}
      >
        Click to reload
      </Button>
    </div>
  );
}

export default NetworkErr;
