import React from "react";
import { IconsType } from "../../libs/Utils/types";
function Separator({ fill, height, width }: IconsType) {
  return (
    <div >
      <svg
        width={width}
        height={height}
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.78852 11.8334C1.56924 11.8334 1.3549 11.765 1.17259 11.637C0.990275 11.5089 0.848183 11.327 0.764274 11.1141C0.680365 10.9012 0.658406 10.6669 0.701173 10.4409C0.74394 10.2149 0.849512 10.0073 1.00454 9.84436L4.65608 6.00724L1.00454 2.17012C0.898635 2.06263 0.814157 1.93406 0.756042 1.79189C0.697927 1.64973 0.667337 1.49683 0.666057 1.34211C0.664778 1.18739 0.692835 1.03395 0.748591 0.890748C0.804346 0.747545 0.886684 0.617444 0.9908 0.508037C1.09492 0.39863 1.21872 0.312108 1.355 0.253519C1.49128 0.19493 1.63729 0.165448 1.78453 0.166792C1.93177 0.168137 2.07727 0.200281 2.21256 0.26135C2.34785 0.322419 2.47021 0.411189 2.5725 0.522481L7.00802 5.18342C7.2159 5.40193 7.33268 5.69826 7.33268 6.00724C7.33268 6.31622 7.2159 6.61255 7.00802 6.83106L2.5725 11.492C2.36459 11.7105 2.08259 11.8333 1.78852 11.8334Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}

export default Separator;
