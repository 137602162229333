import React from "react";

function Close() {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.4263 12.0123L18.7193 6.71929C18.8148 6.62704 18.891 6.5167 18.9434 6.39469C18.9958 6.27269 19.0234 6.14147 19.0245 6.00869C19.0257 5.87591 19.0004 5.74423 18.9501 5.62133C18.8998 5.49844 18.8256 5.38679 18.7317 5.29289C18.6378 5.199 18.5261 5.12475 18.4032 5.07447C18.2803 5.02419 18.1487 4.99888 18.0159 5.00004C17.8831 5.00119 17.7519 5.02878 17.6299 5.08119C17.5079 5.1336 17.3975 5.20978 17.3053 5.30529L12.0123 10.5983L6.71929 5.30529C6.53069 5.12313 6.27808 5.02234 6.01589 5.02461C5.75369 5.02689 5.50288 5.13206 5.31747 5.31747C5.13206 5.50288 5.02689 5.75369 5.02461 6.01589C5.02234 6.27808 5.12313 6.53069 5.30529 6.71929L10.5983 12.0123L5.30529 17.3053C5.20978 17.3975 5.1336 17.5079 5.08119 17.6299C5.02878 17.7519 5.00119 17.8831 5.00004 18.0159C4.99888 18.1487 5.02419 18.2803 5.07447 18.4032C5.12475 18.5261 5.199 18.6378 5.29289 18.7317C5.38679 18.8256 5.49844 18.8998 5.62133 18.9501C5.74423 19.0004 5.87591 19.0257 6.00869 19.0245C6.14147 19.0234 6.27269 18.9958 6.39469 18.9434C6.5167 18.891 6.62704 18.8148 6.71929 18.7193L12.0123 13.4263L17.3053 18.7193C17.4939 18.9014 17.7465 19.0022 18.0087 19C18.2709 18.9977 18.5217 18.8925 18.7071 18.7071C18.8925 18.5217 18.9977 18.2709 19 18.0087C19.0022 17.7465 18.9014 17.4939 18.7193 17.3053L13.4263 12.0123Z"
          fill="#1F2A37"
        />
      </svg>
    </div>
  );
}

export default Close;
