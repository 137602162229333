import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchBaseLocation } from "../../common";
import axios from "axios";
import { generateResult } from "../../../global components/ResultModal/ResultModal";

// type User = {
//   firstName: string;
//   lastName: string;
//   email: string;
//   phoneNumber?: string;
//   token: null | string;
// };

export interface SignUpResponse {
  success: boolean;
  data: {
    id: string;
    type: string;
    email: string;
  };
  message: string;
}

type InitialState = {
  id: string;
  message: string;
  success: boolean;
  loading: boolean;
  email: string;
  error: string | null;
  type: string;
};

const initialState: InitialState = {
  id: "",
  message: "",
  success: false,
  loading: false,
  email: "",
  error: null,
  type: "",
};

// Async thunk for signing up
export const signup = createAsyncThunk<SignUpResponse, URLSearchParams>(
  "auth/signup", // Action type should follow a clear convention
  async (body, thunkAPI) => {
    const url = `${fetchBaseLocation}api/auth/sign-up`;
    try {
      const response = await axios.post<SignUpResponse>(url, body);
      return response.data;
    } catch (error: any) {
      // Check if error.response exists before accessing data
      const errorMessage = error.response?.data?.message || "An error occurred";
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle the pending state
    builder.addCase(signup.pending, (state) => {
      state.loading = true;
      state.error = null; // Set error to null when starting the request
    });

    // Handle the fulfilled state (success)
    builder.addCase(
      signup.fulfilled,
      (state, action: PayloadAction<SignUpResponse>) => {
        state.loading = false;
        state.id = action.payload.data.id;
        state.email = action.payload.data.email;
        state.type = action.payload.data.type;
        state.success = action.payload.success;
        state.message = action.payload.message;
        state.error = null; // Reset error on successful signup
        generateResult(
          "success",
          "Email verification",
          "Check your email to verify your account"
        ); // Info toast
      }
    );

    // Handle the rejected state (error)
    builder.addCase(signup.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
      generateResult("error", state.error || "Signup failed"); // Error toast
    });
  },
});

export default authSlice.reducer;
