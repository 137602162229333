import { generateResult } from "../../global components/ResultModal/ResultModal";
import { ErrorDataType, SuccessDataType } from "./types";
import { logout } from "../redux/auth/signinSlice";
import { store } from "../Utils/store";
import { fetchBaseLocation } from "../common";
/**
 *  This is a function that is primarily passed to `useSWR as a fetcher`.
 *
 * {string} api: This is the endpoint to access the route handler endpoint defined.
 * {RequestInit} requestOptions: Passed down to the fetch func that will be called.
 */

export interface SwrFetcherType {
  api: string;
  searchParams?: object;
  requestOptions?: RequestInit;
  token?: string;
}

export async function swrFetcher([api, token, searchParams]: [
  string,
  string,
  object?
]) {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Construct the query string from searchParams
  const queryString = new URLSearchParams(
    searchParams as Record<string, string>
  ).toString();
  const url = `${fetchBaseLocation}${api}?${queryString}`; // Append query params to the URL

  const res = await fetch(url, requestOptions);

  if (!res.ok) {
    const resData: ErrorDataType = await res.json();
    if (res.status === 401) {
      generateResult("error", "Unauthorized, please sign in again");
      store.dispatch(logout());
    }
    throw new Error(
      JSON.stringify({
        ...resData,
        statusCode: res.status,
      })
    );
  }

  const resData = await res.json();
  return resData.data;
}

export type SwrMutationFetcherArgType<T = unknown> = {
  requestOption: RequestInit;
  searchParams?: T;
  token?: string | null;
};

export async function swrMutationFetcher(
  api: string,
  options: { arg?: SwrMutationFetcherArgType<any> } // Accept the options parameter that includes `arg`
) {
  const { arg } = options;
  const requestOption = arg?.requestOption ?? {};

  // Check if there are any searchParams passed and try to include them in the fetch API string
  const searchParamsInstance = new URLSearchParams(arg?.searchParams as any);
  const searchParamsString = searchParamsInstance.toString()
    ? `?${searchParamsInstance.toString()}`
    : "";

  // Include the token in the headers if it exists
  if (arg?.token) {
    requestOption.headers = {
      Authorization: `Bearer ${arg.token}`,
      ...requestOption.headers,
    };
  }

  const res = await fetch(
    `${fetchBaseLocation}${api}${searchParamsString}`,
    requestOption
  );

  // Handle errors
  if (!res.ok) {
    const resData: ErrorDataType = await res.json();
    if (res.status === 401) {
      // Dispatch the logout action
      store.dispatch(logout());
      generateResult("error", "Unauthorized, please sign in again");
    }
    throw new Error(
      JSON.stringify({
        ...resData,
        statusCode: res.status,
      })
    );
  }

  const resData = await res.json();
  return resData;
}
export function parseClientError(e: any) {
  try {
    // Attempt to parse the error message as JSON
    const errorObject: ErrorDataType = JSON.parse(e?.message);

    // Handle nested error messages, if any
    if (typeof errorObject.error === "object") {
      const value: any = Object.values(errorObject.error)[0];
      if (value?.msg) {
        errorObject.message = value?.msg;
      }
    }

    return errorObject;
  } catch (error: any) {
    // If JSON parsing fails, handle the error message directly

    const statusCode =
      e?.originalStatus || e?.statusCode || e?.status || error?.statusCode;

    // Use the raw message or fallback to "An unexpected error occurred"
    const rawMessage =
      e?.message || e?.response?.text || "An unexpected error occurred";

    const errorObject: ErrorDataType = {
      success: false,
      error: rawMessage, // Use rawMessage as fallback error message
      message: "",
      statusCode: statusCode,
    };

    return errorObject;
  }
}
