import React from "react";
import s from "./Header.module.scss";
import clsx from "clsx";
import ArrowDown from "../../../../global components/Icons/ArrowDown";
import Notification from "../../../../global components/Icons/Notification";
import { Avatar } from "../../../../libs/redux/auth/signinSlice";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ReusablePopover from "../../../../global components/Popover/ReuseablePopover";
import Logout from "../Logout/Logout";
import { useLocation } from "react-router-dom";
import ContactAvatar from "../../../../global components/Icons/ContactAvatar/ContactAvatar";
import Navigation from "../../../../global components/Navigation/Navigation";
import Hamburger from "../../../../global components/Icons/Hamburger";
import { useToggle } from "../../../../global components/Context/NavbarContext";
type HeaderProp = {
  avatar: Avatar | undefined;
  email: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
};

function Header({ avatar, email, firstName, lastName }: HeaderProp) {
  const location = useLocation();
  const pathName = location.pathname;
  const { isTrue, toggle } = useToggle();
  return (
    <div className={clsx(s.wrapper)}>
      {firstName || !lastName ? (
        <span className={s.profileInfo}>
          <span className={s.headerTitle}>
            {(pathName === "/dashboard/Home" || pathName === "/dashboard") && (
              <h1 className={s.name}>Welcome, {firstName}</h1>
            )}
            {pathName === "/dashboard/Contacts" && (
              <h1 className={s.name}>Contacts</h1>
            )}
            {pathName === "/dashboard/Message" && (
              <h1 className={s.name}>Message</h1>
            )}
            {pathName === "/dashboard/Account" && (
              <h1 className={s.name}>Account</h1>
            )}
            {pathName !== "/dashboard/Account" &&
              pathName !== "/dashboard" &&
              pathName !== "/dashboard/Home" &&
              pathName !== "/dashboard/Contacts" &&
              pathName !== "/dashboard/Message" && <Navigation />}
          </span>
          <div className={s.headerIcons}>
            <div onClick={toggle}>
              <Hamburger />
            </div>
          </div>
          {avatar?.url ? (
            <div className={s.imageContainer}>
              <img
                src={avatar?.publicId} // Fallback to default profile image if no avatar
                className={s.profilePics}
                alt="Profile"
              />
            </div>
          ) : (
            <div className={s.profilePics}>
              <ContactAvatar width={32} height={32} />
            </div>
          )}
          <span className={s.profileDetails}>
            <h5 className={s.profileName}>{firstName + " " + lastName}</h5>
            <p className={s.profileEmail}>{email}</p>
          </span>
          <ReusablePopover trigger={"click"} content={<Logout />}>
            <span className={s.arrowDown}>
              <ArrowDown fill="#1F2A37" width={10.5} height={6} />
            </span>
          </ReusablePopover>
        </span>
      ) : (
        <Skeleton width={150} height={30} enableAnimation duration={2} />
      )}
    </div>
  );
}

export default Header;
