import React from "react";
import { IconsType } from "../../libs/Utils/types";;
function Search({fill, height, width}:IconsType) {
  return (
    <div >
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 15"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.7502 15L14.7503 14.5C14.684 14.5 14.6204 14.4736 14.5735 14.4267L14.5734 14.4267L11.5773 11.4306C11.5332 11.3837 11.5088 11.3215 11.5093 11.257C11.5099 11.1915 11.5362 11.1288 11.5825 11.0824C11.6289 11.0361 11.6916 11.0098 11.7572 11.0092C11.8217 11.0087 11.8838 11.033 11.9307 11.0772L14.9268 14.0733M14.7502 15L14.75 14.5C14.7995 14.5 14.8478 14.4853 14.8889 14.4579L15.1667 14.8736L14.8889 14.4578C14.93 14.4304 14.9621 14.3913 14.981 14.3457C14.9999 14.3 15.0048 14.2497 14.9952 14.2012C14.9856 14.1528 14.9618 14.1082 14.9268 14.0733M14.7502 15C14.5513 15 14.3605 14.9209 14.2199 14.7803L14.7502 15ZM14.9268 14.0733L14.9269 14.0733L15.2804 13.7198L14.9268 14.0733ZM6.50057 1H6.50007C5.51116 1 4.54445 1.29325 3.72219 1.84265C2.89994 2.39206 2.25907 3.17295 1.88063 4.08658C1.50218 5.00021 1.40317 6.00555 1.59609 6.97545C1.78902 7.94536 2.26523 8.83627 2.9645 9.53553C3.66377 10.2348 4.5547 10.711 5.52461 10.9039C6.49453 11.0968 7.49987 10.9978 8.41351 10.6194C9.32715 10.241 10.108 9.6001 10.6575 8.77785C11.2069 7.95561 11.5001 6.98891 11.5001 6V5.9995C11.4988 4.67395 10.9716 3.40308 10.0343 2.46578C9.09701 1.52848 7.82613 1.00133 6.50057 1ZM6.49951 11.5C5.41189 11.4999 4.34872 11.1773 3.44439 10.5731C2.53991 9.96873 1.83496 9.10975 1.41867 8.10476C1.00238 7.09976 0.893464 5.9939 1.10568 4.927C1.31791 3.86011 1.84174 2.88011 2.61093 2.11092C3.38013 1.34173 4.36015 0.817903 5.42706 0.605684C6.49397 0.393465 7.59985 0.502383 8.60486 0.918666C9.60987 1.33495 10.4689 2.0399 11.0732 2.94437C11.6775 3.84868 12 4.91184 12.0001 5.99944C11.9985 7.45777 11.4185 8.85591 10.3872 9.88711C9.35602 10.9183 7.95787 11.4984 6.49951 11.5Z"
          fill={fill}
          stroke="#80828A"
        />
      </svg>
    </div>
  );
}

export default Search;
