import React from "react";
import { IconsType } from "../../../libs/Utils/types";
import s from "./Microphone.module.scss";
import clsx from "clsx";
type MicrophoneProp = IconsType & {
  onClick?: () => void;
};
function Microphone({ height, width, fill, onClick }: MicrophoneProp) {
  return (
    <div onClick={onClick} className={clsx(!width && s.wrapper)}>
      <svg
        viewBox="0 0 22 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: width || "100%",
          height: height || "100%",
        }}
        preserveAspectRatio="xMidYMid meet"
      >
        <path
          d="M20.3359 8.00024C19.9823 8.00024 19.6432 8.14072 19.3931 8.39077C19.1431 8.64082 19.0026 8.97996 19.0026 9.33358V13.3336C19.0026 14.7481 18.4407 16.1046 17.4405 17.1048C16.4403 18.105 15.0838 18.6669 13.6693 18.6669H8.33594C6.92145 18.6669 5.5649 18.105 4.5647 17.1048C3.56451 16.1046 3.0026 14.7481 3.0026 13.3336V9.33358C3.0026 8.97996 2.86213 8.64082 2.61208 8.39077C2.36203 8.14072 2.02289 8.00024 1.66927 8.00024C1.31565 8.00024 0.976511 8.14072 0.726462 8.39077C0.476414 8.64082 0.335938 8.97996 0.335938 9.33358V13.3336C0.338055 15.4547 1.18159 17.4883 2.68142 18.9881C4.18125 20.4879 6.21486 21.3315 8.33594 21.3336H9.66927V24.0002H7.0026C6.64898 24.0002 6.30984 24.1407 6.0598 24.3908C5.80975 24.6408 5.66927 24.98 5.66927 25.3336C5.66927 25.6872 5.80975 26.0263 6.0598 26.2764C6.30984 26.5264 6.64898 26.6669 7.0026 26.6669H15.0026C15.3562 26.6669 15.6954 26.5264 15.9454 26.2764C16.1955 26.0263 16.3359 25.6872 16.3359 25.3336C16.3359 24.98 16.1955 24.6408 15.9454 24.3908C15.6954 24.1407 15.3562 24.0002 15.0026 24.0002H12.3359V21.3336H13.6693C15.7904 21.3315 17.824 20.4879 19.3238 18.9881C20.8236 17.4883 21.6672 15.4547 21.6693 13.3336V9.33358C21.6693 8.97996 21.5288 8.64082 21.2787 8.39077C21.0287 8.14072 20.6896 8.00024 20.3359 8.00024Z"
          fill="#D52B1E"
        />
        <path
          d="M12.3359 0.000244141H9.66927C6.72375 0.000244141 4.33594 2.38806 4.33594 5.33358V12.0002C4.33594 14.9458 6.72375 17.3336 9.66927 17.3336H12.3359C15.2815 17.3336 17.6693 14.9458 17.6693 12.0002V5.33358C17.6693 2.38806 15.2815 0.000244141 12.3359 0.000244141Z"
          fill={fill || "#D52B1E"}
        />
      </svg>
    </div>
  );
}

export default Microphone;
